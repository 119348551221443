import React, { useEffect, useState } from 'react';
import { history, useDispatch } from 'umi';
import { Spin, Modal } from 'antd';
import useQuery from '@/hooks/useQuery';
import { isDingtalk, login } from '@/utils/dingUtils';

type Props = {};

const DdLoginPage = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { redirectUrl } = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDingtalk) {
      getLogin();
    }
  }, []);

  // 登录
  const getLogin = async () => {
    setLoading(true);
    login()
      .then((res) => {
        const { success, errMsg, errCode } = res || {};
        if (errCode === 102) {
          // 需要补全信息
          history.replace(`/dd-login/bind-info?redirectUrl=${redirectUrl}`);
        } else if (success) {
          // message.success(document.cookie);
          // 登录成功更新用户信息
          dispatch({ type: 'common/getUserInfo' });
          if (redirectUrl === '/' || !redirectUrl) {
            history.replace('/home');
          } else {
            history.replace(redirectUrl);
          }
        } else {
          Modal.error({
            title: errMsg || '登录异常, 请重新登录',
            okText: '确定',
            onOk: () => {
              getLogin();
            },
          });
        }
      })
      .catch((error) => {
        console.warn('钉钉 login failed', error);
        Modal.error({
          title: '登录异常, 请重新登录',
          okText: '确定',
          onOk: () => {
            getLogin();
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading} tip="登录中....">
      <div style={{ height: '100%', background: '#fff' }} />
    </Spin>
  );
};

export default DdLoginPage;
