import React from 'react';
import Container from '@/components/Container';
import InfoCard from '@/components/InfoCard';
import { Col } from 'antd';
import styles from './SearchPage.less';

export const reducer = (state, action) => {
  const { activeKey, data, type } = action;
  if (type === 'clear') {
    return {};
  }
  const { list, pageNum } = data || {};
  if (pageNum === 1) {
    return {
      ...state,
      [activeKey]: data || [],
    };
  } else if (pageNum > 1) {
    const newList = state[activeKey].list.concat(list);
    const newData = {
      ...state,
      [activeKey]: {
        ...state[activeKey],
        list: newList,
      },
    };
    return newData;
  }
  return state;
};

export const Card = ({ title = '', onClick = () => {}, children }) => (
  <Container className={styles.card} title={title} extra={<span onClick={onClick}>查看更多</span>}>
    {children}
  </Container>
);

export const ZxCard = (props) => {
  const { id, informationSearchDTO } = props || {};
  const { ossKey, informationTitle, newsType, linkUrl, validDate, labelName } =
    informationSearchDTO || {};
  return (
    <Col span={12} key={id}>
      <InfoCard
        fileKey={ossKey}
        title={informationTitle}
        tag={labelName}
        time={validDate}
        newsType={newsType}
        linkedUrl={linkUrl}
        newsId={id}
      />
    </Col>
  );
};
