import React, { useEffect } from 'react';
import { Avatar } from 'antd';
import { useSelector, useDispatch } from 'umi';
import Container from '@/components/Container';
import defaultAvatarImg from '@/assets/images/avatar.png';
import { prefixUUCImage } from '@/utils';
import styles from './UserCard.less';

export interface UserCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const UserCard = (props: UserCardProps) => {
  const {
    common: { classInfo, userInfo, creditInfo },
  } = useSelector(({ common }) => ({ common }));

  const dispatch = useDispatch();

  const { userName, avatar } = userInfo || {};
  const { duration } = classInfo || {};
  const { allScore } = creditInfo || {};

  useEffect(() => {
    if (userName) dispatch({ type: 'common/getClassCreditInfo' });
  }, [userName]);

  return userName ? (
    <Container className={styles.root}>
      <div className={styles.left}>
        <Avatar
          size={60}
          src={prefixUUCImage(avatar, defaultAvatarImg)}
          className={styles.avatar}
        />
        <div className={styles.content}>
          <span className={styles.name}>{userName}</span>
          <span className={styles.min}>
            已学习：
            {duration ?? 0}
            分钟
          </span>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <span className={styles.label}>学分</span>
          <span className={styles.ranking}>{allScore ?? '-'}</span>
        </div>
      </div>
    </Container>
  ) : null;
};

export default UserCard;
