import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import ProCertificate from './components/ProCertificate';
import EleCertificate from './components/EleCertificate';
import LayoutTitle from '../components/LayoutTitle';
import styles from './MyCertificatePage.less';
import { fetchCertificate, fetchMyOutCertificate, getAccountInfo } from './services';
import { typeMap } from './constant';
import useLoading from '@/hooks/useLoading';
import { Spin } from 'antd';

const { TabPane } = Tabs;

const MyCertificatePage = () => {
  const [key, setKey] = useState<string>('1');
  const [profeCertList, setProfeCertList] = useState<any>();
  const [electCertList, setElectCertList] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getListData = async () => {
    const { data, success } = await fetchCertificate();
    if (success) {
      const skill = data.skill || [];
      const other = data.other || [];
      const registration = data.registration || [];
      const nonRegistration = data.nonRegistration || [];
      const professional = data.professional || [];
      const electronic = data.electronic || [];
      skill.forEach((item) => (item.type = typeMap['skill']));
      other.forEach((item) => (item.type = typeMap['other']));
      registration.forEach((item) => (item.type = typeMap['registration']));
      nonRegistration.forEach((item) => (item.type = typeMap['nonRegistration']));
      professional.forEach((item) => (item.type = typeMap['professional']));
      /*setProfeCertList([
        ...skill,
        ...other,
        ...registration,
        ...nonRegistration,
        ...professional,
      ]);*/
      setElectCertList(electronic);
    }
  };

  const getProfListData2 = async (certNo) => {
    const { data, success } = await fetchMyOutCertificate(certNo);
    if (success) {
      setProfeCertList(data);
    }
  };

  const getProfListData = async () => {
    const { data, success } = await getAccountInfo();
    if (success) {
      getProfListData2(data.userInfo.certCode);
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getListData(), getProfListData()]).finally(() => {
      setLoading(false);
    });
  }, []);
  return (
    <Spin spinning={loading}>
      <div className={styles.root}>
        <div className={styles.top}>
          <LayoutTitle style={{ marginBottom: 24 }} />
          <Tabs
            onChange={(val) => {
              setKey(val);
              getListData();
            }}
          >
            <TabPane tab="专业证书" key="1" />
            <TabPane tab="电子证书" key="2" />
          </Tabs>
        </div>
        <div>
          {key === '1' && <ProCertificate list={profeCertList} />}
          {key === '2' && <EleCertificate list={electCertList} />}
        </div>
      </div>
    </Spin>
  );
};

export default MyCertificatePage;
