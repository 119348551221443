import React from 'react';
import useList from '@/hooks/useList';
import { Pagination, Spin } from 'antd';
import Empty from '@/components/Empty';
import styles from './TrainingList.less';
import { getListApi } from '../../services';
import ClassCard from '../ClassCard';

const TrainingList = (props) => {
  const { status } = props;
  const [tableProps] = useList(getListApi, {
    queryParams: {
      status,
    },
  });
  const { loading, pagination, list } = tableProps;
  const { pageSize, pageNum, total, changePage } = pagination;

  return (
    <Spin spinning={loading}>
      <div className={styles.root}>
        {list?.length ? (
          <>
            <div className={styles.list}>
              {list.map(item => (
                <ClassCard data={item} />
              ))}
            </div>
            <div className={styles.pagination}>
              <Pagination
                showQuickJumper
                showSizeChanger
                onChange={changePage}
                current={pageNum}
                total={total}
                pageSize={pageSize}
              />
            </div>
          </>
        ) : (
          <Empty />
        )}
      </div>
    </Spin>
  );
};

export default TrainingList;
