import React from 'react';
import { MicroAppWithMemoHistory } from 'umi';
import styles from './MyExamPage.less';

const MyExamPage = () => (
  <div className={styles.root}>
    <MicroAppWithMemoHistory name="考试中心" url="/user-exam/my-exam" />
  </div>
);

export default MyExamPage;
