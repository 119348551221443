import React from 'react';
import { history, useLocation, useDispatch } from 'umi';
import { Tag, Space } from 'antd';
import Container from '@/components/Container';
import { useUpdate } from 'ahooks';
import { getLocalStorage, removeStorage, searchHistoryDataSource, setSearchRecord } from '@/utils';
import Empty from '@/components/Empty';
import MyIcon from '../MyIcon';
import styles from './SearchHistoryBox.less';

export interface SearchHistoryBoxProps {
  className?: string;
}

const SearchHistoryBox = (props: SearchHistoryBoxProps) => {
  const { className = '' } = props;

  const dispatch = useDispatch();

  const update = useUpdate();

  const location = useLocation();

  const data = getLocalStorage(searchHistoryDataSource) || [];

  return (
    <Container className={`${styles.root} ${className}`}>
      <div className={styles.row}>
        <div className={styles.title}>历史搜索</div>
        <Space
          size={2}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            removeStorage(searchHistoryDataSource);
            update();
          }}
        >
          <MyIcon size={12} type="icon-shanchu" />
          全部清除
        </Space>
      </div>
      {data.length > 0 ? (
        data.map((keyword, key) => (
          <Tag
            key={key}
            className={styles.tag}
            onClick={() => {
              // 跳转到搜索结果页
              if (location.pathname !== '/search') {
                history.push('/search');
              }
              dispatch({ type: 'common/update', keyword });
              if (keyword?.trim()) {
                // 更新搜索历史记录
                setSearchRecord(keyword?.trim());
              }
            }}
          >
            {keyword}
          </Tag>
        ))
      ) : (
        <Empty />
      )}
    </Container>
  );
};

export default SearchHistoryBox;
