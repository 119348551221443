import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { getRegisterInfoApi, getRegisterConfig } from '@/pages/person/my-register/services/index';
import InfoBox from '../InfoBox';
import styles from './RegisterInfo.less';

export interface RegisterInfoProps extends React.HTMLAttributes<HTMLDivElement> {}

const RegisterInfo = (props: RegisterInfoProps) => {
  const { className = '', id, classId, classInfo = {}, ...otherProps } = props;
  const [detail, setDetail] = useState({});
  const [formConfig, setFormConfig] = useState([]);

  const [eduInfoList, setEduInfoList] = useState([]);
  const [workInfoList, setWorkInfoList] = useState([]);
  const [positionList, setPositionList] = useState([]);

  const [loading, setLoading] = useState(false);

  // 获取表单配置
  const getConfig = () => {
    getRegisterConfig({ classId }).then((res) => {
      if (res.success) {
        const { data } = res;
        setFormConfig(data);
      }
    });
  };

  const getDetail = (id) => {
    setLoading(true);
    getRegisterInfoApi({ id }).then((res) => {
      if (res?.success) {
        const { data: { userInfo }} = res;
        if (userInfo) {
          const { xlxx, zyzg, zgzc } = userInfo;
          setEduInfoList(JSON.parse(xlxx || '[]'));
          setWorkInfoList(JSON.parse(zyzg || '[]'));
          setPositionList(JSON.parse(zgzc || '[]'));
          setDetail(userInfo);
        }
      }
    })
      .finally(() => { setLoading(false); });
  };

  useEffect(() => {
    getConfig();
    getDetail(id);
  }, []);

  const classConfig = [
    {
      dictName: '班级名称',
      type: 'text',
      dictCode: 'className',
    },
    {
      dictName: '开始时间',
      type: 'text',
      dictCode: 'gmtStart',
    },
    {
      dictName: '结束时间',
      type: 'text',
      dictCode: 'gmtEnd',
    },
    {
      dictName: '发起机构',
      type: 'text',
      dictCode: 'tenantName',
    },
  ];

  const doubleColList = ['yx', 'zgzc', 'zczm', 'zyzg', 'zyzgzm'];
  // 信息项渲染方法
  const infoRender = (data, config) => {
    const content = config.map((column, index) => {
      const { dictCode, dictName, type } = column;
      const _type = type === 'file' ? 'img' : 'text';
      let _value = data[dictCode];
      if (dictCode === 'sfzzfmzp') {
        _value = ['sfzzmzp', 'sfzfmzp'].map(v => data[v]).join(',');
      }
      const col = doubleColList.includes(dictCode) ? 24 : 12;
      return (
        <Col span={col} key={dictCode}>
          <InfoBox label={dictName} type={_type} value={_value} />
        </Col>
      );
    });
    return (
      <Row gutter={20}>
        {content}
      </Row>
    );
  };

  const degreeInfoRender = (list, config) => {
    const content = list.map(item => infoRender(item, config));
    return content;
  };

  const zc = ['zgzc', 'zczm'];
  const zy = ['zyzg', 'zyzgzm'];
  const workInfoRender = (detail, zyInfo, zcInfo, config) => {
    const baseList = config.filter(item => !(zc.includes(item.dictCode) || zy.includes(item.dictCode)));
    const zcList = config.filter(item => zc.includes(item.dictCode));
    const zyList = config.filter(item => zy.includes(item.dictCode));

    const basePart = infoRender(detail, baseList);
    const zcPart = zcInfo?.map(item => infoRender(item, zcList));
    const zyPart = zyInfo?.map(item => infoRender(item, zyList));
    return [basePart, zcPart, zyPart];
  };

  const registerInfoRender = () => formConfig.map((item) => {
    const { children, dictCode, dictName } = item;
    const arrayJudge = Array.isArray(children) && children.length > 0;
    if (!arrayJudge) return null;
    let content = null;
    switch (dictCode) {
      case 'jbxx':
        content = infoRender(detail, children);
        break;
      case 'gzxx':
        content = workInfoRender(detail, workInfoList, positionList, children);
        break;
      case 'xlxx':
        content = degreeInfoRender(eduInfoList, children);
        break;
      case 'qtxx':
        content = infoRender(detail, children);
        break;
      default:
        break;
    }
    return (
      <>
        <h4>{dictName}</h4>
        {content}
      </>
    );
  }).filter(item => item);

  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      <Spin spinning={loading}>
        <h4>班级信息</h4>
        {infoRender(classInfo, classConfig)}
        {registerInfoRender()}
      </Spin>
    </div>
  );
};

export default RegisterInfo;
