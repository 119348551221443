import React, { Ref, FC, useState, useEffect, forwardRef } from 'react';
import isNil from 'lodash/isNil';
import { Select, Spin } from 'antd';
import Empty from '@/components/Empty';
import { SelectProps as AntSelectProps, SelectValue, RefSelectProps } from 'antd/lib/select';
import useDeepUpdateEffect from '@/hooks/useDeepUpdateEffect';

interface Item {
  key?: string;
  label: string;
  value: string | number;
}

interface Datalist {
  list: Array<Item>;
  total: number;
}

interface ReSult {
  success: boolean;
  data: Datalist;
}

interface SelectProps<VT extends SelectValue = SelectValue> extends AntSelectProps<VT> {
  options?: Array<Item>; // 配置的options 不走异步请求
  request?: (params?: unknown) => Promise<ReSult>; // api请求
  params?: Record<string, unknown>; // 请求方法的参数，无参数可不传
  config?: Item; // 配置接口字段对应 Select 的 label/value
  defaultSelectFirstOption?: boolean; // 请求options时,默认选择第一个选项(该属性必须在完全受控模式下才会生效)
}

const AutoSelect: FC<SelectProps> = forwardRef((props, ref: Ref<RefSelectProps>) => {
  const [selectOptions, setSelectOptions] = useState<Array<Item> | undefined>(undefined);
  const [fetching, setFetching] = useState(false);
  const {
    options,
    request,
    params,
    config,
    value,
    onChange,
    placeholder,
    defaultSelectFirstOption,
    ...otherProps
  } = props;

  const getOptions = async () => {
    if (request) {
      setFetching(true);
      const { data } = await request({ ...params });
      const list = Array.isArray(data) ? data : data?.list;
      let transOptions: Array<Item>;
      if (config) {
        transOptions =
          list?.map(item => ({
            label: item[config.label],
            value: item[config.value],
          })) || [];
      } else {
        transOptions = list || [];
      }
      setSelectOptions(transOptions);

      if (defaultSelectFirstOption && typeof onChange === 'function') {
        // 请求options时, 默认选择第一个选项
        onChange(transOptions[0]?.value, transOptions);
      }

      setFetching(false);
    } else {
      setSelectOptions(options);
    }
  };

  useEffect(() => {
    if (!isNil(value) && !selectOptions) {
      getOptions();
    }
  }, [value]);

  useEffect(() => {
    if (defaultSelectFirstOption && !selectOptions) {
      getOptions();
    }
  }, [defaultSelectFirstOption]);

  useDeepUpdateEffect(() => {
    getOptions();
  }, [params]);

  const handleOnDropdownVisibleChange = (open: boolean) => {
    if (open && !selectOptions) {
      getOptions();
    }
  };

  return (
    <Select
      ref={ref}
      placeholder={placeholder || '请选择'}
      allowClear
      showArrow
      loading={fetching}
      notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
      onDropdownVisibleChange={handleOnDropdownVisibleChange}
      value={selectOptions ? value : undefined}
      onChange={onChange}
      options={selectOptions}
      optionFilterProp="label"
      showSearch
      {...otherProps}
    />
  );
});

AutoSelect.defaultProps = {
  defaultSelectFirstOption: false,
};

export default AutoSelect;
