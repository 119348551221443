import React from 'react';
import { history } from 'umi';
import { Avatar } from 'antd';
import { removeAllHTMLTag } from '@/utils';
import defaultPhoto from '@/assets/images/默认讲师头像.png';

import styles from './LecturerCard.less';

/** 讲师中心 图片访问地址 */
export const previewUrl = (key) => `/lecturer/file/preview?key=${key}`;

const LecturerCard = ({ id, photo, lecturerName, levelName, workBranchName, remark }) => (
  <div
    className={styles.item}
    onClick={() => {
      history.push(`/teacher/teacher-list/teacher-detail?id=${id}`);
    }}
  >
    <Avatar size={56} className={styles.avatar} src={photo ? previewUrl(photo) : defaultPhoto} />
    <div className={styles.content}>
      <div className={styles.header}>
        <span className={styles.name}>{lecturerName}</span>
        <span className={styles.tag1}>{levelName}</span>
        <span className={styles.tag2}>{workBranchName}</span>
      </div>
      <div className={styles.desc}>{removeAllHTMLTag(remark)}</div>
    </div>
  </div>
);

export default LecturerCard;
