import { post, del, put, get } from '@/utils/fetch';
import { FetchResult } from '@/utils/defs';

export interface InvoiceProps {
  type: 'PS' | 'E'; // 发票类型:PS纸质专票, E电子发票 PI纸质普票(门户不展示)
  invoiceHeader: string; // 发票抬头
  taxId: string; // 税号
  content: string; // 发票内容
  registeredAddress?: string; // 注册地址
  registeredPhone?: string;
  bankName?: string;
  bankAccount?: string;
  email?: string;
  amount?: number; // 发票金额
  gmtInvoice: string; // 开票时间
  gmtCreate: string; // 创建时间
  invoiceStatus?: string; // 开票状态:10作废中 11已作废 , 20 待审核, 21 已审核 ,30开具中, 31已开具 -1未开票
  orderCode?: string;
  remark?: string;
}

// 订单列表
export const listApi = (params) => post<FetchResult>('/trainees/order/user/page', params);

// 订单详情
export const detailApi = (orderCode) => get(`/trainees/order/user/detail/${orderCode}`);

// 发起退款
export const startRefundApi = (orderCode) =>
  put(`/trainees/order/user/start-refund?orderCode=${orderCode}`);

// 再次购买
export const buyAgainApi = (orderCode) =>
  post(`/trainees/order/user/buy-again?orderCode=${orderCode}`);

// 删除订单
export const deleteApi = (orderCode) => del(`/trainees/order/user?orderCode=${orderCode}`);

// 取消订单
export const cancelApi = (orderCode) => put(`/trainees/order/user/cancel?orderCode=${orderCode}`);

// 取消退款
export const cancelrefundApi = (orderCode) =>
  put(`/trainees/order/user/cancel-refund?orderCode=${orderCode}`);

// 查询付款结果
export const queryPayResult = (orderCode) => get(`/trainees/order/user/pay/result/${orderCode}`);

// 订单关闭
export const clsoeOrder = (orderCode) =>
  put(`/trainees/order/user/close-over-time?orderCode=${orderCode}`);

// 获取对应的商品信息
export const getGoodsDetail = (params) => get('/trainees/order/user/goods/detail', params);

// 创建订单
export const createOrderApi = (params) => post('/trainees/order/user/create', params);

/**
 * 发票
 */
// 开发票
export const createInvoiceApi = (params) => post('/trainees/mobile/invoice/create', params);
// 发票详情
export const getInvoiceDetailApi = (id) => get(`/trainees/mobile/invoice/detail/${id}`);

// 重开发票
export const recreateInvoiceApi = (params) => post('/trainees/mobile/invoice/recreate', params);
// 查询失败原因
export const getRefundReasonApi = (order) => get(`/trainees/order/user/refund-reason?order=${order}`);
