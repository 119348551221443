import React, { useEffect, useState } from 'react';
import useList from '@/hooks/useList';
import { Pagination, Spin } from 'antd';
import Empty from '@/components/Empty';
import styles from './ResourceList.less';
import { getGoodsDetailByIdApi, getOrderListApi } from '../../services';
import GoodsCard from '../GoodsCard';

const OrderStatus = {
  NOT_PAY: 1, // 未支付
  PAY_SUCCESS: 2, // 支付成功
  PAY_REFUND: 3, // 支付失败
  BUY_SUCCESS: 4, // 交易成功
  ORDER_CLOSE: 5, // 订单关闭
};

const ResourceList = (props) => {
  const { status } = props;
  // const [resourceList, setResourceList] = useState(false);
  // const [current, setCurrent] = useState(0);
  // 根据status选择正确的useList实例

  function removeDuplicatesByGoodsId(arr) {
    const seen = new Map();
    return arr.filter((item) => {
      if (seen.has(item.id)) {
        return false;
      }
      seen.set(item.id, true);
      return true;
    });
  }

  const fetchGoodDetail = async (goodId) => {
    const response = await getGoodsDetailByIdApi(goodId);
    return response.data;
  };

  const fetchAllGoodsDetails = async (goodsList) => {
    // 使用 map 方法创建 Promise 数组
    const detailPromises = goodsList.map(async (item) => {
      const detail = await fetchGoodDetail(item.id);
      return { ...item, ...detail };
    });

    // 使用 Promise.all 处理并发请求
    const details = await Promise.all(detailPromises);
    return details;
  };
  const getListApi = async (param) => {
    const response = await getOrderListApi({
      pageNum: 1,
      pageSize: 100,
      goodsType: status,
    });
    const orderList = response.data.list.filter(
      (item) => (item.originalChannel === 6 && item.orderStatus === 2) || item.orderStatus === 4,
    );

    // 解析并提取数据
    const extractedLists = orderList.reduce((acc, item) => {
      try {
        if (
          item.goodsRemark &&
          typeof item.goodsRemark === 'string' &&
          item.goodsRemark.trim() !== ''
        ) {
          const parsedGoodsRemark = JSON.parse(item.goodsRemark) || null;
          if (
            parsedGoodsRemark &&
            parsedGoodsRemark.list &&
            Array.isArray(parsedGoodsRemark.list)
          ) {
            parsedGoodsRemark.list.forEach((subItem) => {
              if (
                subItem &&
                subItem.goods &&
                subItem.goodsId &&
                !acc.some((existing) => existing.goodsId === subItem.goodsId) &&
                subItem.goods.goodsType === Number(status)
              ) {
                const goods = {
                  ...subItem.goods,
                  order: item,
                };
                acc.push(goods);
              }
            });
          }
        }
      } catch (error) {
        console.error('Failed to parse goodsRemark:', error);
      }
      return acc;
    }, []);

    // 在提取数据之后
    const uniqueExtractedLists = removeDuplicatesByGoodsId(extractedLists);

    // 获取并合并详情
    const mergedList = await fetchAllGoodsDetails(uniqueExtractedLists);

    const mockResponse = {
      ...response,
      data: {
        ...response.data,
        totalCount: mergedList.length,
        list: mergedList,
      },
    };

    console.log('Extracted lists with details:', mergedList, 'mockResponse:', mockResponse);
    return mockResponse;
  };

  const [listData] = useList(getListApi, {
    queryParams: {
      goodsType: status,
      // id: 2, // goodsId
    },
  });

  window.console.log('listData', listData);

  const { loading, pagination, list } = listData;
  const { pageSize, pageNum, total, changePage } = pagination;
  // setResourceList(list);

  /*  useEffect(() => {
    if (listData.list) {
      const orderList = listData.list.filter(
        (item) =>
          item.originalChannel === 6 &&
          (item.orderStatus === OrderStatus.PAY_SUCCESS ||
            item.orderStatus === OrderStatus.BUY_SUCCESS),
      );
      // 解析并提取数据
      const extractedLists = orderList.reduce((acc, item) => {
        try {
          const parsedGoodsRemark = item.goodsRemark ? JSON.parse(item.goodsRemark) : {};
          if (parsedGoodsRemark.list && Array.isArray(parsedGoodsRemark.list)) {
            parsedGoodsRemark.list.forEach((subItem) => {
              if (!acc.some((existing) => existing.goods.goodsId === subItem.goods.goodsId)) {
                const goods = {
                  ...subItem.goods,
                  order: item,
                };
                acc.push(goods);
              }
            });
          }
        } catch (error) {
          console.error('Failed to parse goodsRemark:', error);
        }
        return acc;
      }, []);
      console.log('orderList', orderList, 'extractedLists', extractedLists);
      // orderList
      setResourceList(extractedLists);
    }
  }, [list]); */

  /*  // 检查是否需要显示Empty组件
  useEffect(() => {
    setEmpty(list.length === 0);
  }, [list]); */

  return (
    <Spin spinning={loading}>
      <div className={styles.root}>
        {list && list.length > 0 ? (
          <>
            <div className={styles.list}>
              {list.map((item) => {
                // 只有当item.isFree与status相等时才渲染GoodsCard
                if (item.goodsType === Number(status)) {
                  return <GoodsCard key={item.id} data={item} />;
                }
                return null; // 不符合条件时不返回任何内容
              })}
            </div>
            <div className={styles.pagination}>
              <Pagination
                showQuickJumper
                showSizeChanger
                onChange={changePage}
                current={pageNum}
                total={total}
                pageSize={pageSize}
              />
            </div>
          </>
        ) : (
          <Empty />
        )}
      </div>
    </Spin>
  );
};

export default ResourceList;
