import React, { useEffect, useState } from 'react';
import { Col, Spin, Row } from 'antd';
import Empty from '@/components/Empty';
import DetailPage from '../detail/DetailPage';
import styles from '../MyCertificatePage.less';
import { formItemsMap } from '../constant';

const ProCertificate = ({ list }) => {
  const [loading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [infoData, setInfoData] = useState({});
  const renderCardDesc = (type: string, data) => {
    const formItems = formItemsMap[type];
    return (
      <>
        <p className={styles.name}>{data[formItems[0].key] || '-'}</p>
        <div className={styles.description}>
          {formItems.slice(1).map((item) => {
            const val = (item.format ? item.format(data[item.key], data) : data[item.key]) || '-';
            return (
              <Row className={styles.text} key={item.label}>
                <Col>{`${item.label}：`}</Col>
                <Col title={val}>{val}</Col>
              </Row>
            );
          })}
        </div>
      </>
    );
  };

  const handleDetail = (item) => {
    setVisible(true);
    setInfoData(item);
  };

  useEffect(() => {});

  return (
    <div className={styles.proCertificate}>
      <Spin size="large" spinning={loading}>
        {Array.isArray(list) && list.length > 0 ? (
          <div className={styles.cardList}>
            {list.map((item, index) => (
              <div key={`card${index.toString()}`}>
                {renderCardDesc('5', item)}
                
              </div>
            ))}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
      {visible && (
        <DetailPage
          isModalVisible={visible}
          infoData={infoData}
          setVisible={setVisible}
          onChange={setInfoData}
        />
      )}
    </div>
  );
};

export default ProCertificate;
