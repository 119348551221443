/**
 * 登录流程
 * 1、钉钉免登，获取用户临时信息
 * 2、用临时信息返回的tenantCode,判断用户是否有主企业，有则直接用主企业获取tgt，构建单点登录url，否则进入选择企业页面先选择主企业
 * 3、获取用户信息
 */

import { biz, config, env, ready, runtime, error } from 'dingtalk-jsapi';
import { post } from '@/utils/fetch';
import { dingtalkLogin } from '@/services/common';
import { message } from 'antd';

const isDev = process.env.NODE_ENV === 'development';

// 判断当前build环境是预发还是生产
const isProd = ENV_BUILD === 'prod';

// const globalEnv = window['env'];
// // eslint-disable-next-line no-template-curly-in-string
// globalEnv.corpId = globalEnv.corpId.replace('${corpId}', '');
// // eslint-disable-next-line no-template-curly-in-string
// globalEnv.agentId = globalEnv.agentId.replace('${agentId}', '');

// 配置组织id 和 agentId
const defaultCorpId = isProd
  ? 'ding558f135fcc7839dda39a90f97fcb1e09'
  : 'ding609c435b9f9db54724f2f5cc6abecb85';
const defaultAgentId = isProd ? 1491469069 : 1479464356;
export const corpId = defaultCorpId;
export const agentId = defaultAgentId;
// export const agentId = 1286273937; // 日常
// export const agentId = 1315862835; // 预发
// export const agentId = 1093293307 // 生产
// export const corpId = umiEnv.DINGTALK_CORPID || 'dingd60496d6e52565fa35c2f4657eb6378f';
// // export const agentId = Number(umiEnv.DINGTALK_AGENTID) || 1093293307;

export const isDingtalk = env.platform !== 'notInDingTalk';

console.warn(`dingtalk corpId: ${corpId}`);
console.warn(`dingtalk agentId: ${agentId}`);

// 是否已鉴权
let authored = false;

// 钉钉sdk是否ready
export const ddReady = new Promise<boolean>((resolve) => {
  if (isDingtalk) {
    ready(() => {
      resolve(true);
      console.warn(`dingtalk corpId: ${corpId}`);
      console.warn(`dingtalk agentId: ${agentId}`);
    });
    error((err) => {
      if (err.errorCode === '9') {
        message.error('签名校验失败');

        authored = false;
      }
    });
  } else {
    resolve(false);
  }
});

// 获取临时鉴权code
export const requestAuthCode = async () => {
  if (!isDingtalk) return undefined;
  await ddReady;
  return runtime.permission.requestAuthCode({
    corpId,
  });
};

/**
 * 钉钉用户登录
 */
export const login = async (params = {}) => {
  const res = await requestAuthCode();
  if (!res) return { success: false, errMsg: '获取钉钉鉴权码异常', errCode: -1 };
  return dingtalkLogin({ code: res.code, ...params });
};

// 此处url好像需要苹果手机保留初次登入的地址
let url = window?.location?.href;

/**
 * 配置钉钉鉴权
 */
export const configDingtalk = async () => {
  if (!isDingtalk || authored) return;
  // 获取签名 https://iwhale-citybrain.yuque.com/uqfirs/znugow/ap4if3
  window.console.log('当前设备', window.navigator.userAgent);
  if (/(iPhone|iPad|iPod|iOS)/i.test(window.navigator.userAgent)) {
    // 判断iPhone|iPad|iPod|iOS
    url = window.location.href;
  }
  const res = await post('/om/dingtalk/address-book/dingtalk/sign', {
    url,
    agentId,
  });
  if (res.success) {
    const { nonceStr, sign: signature, timeStamp } = res.data as {
      nonceStr: string;
      sign: string;
      timeStamp: number;
    };
    console.warn('dd.config', {
      nonceStr,
      signature,
      timeStamp,
      url,
    });
    config({
      agentId, // 必填，微应用ID
      corpId, // 必填，企业ID
      timeStamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名
      type: 0, // 选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
      // 需要支持的钉钉功能列表
      jsApiList: [
        // 'runtime.info',
        // 'biz.contact.choose',
        // 'device.notification.confirm',
        // 'device.notification.alert',
        // 'device.notification.prompt',
        // 'biz.ding.post',
        // 'biz.util.openLink',
        'biz.contact',
        'biz.contact.departmentsPicker',
        'biz.contact.complexPicker',
      ], // 必填，需要使用的jsapi列表，注意：不要带dd。
    });
    await new Promise<void>((resolve) => {
      ready(resolve);
    });
    authored = true;
  } else {
    message.error(res.message || '签名获取失败');
  }
};

/**
 * 弹出钉钉选择用户对话框
 * @param disabledUsers 禁止选中的用户列表用户列表
 */
export const choosePeople = async (disabledUsers?: string[], callBack = (res) => {}) => {
  if (!isDingtalk) return undefined;
  await configDingtalk();
  return biz.contact.complexPicker({
    title: '导入用户',
    corpId,
    multiple: true,
    limitTips: '超出了',
    maxUsers: 30,
    pickedUsers: [],
    pickedDepartments: [],
    disabledUsers: disabledUsers || [],
    disabledDepartments: [],
    requiredUsers: [],
    requiredDepartments: [],
    appId: agentId,
    permissionType: 'GLOBAL',
    responseUserOnly: false,
    startWithDepartmentId: 0,
    onSuccess(res) {
      // 调用成功时回调
      window.console.log('导入部门后返回的数据:', res);
      callBack(res);
    },
    onFail(err) {},
  });
};

/**
 * 设置导航栏右侧单个按钮
 */
export const setDDNavMenu = async (text, callBack = () => {}) => {
  if (!isDingtalk) return;
  biz.navigation.setRight({
    show: true,
    control: true,
    text,
    onSuccess() {
      if (!text) {
        biz.util.share({
          type: 0,
          url: '',
          title: '鲸眼',
          content: '',
          image: '',
          onSuccess() {
            // onSuccess将在调起分享组件成功之后回调
            /**/
          },
          onFail(err) {},
        });
      }
      // 调用成功时回调
      callBack();
    },
    onFail(err) {
      // 调用失败时回调
      // console.log(err)
    },
  });
};

/**
 * 设置右侧多个按钮
 */
export const setDDRightMenu = async (items, callBack = (data) => {}) => {
  if (!isDingtalk) return;
  if (!items) return;
  biz.navigation.setMenu({
    textColor: '#3F9DFF',
    items,
    onSuccess(data) {
      /*
      {"id":"1"}
      */
      callBack(data);
    },
    onFail(err) {},
  });
};

/**
 * 选择部门信息
 * @param callBack 成功后的回调函数
 */
export const chooseDepartments = async (callBack = () => {}) => {
  if (!isDingtalk) return;
  await configDingtalk();
  biz.contact.departmentsPicker({
    title: '导入用户',
    corpId,
    multiple: true,
    // pickedDepartments: [],
    // disabledDepartments: [],
    // requiredDepartments: [],
    appId: agentId,
    permissionType: 'GLOBAL',
    onSuccess(res) {
      // 调用成功时回调
      window.console.log('导入部门后返回的数据:', res);
      callBack(res);
    },
    onFail(err) {
      // 调用失败时回调
      window.console.log(err);
    },
  });
};

/**
 * 设置导航栏标题
 */
export const setTitle = (title) => {
  if (isDingtalk) {
    biz.navigation.setTitle({
      title, // 控制标题文本，空字符串表示显示默认文本
    });
  }
};

/**
 * 设置左侧按钮
 */

export const setLeft = (text, callBack) => {
  if (isDingtalk) {
    biz.navigation.setLeft({
      control: true,
      text: text || '',
      onSuccess() {
        // 调用成功时回调
        // console.log(res);
        if (typeof callBack === 'function') {
          callBack();
        } else {
          biz.navigation.goBack({});
        }
      },
    });
  }
};
export const closeCurrentPage = () => {
  if (isDingtalk) {
    biz.navigation.close({
      onSuccess() {
        // 调用成功时回调
        // console.log(res);
      },
    });
  }
};

// 分享
export const ddShare = (title, url) => {
  if (!isDingtalk) return;
  biz.util.share({
    type: 1, // 分享类型，0:全部组件 默认；1:只能分享到钉钉；2:不能分享，只有刷新按钮
    url: url || '',
    title,
    content: '',
    // image: String,
    onSuccess() {
      // onSuccess将在调起分享组件成功之后回调
    },
    onFail(err) {},
  });
};
