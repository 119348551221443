import React, { useState, useRef } from 'react';
import { Spin, Button, Col, message, Image, Row, Space, Tooltip } from 'antd';
import Empty from '@/components/Empty';
import { getFileExtension } from '@/utils';
import { downloadByField } from '../services';
import styles from '../MyCertificatePage.less';

const EleCertificate = ({ list }) => {
  const timer = useRef(-1);
  const [loading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  return (
    <div className={styles.eleCertificate}>
      <Spin size="large" spinning={loading}>
        {Array.isArray(list) && list.length > 0 ? (
          <div className={styles.cardList}>
            {list.map((item, index) => (
              <div key={`card${index.toString()}`}>
                <div className={styles.name} title={item.certificateName}>
                  {item.certificateName || '-'}
                </div>
                <div className={styles.code}>
                  <span>证书编号：</span>
                  <span title={item.code}>{`${item.code || '-'}`}</span>
                </div>
                <div className={styles.photo}>
                  <Image
                    src={`/zs-api/common/download?fileKey=${item.image}`}
                    style={{ objectFit: 'contain' }}
                    preview={false}
                    width={260}
                    height={194}
                  />
                  <div className={styles.mask}>
                    <Space>
                      <Button
                        shape="round"
                        onClick={() => {
                          setVisible(true);
                          setSelectedImage(item.image);
                        }}
                      >
                        查看证书
                      </Button>
                      <Button
                        shape="round"
                        type="primary"
                        onClick={async () => {
                          const res = (await downloadByField(item.image)) ?? {};
                          if (res && `${res.code}` === '0') {
                            const houzhui = getFileExtension(res.filename);
                            const filename = item.certificateName
                              ? `${item.certificateName}.${houzhui}`
                              : res.filename;
                            const reader = new FileReader();
                            reader.readAsDataURL(res.data);
                            reader.onload = (e) => {
                              const a = document.createElement('a');
                              a.download = filename;
                              // @ts-expect-error
                              a.href = e.target?.result;
                              document.body.appendChild(a);
                              a.click();
                              // @ts-expect-error
                              timer.current = setTimeout(() => {
                                document.body.removeChild(a);
                              }, 1000);
                            };
                          } else {
                            message.error(res?.message ?? '证书图片下载失败');
                          }
                        }}
                        disabled={!item.image}
                      >
                        下载证书
                      </Button>
                    </Space>
                  </div>
                  <div style={{ display: 'none' }}>
                    <Image.PreviewGroup
                      preview={{
                        visible,
                        onVisibleChange: (vis) => {
                          setVisible(vis);
                        },
                      }}
                    >
                      <Image src={`/zs-api/common/download?fileKey=${selectedImage}`} />
                    </Image.PreviewGroup>
                  </div>
                  <div className={styles.date}>
                    <Row>
                      <Col style={{ marginRight: 17 }}>
                        {`截止日期: ${item.invalidDate?.split(' ')[0] || '-'}`}
                      </Col>
                      <Col>{`获取日期: ${item.gmtCreate?.split(' ')[0] || '-'}`}</Col>
                    </Row>
                    <Col>
                      <Tooltip
                        title={item.relSource}
                        color="#fff"
                        overlayInnerStyle={{ color: '#666' }}
                      >
                        {`证书来源：${item.relSource || '-'}`}
                      </Tooltip>
                    </Col>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </div>
  );
};

export default EleCertificate;
