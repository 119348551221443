import React, { useEffect, useState } from 'react';
import useQuery from '@/hooks/useQuery';
import { Form, Radio, Input, Spin, Modal } from 'antd';
import usePromisifyModal from '@/hooks/usePromisifyModal';
import { history } from 'umi';
import InvoiceConfirmModal from '@/pages/person/my-order/invoice/components/InvoiceConfirmModal';
import { dealRes } from '@/utils';
import {
  createInvoiceApi,
  getInvoiceDetailApi,
  InvoiceProps,
  recreateInvoiceApi,
} from '@/pages/person/my-order/services';
import AutoSelect from '@/components/AutoSelect';
import { getDictList } from '@/services/common';
import { useBoolean } from 'ahooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import FooterBar from '../components/FooterBar';
import OrderBreadcrumb from '../components/OrderBreadcrumb';
import styles from './InvoicePage.less';
import OrderTitle from '../components/OrderTitle';

const customBreadCrumb = {
  text: '我的订单',
  goBack: () => history.goBack(),
};

const InvoicePage = (props) => {
  const { orderCode, amount, id } = useQuery(); // 如果有id,则是重开发票页面
  const modal = usePromisifyModal();
  const [form] = Form.useForm();
  const [type, setType] = useState(); // 发票类型:PS纸质发票, E电子发票
  const [loading, { toggle: setLoading }] = useBoolean();
  const [detailData, setDetailDate] = useState<InvoiceProps>();

  const FormItem = () => [
    {
      name: 'type',
      label: '发票类型',
      rule: [{ required: true }],
      type: 'Radio',
      option: [
        { label: '纸质专票', value: 'PS' },
        { label: '电子发票', value: 'E' },
      ],
    },
    {
      name: 'invoiceHeader',
      label: '发票抬头',
      rule: [{ required: true }],
      type: 'Input',
      maxLength: 50,
    },
    {
      name: 'taxId',
      label: '税号',
      rule: [
        { required: true },
        {
          pattern: /^[0-9A-Z]{15,20}$/,
          message: '税号为15~20位数字或大写字母组合',
        },
      ],
      type: 'Input',
    },
    {
      name: 'content',
      label: '发票内容',
      rule: [{ required: true }],
      type: 'Select',
      option: [
        { label: '培训费', value: '培训费' },
        { label: '考试费', value: '考试费' },
      ],
    },
    {
      name: 'amount',
      label: '发票金额',
      rule: [{ required: true }],
      type: 'Input',
      disabled: true,
    },
    {
      name: 'email',
      label: '邮箱地址',
      rule: [{ required: true }],
      type: 'Input',
      hidden: type === 'PS',
      maxLength: 50,
    },
    {
      name: 'registeredAddress',
      label: '注册地址',
      rule: [{ required: type === 'PS' }],
      type: 'Input',
      maxLength: 50,
    },
    {
      name: 'registeredPhone',
      label: '注册电话',
      rule: [{ required: type === 'PS' }],
      type: 'Input',
      maxLength: 30,
    },
    {
      name: 'bankName',
      label: '开户银行',
      rule: [{ required: type === 'PS' }],
      type: 'Input',
      maxLength: 50,
    },
    {
      name: 'bankAccount',
      label: '银行账号',
      rule: [{ required: false }],
      type: 'Input',
      maxLength: 50,
    },
    { name: 'remark', label: '备注', rule: undefined, type: 'Input', maxLength: 100 },
  ];
  useEffect(() => {
    FormItem();
  }, [type, id]);

  // 重开发票,获取详情
  const getDetailData = async () => {
    setLoading();
    const data = (await dealRes(getInvoiceDetailApi(id), '', '获取发票详情失败')) || {};
    setDetailDate(data || {});
    setType(data.type);
    form.setFieldsValue({ ...data, amount: +data?.amount });
    setLoading();
  };
  useEffect(() => {
    if (id) {
      getDetailData();
    }
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({ amount });
  }, []);
  // 提交发票
  const handleSubmit = () => {
    const values = form.getFieldsValue();
    dealRes(createInvoiceApi({ ...values, orderCode }), '提交成功', '提交失败', () => {
      history.goBack();
    });
  };

  // 重开发票
  const handleReSubmit = () => {
    const values = form.getFieldsValue();
    dealRes(
      recreateInvoiceApi({ ...values, orderCode: detailData?.orderCode, id }),
      '提交成功',
      '提交失败',
      () => {
        history.goBack();
      },
    );
  };

  // 提交发票
  const handleFinish = (values) => {
    // 如果是重开发票,需要二次弹窗
    if (id) {
      Modal.confirm({
        title: '确认重开发票?',
        icon: <ExclamationCircleOutlined />,
        content: '发票只能重开一次，申请后原发票将作废，请谨慎选择。',
        onOk() {
          handleReSubmit();
        },
        onCancel() {},
      });
    } else {
      modal.show(<InvoiceConfirmModal values={values} onOk={handleSubmit} />);
    }
  };
  // 表单数据修改
  const handleValuesChange = (changeValues, allValues) => {
    // 如果修改发票类型,则对应修改表单项
    if (changeValues.type) {
      setType(changeValues.type);
    }
  };
  // 提交发票按钮
  const action = [
    {
      text: '提交发票',
      type: 'primary',
      onClick: form.submit,
    },
  ];
  const renderItem = (item) => {
    switch (item.type) {
      case 'Input':
        return <Input maxLength={item.maxLength} disabled={item.disabled} />;
      case 'Select':
        return (
          <AutoSelect
            request={getDictList}
            params={{ parentDictCode: 'FPNR' }}
            config={{ label: 'dictName', value: 'dictCode' }}
          />
        );
      case 'Radio':
        return (
          <Radio.Group>
            {item.option.map((i) => (
              <Radio key={i.value} value={i.value}>
                {i.label}
              </Radio>
            ))}
          </Radio.Group>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <OrderBreadcrumb onClick={customBreadCrumb.goBack}>{customBreadCrumb.text}</OrderBreadcrumb>
      <Spin spinning={loading}>
        <div className={`${styles.root}`}>
          <OrderTitle>{id ? '申请重开发票' : '开具发票'}</OrderTitle>
          <Form
            className={styles.form}
            form={form}
            onFinish={handleFinish}
            onValuesChange={handleValuesChange}
          >
            {FormItem()
              .filter((item) => !item.hidden)
              .map((item) => (
                <Form.Item key={item.name} name={item.name} label={item.label} rules={item.rule}>
                  {renderItem(item)}
                </Form.Item>
              ))}
          </Form>
          <FooterBar config={action} />
        </div>
      </Spin>
      {modal.instance}
    </>
  );
};

export default InvoicePage;
