const handleFormat = (values) => {
  const { xlxx, zgzc, sfzfmzp, sfzzmzp, zyzg, zjz } = values;
  const getId = v => v?.map(item => item?.response?.data?.id ?? undefined)?.filter(i => i)
    .join(',');
  const _xlxx = xlxx?.map((item = {}) => {
    const { xlzm = [] } = item;
    const _xlzm = getId(xlzm);
    return ({ ...item, xlzm: _xlzm });
  }) ?? undefined;
  const _zgzc = zgzc?.map((item = {}) => {
    const { zczm = [] } = item;
    const _zczm = getId(zczm);
    return ({ ...item, zczm: _zczm });
  }) ?? undefined;
  const _sfzfmzp = getId(sfzfmzp);
  const _sfzzmzp = getId(sfzzmzp);
  const _zyzg = zyzg?.map((item = {}) => {
    const { zyzgzm = [] } = item;
    const _zyzgzm = getId(zyzgzm);
    return ({ ...item, zyzgzm: _zyzgzm });
  }) ?? undefined;
  const _zjz = getId(zjz);
  const payload = ({
    ...values,
    xlxx: JSON.stringify(_xlxx),
    zgzc: JSON.stringify(_zgzc),
    sfzfmzp: _sfzfmzp,
    sfzzmzp: _sfzzmzp,
    zyzg: JSON.stringify(_zyzg),
    zjz: _zjz,
  });
  return payload;
};

export { handleFormat };
