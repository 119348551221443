import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/zh-cn';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style';

dayjs.locale('zh-cn');

/*
 * 重写toJSON方法，因为在调用JSON.stringify的时候，时间转换就调用的toJSON，这样会导致少8个小时，所以重写它的toJSON方法
 */
dayjs.prototype.toJSON = function () {
  return dayjs(this).format('YYYY-MM-DD HH:mm:ss');
};

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export default DatePicker;
