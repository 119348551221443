import React from 'react';
import Tabs, { TabPaneProps } from '@/components/Tabs';
import LayoutTitle from '../components/LayoutTitle';
import TrainingList from './components/TrainingList';
import styles from './MyTrainingPage.less';

const MyTrainingPage = () => {
  const tabPanes: Array<TabPaneProps> = [
    {
      key: '1',
      children: <TrainingList status="1" />,
      tab: '进行中',
    },
    {
      key: '0',
      children: <TrainingList status="0" />,
      tab: '未开始',
    },
    {
      key: '2',
      children: <TrainingList status="2" />,
      tab: '已结束',
    },
  ];

  return (
    <div className={styles.root}>
      <Tabs
        destroyInactiveTabPane
        extra={<LayoutTitle />}
        tabPanes={tabPanes}
        defaultActiveKey="1"
      />
    </div>
  );
};
export default MyTrainingPage;
