import React from 'react';
import { Form, Spin, Pagination } from 'antd';
import Container from '@/components/Container';
import SearchForm from '@/components/SearchForm';
import useList from '@/hooks/useList';
import Empty from '@/components/Empty';
import { listApi } from '../../services';
import OrderCard from '../OrderCard';
import styles from './OrderList.less';

export interface OrderListProps extends React.HTMLAttributes<HTMLDivElement> {
  orderStatus?: string;
}

const formItems = [
  {
    type: 'Input',
    label: '订单编号',
    name: 'orderCode',
  },
  {
    type: 'Input',
    label: '商品信息',
    name: 'goodsName',
  },
  {
    type: 'RangePicker',
    label: '创建时间',
    name: 'createDateArray',
  },
];

const OrderList = (props: OrderListProps) => {
  const { className = '', orderStatus, ...otherProps } = props;

  const [form] = Form.useForm();

  const [listProps, search, refresh] = useList(listApi, {
    form,
    queryParams: { orderStatus },
  });

  const { loading, pagination, list } = listProps;
  const { total, pageNum, pageSize, changePage } = pagination;

  const { submit, reset } = search;

  const delRefresh = () =>
    refresh(
      pageNum > 1 &&
        pageNum === Math.ceil(total / pageSize) &&
        (total % pageSize === 1 || pageSize === 1)
        ? pageNum - 1
        : pageNum,
    );

  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      <Container className={styles.wrap}>
        <SearchForm formItems={formItems} form={form} onSubmit={submit} onReset={reset} />
      </Container>
      <Container className={styles.content}>
        <Spin spinning={loading}>
          {list?.length > 0 ? (
            <>
              {list.map((item) => (
                <OrderCard
                  key={item.orderCode}
                  {...item}
                  delRefresh={delRefresh}
                  refresh={refresh}
                />
              ))}
              <Pagination
                style={{ marginTop: 16, textAlign: 'right' }}
                current={pageNum}
                pageSize={pageSize}
                onChange={changePage}
                total={total}
                showLessItems
                showQuickJumper
                showSizeChanger
              />
            </>
          ) : (
            <Empty />
          )}
        </Spin>
      </Container>
    </div>
  );
};

export default OrderList;
