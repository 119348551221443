import React from 'react';
import EmptyIcon from '@/assets/images/空数据.svg';
import styles from './Empty.less';

export interface EmptyProps extends React.HTMLAttributes<HTMLDivElement> {
  emptyIcon?: string;
  emptyText?: string;
}

const Empty = (props: EmptyProps) => {
  const { emptyIcon, emptyText, className = '', ...otherProps } = props;
  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      <img src={emptyIcon || EmptyIcon} alt="" className={styles.emptyIcon} />
      <div>{emptyText}</div>
    </div>
  );
};

Empty.defaultProps = {
  emptyText: '暂无数据',
};

export default Empty;
