import React from 'react';
import { history } from 'umi';
import dayjs from 'dayjs';
import Card from '@/components/Card';

/** 资讯中心 图片访问地址 */
export const previewUrl = (key) =>
  `${window['apiPrefix'] ?? ''}/information/common/download?fileKey=${key}`;

export interface InfoCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const InfoCard = (props) => {
  const { fileKey, newsType, newsId, linkedUrl, time, ...rest } = props;

  const handleDetail = () => {
    if (newsType === 1) {
      window.open(linkedUrl);
    } else {
      history.push({
        pathname: '/info/info-center/information-detail',
        query: {
          pageType: newsId,
        },
      });
    }
  };

  return (
    <Card
      onClick={handleDetail}
      url={previewUrl(fileKey)}
      time={time ? dayjs(time).format('MM月DD日 HH:mm') : null}
      {...rest}
    />
  );
};

export default InfoCard;
