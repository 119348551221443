import React, { useEffect, useMemo, useState } from 'react';
import { history, useSelector, useDispatch } from 'umi';
import { Layout, Badge, Divider, Space } from 'antd';
import classPrefix from 'prefix-classnames';
import MyIcon from '@/components/MyIcon';
import logo from '@/assets/images/logo.png';
import cart from '@/assets/images/cart.svg';
import {
  // MenuItemType,
  MenuLayout,
} from '@orca-fe/pocket';
import { getCartCount } from '@/services/cart';
import { toDingtalkLogin } from '@/utils';
import { isDingtalk } from '@/utils/dingUtils';
import Theme from '@/themes/theme';
import Search from '../Search';
import Avatar from '../Avatar';
import TopMenu from '../TopMenu';
import Footer from '../Footer';
import './MenuLayout.less';

const { Content } = Layout;

const PREFIX = 'portal-layout';

const px = classPrefix(PREFIX);

export interface MenuLayoutProps {
  // menuData: MenuItemType[];
  children?: React.ReactElement;
}

const nudeRouteList = [
  '/utc/base-tpl/user-login-pc', // 登录页
  '/utc/base-tpl/user-login-pc/bind-info', // 钉钉/微信扫码完善信息页
  '/utc/base-tpl/agreement', // 协议
  '/utc/base-tpl/yn-register', // 注册页
  '/dd-login', // 钉钉PC端免登页
  '/dd-login/bind-info', // 钉钉PC端免登完善信息页
  '/utc/base-tpl/forget-psw', // 忘记密码
  '/base-tpl/modify-password', // 修改密码
];

const MenuLayoutPage = (props: MenuLayoutProps) => {
  const { children } = props;

  const {
    common: { userInfo, hasLoginStatus, messageUnReadCount, todoUnReadCount },
  } = useSelector(({ common }) => ({ common }));

  const [cartCount, setCartCount] = useState();

  const { location } = history;

  const { pathname } = location;

  const nudeRoutePage = nudeRouteList.includes(pathname);

  const dispatch = useDispatch();

  const style = useMemo(() => {
    if (pathname.includes('home')) {
      return {
        backgroundImage: Theme.background.Home ? `url("${Theme.background.Home}")` : 'unset',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        backgroundSize: '100% 100%',
      };
    }
    return {};
  }, [pathname]);

  useEffect(() => {
    if (isDingtalk && pathname !== '/dd-login') {
      toDingtalkLogin();
    } else if (!nudeRoutePage) {
      // 获取用户信息、菜单信息
      dispatch({ type: 'common/getUserInfo' });
      getCartCount().then((res) => {
        if (res.success && res.data?.list) {
          setCartCount(res.data.list.length || 0);
        }
      });
    }
  }, []);

  if (nudeRoutePage) {
    return children;
  }

  return (
    <Layout className={`${px('root')}`} style={style}>
      {hasLoginStatus ? (
        <MenuLayout
          classPrefix="portal-menu"
          mainMenuSide="top"
          title=""
          logo={
            <img
              width={140}
              src={logo}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/home');
              }}
            />
          }
          themeSide="light"
          themeHeader="light"
          style={{ height: '100%' }}
          headerExtra={
            <div className={px('header')}>
              <Search />
              <div className={px('header-right')}>
                <TopMenu />
                <Space size={24}>
                  <Divider type="vertical" style={{ height: '2.5em' }} />
                  <Badge count={cartCount} size="small" style={{ marginRight: '20px !important' }}>
                    <img
                      width={24}
                      src={cart}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push('/zyzx/cart-center');
                      }}
                    />
                    {/* <MyIcon
                      type="icon-tongzhi"
                      color="rgba(0,10,32,0.65)"
                      size={24}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push('/my-message');
                      }}
                    /> */}
                  </Badge>

                  {/* <Divider type="vertical" style={{ height: '2.5em' }} /> */}
                  <Badge dot count={messageUnReadCount + todoUnReadCount} size="small">
                    <MyIcon
                      type="icon-tongzhi"
                      color="rgba(0,10,32,0.65)"
                      size={24}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push('/my-message');
                      }}
                    />
                  </Badge>
                  <Avatar userInfo={userInfo} />
                </Space>
              </div>
            </div>
          }
        >
          {/* 渲染子应用 */}
          <Content className={px('content')}>{children}</Content>
          <Footer pathname={pathname} />
        </MenuLayout>
      ) : null}
    </Layout>
  );
};

export default MenuLayoutPage;
