import React, { useImperativeHandle } from 'react';
import cn from 'classnames';
import { Form } from 'antd';
import { useUpdateEffect } from 'ahooks';
import useTable, { OptionsProps as UseTableOptions } from '@/hooks/useTable';
import Container from '@/components/Container';
import SearchForm from '@/components/SearchForm';
import CommonTable, { TableProps as CommonTableProps } from '@/components/CommonTable';

import styles from './AutoTable.less';

interface OriginResult {
  success: boolean;
  data: {
    totalCount: number;
    list: Array<unknown>;
  };
}

export interface TableProps extends CommonTableProps {
  request: (params?: unknown) => Promise<OriginResult>; // api请求
  params?: Record<string, unknown>; // 查询页面需要的额外参数, 动态变化时重置状态重新发起请求
  refresh?: boolean; //  用于外部触发表格强制刷新 (保留所有搜索状态的刷新)
  extraContent?: React.ReactNode | ((params?: unknown) => React.ReactNode); // 操作功能内容
  showType?: 'default' | 'mini'; // default-代表搜索项和表格分离的 mini-代表搜索项和表格不分离版本
  formItems?: Array<unknown>; // 用于表格搜索的表单项
  searchType?: 'advance' | 'simple'; // 搜索模式 , 分为 advance、simple两种模式 --默认为advance模式,  simple模式表示简易搜索,不需要搜索重置按钮
  // useRequestOptions?: UseTableOptions; // useTable的配置项
  useRequestOptions?: Record<string, unknown>; // useTable的配置项
  className?: string;
}

export type AutoTableType<T = any> = (
  props: TableProps,
  ref?: React.Ref<T>,
) => React.ReactElement | null;

const AutoTable: AutoTableType = (props, ref) => {
  const {
    request,
    params,
    refresh: isRefresh,
    extraContent,
    showType = 'default',
    formItems,
    searchType,
    useRequestOptions,
    className = '',
    ...otherTableProps
  } = props;

  const [form] = Form.useForm();

  const [tableProps, search, refresh, changeCurrent, del] = useTable(request, {
    form,
    params,
    useRequestOptions, // 请参考 umiRequest....
  });

  const { submit, reset } = search;

  useUpdateEffect(() => {
    // 使用上一次的 params，重新执行 service, 用于保留条件进行刷新的场景
    refresh();
  }, [isRefresh]);

  useImperativeHandle(
    ref,
    () => ({
      refresh, // 刷新列表
      del, // 删除列表最后一项时，会刷新到上一页
    }),
    [refresh, del],
  );

  const miniContainer = cn(styles.container, {
    [styles.miniContainer]: showType === 'mini',
  });

  return (
    <div className={`${styles.root} ${className}`}>
      {formItems && formItems.length > 0 && (
        <Container className={miniContainer}>
          <SearchForm
            form={form}
            onSubmit={submit}
            onReset={reset}
            formItems={formItems}
            searchType={searchType}
          />
        </Container>
      )}
      <Container className={miniContainer}>
        {extraContent && <div className={styles.extraContent}>{extraContent}</div>}
        <CommonTable {...tableProps} {...otherTableProps} />
      </Container>
    </div>
  );
};

export default React.forwardRef(AutoTable);
