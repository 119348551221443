import React from 'react';
import { history } from 'umi';
import { Spin, Image, Badge, Modal } from 'antd';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import Theme from '@/themes/theme';
import Container from '@/components/Container';
import MyIcon from '@/components/MyIcon';
import defaultImg from '@/assets/images/courseDefault.png';
import { getTaskList } from '../../services';

import styles from './TaskCard.less';
import { examTypeMap } from '@/pages/study-calendar';

export interface TaskCardProps extends React.HTMLAttributes<HTMLDivElement> {}

/** 培训中心 图片访问地址 */
const previewUrl = (key) => `/train/file/show/${key}`;

// 任务内容卡片
const ContentCard = (props: any) => {
  const type = props.type;
  const isExam = type === 2;
  const name = isExam ? props.examName : props.courseName;
  const startTime = isExam ? props.examStartTime : props.gmtStart;
  const toDetail = () => {
    if (isExam) {
      if (props?.isMicrocomputerSideExam === 1) {
        Modal.warning({
          title: '提示',
          content: '该考试为微机考试，本设备无权限，请使用指定设备',
        });
        return;
      }
      const params = {
        planId: props.planId,
        type: props.examType,
        name: props.planName,
        model: props.model,
        questionnaireId: props.questionnaireId,
        startTime: props.examStartTime,
        endTime: props.examEndTime,
        examTime: props.examTime,
        isAheadSchedule: props.isAheadSchedule,
        aheadMinute: props.aheadMinute,
      };
      history.push({
        pathname: '/exam/user-exam/my-exam/enter-exam?from=main',
        state: params,
      });
    } else {
      history.push({
        pathname: '/pxgl/course',
        query: { courseId: props.courseId },
      });
    }
  };

  const dom = (
    <div className={styles.list_item} onClick={toDetail}>
      {isExam ? (
        <div className={styles.examLogo}>{examTypeMap[props.examType]}</div>
      ) : (
        <Image
          preview={false}
          className={styles.list_image}
          src={props.fileId ? previewUrl(props.fileId) : defaultImg}
          fallback={defaultImg}
        />
      )}

      <div className={styles.list_content}>
        <div className={styles.list_title}>{name}</div>
        <div className={styles.list_time}>
          <MyIcon type="icon-shijian" />
          <span className={styles.list_time_text}>{dayjs(startTime).format('MM月DD日 HH:mm')}</span>
        </div>
      </div>
    </div>
  );

  return props.isMicrocomputerSideExam === 1 ? (
    <Badge.Ribbon text="微机考试" color="#ff9919">
      {dom}
    </Badge.Ribbon>
  ) : (
    dom
  );
};

const TaskCard = (props: TaskCardProps) => {
  const { data: requestData, loading } = useRequest(getTaskList);
  const { data } = requestData || {};

  return data?.length > 0 ? (
    <Container
      className={styles.root}
      title="我的任务"
      extra={
        <span
          onClick={() => {
            history.push('/study-calendar');
          }}
        >
          查看更多
        </span>
      }
      style={{ backgroundImage: Theme.background.Gradient ?? 'unset' }}
    >
      <Spin spinning={loading}>
        {data.map((item) => (
          <ContentCard key={item.newsId} {...item} />
        ))}
      </Spin>
    </Container>
  ) : null;
};

export default TaskCard;
