/* eslint-disable @typescript-eslint/no-explicit-any */
import { extend, RequestOptionsInit, ResponseError } from 'umi-request';
import { message } from 'antd';
import moment from 'moment';
import { history } from 'umi';
import { toDingtalkLogin } from '@/utils';
import { isDingtalk } from '@/utils/dingUtils';

try {
  const _fetch = window.fetch;
  // 全局fetch劫持拦截
  window.fetch = (...args: unknown[]) =>
    _fetch(...args).then((res) => {
      if (res) {
        const _res = res.clone();
        _res?.json()?.then((response) => {
          const { data, errCode } = response || {};
          if (
            errCode === 401 &&
            (data?.indexOf('http://') > -1 || data?.indexOf('https://') > -1)
          ) {
            // 兼容钉钉
            if (isDingtalk) {
              toDingtalkLogin();
            } else {
              // 动态设置登录之后回调地址
              const { pathname, search } = window.location || {};
              document.cookie = `history_path=${pathname}${search};path=/`;
              window.location.href = data;
            }
          }

          if (errCode === 403) {
            history.replace('/exception/403');
          }
        });
      }
      return res;
    });
} catch (e) {}

/* 异常处理 */
const errorHandler = function (error: ResponseError) {
  if (error.response) {
    const { data, errCode } = error.data;
    if (
      (error.response.status === 401 || errCode === 401) &&
      (data?.indexOf('http://') > -1 || data?.indexOf('https://') > -1)
    ) {
      // 兼容钉钉
      if (isDingtalk) {
        toDingtalkLogin();
      } else {
        // 动态设置登录之后回调地址
        const { pathname, search } = window.location || {};
        document.cookie = `history_path=${pathname}${search};path=/`;
        window.location.href = data;
      }
    }

    if (error.response.status === 403 || errCode === 403) {
      history.replace('/exception/403');
    }
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error.response.status, error.request);
  } else {
    // The request was made but no response was received or error occurs when setting up the request.
    console.error('Request error:', error.message);
    if (error.type === 'Timeout') {
      message.warn('网络问题，请刷新重试。');
    }
  }
  throw error;
};

const request = extend({
  // 为每个请求添加前缀
  prefix: '',
  errorHandler,
  timeout: 30000,
});

/* 处理data */
const handleResponseData = <T>(data: T) => data;

const get = <T = any>(
  url: string,
  params?: Record<string, number | string | boolean> | URLSearchParams,
  options?: RequestOptionsInit,
) =>
  request
    .get<T>(url, { ...options, params })
    .then(handleResponseData);

const post = <T = any>(url: string, data?: Record<string, any>, options?: RequestOptionsInit) =>
  request
    .post<T>(url, { ...options, data })
    .then(handleResponseData);

const put = <T = any>(url: string, data?: Record<string, any>, options?: RequestOptionsInit) =>
  request
    .put<T>(url, { ...options, data })
    .then(handleResponseData);

const del = <T = any>(url: string, data?: Record<string, any>, options?: RequestOptionsInit) =>
  request
    .delete<T>(url, { ...options, data })
    .then(handleResponseData);

const upload = <T = any>(url: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return request
    .post<T>(url, { body: formData })
    .then(handleResponseData);
};

// post下载流文件处理方法
const handleDownloadData = async (datas) => {
  const { data, response } = datas ?? {};
  const blobData = await response.blob();
  if (!data || (data && blobData.type === 'application/json')) {
    if (`${data?.errCode}` === '-999') {
      const { datas: { loginUrl = '' } = {} } = data;
      window.location.href = loginUrl;
    }
    return data;
  }
  const disposition = response?.headers?.get('content-disposition');
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);
  const [matchName] = matches ?? [];
  const filename = matchName
    ? decodeURI(matchName).replace('filename=', '')
    : `${moment(new Date()).format('YYYY-MM-DD')}.xls`;
  return { data: blobData, filename, code: '0' };
};

const postDownload = <T = any>(
  url: string,
  data?: Record<string, any>,
  options?: RequestOptionsInit,
) =>
  request
    .get<T>(url, { ...options, data, getResponse: true })
    .then(handleDownloadData);

/**
 * // How to upload file?
 * const formData = new FormData();
 * formData.append('file', file);
 * fetch('/api/v1/some/api', { method: 'POST', body: formData });
 */

// 导出你所需要使用的方法
export { request, get, post, put, del, upload, postDownload };
