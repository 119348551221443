import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, Tooltip } from 'antd';
import Descriptions from '@/components/Descriptions';
import { dealRes } from '@/utils';
import { getInvoiceDetailApi, InvoiceProps } from '@/pages/person/my-order/services';
import { useBoolean } from 'ahooks';
import { history } from '@@/core/history';
import { InvoiceStatus, InvoiceType } from '@/pages/person/my-order/invoice/constant';
import styles from './InvoiceDetailModal.less';

export interface InvoiceDetailModalProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string; // 订单id
}

const InvoiceDetailModal = (props: InvoiceDetailModalProps) => {
  const { id, ...otherProps } = props;
  const [loading, { toggle: setLoading }] = useBoolean();
  const [detailData, setDetailDate] = useState<InvoiceProps>();
  const [btnProps, setBtnProps] = useState<{ text: string; disabled: boolean }>({
    text: '请稍后',
    disabled: false,
  }); // 按钮属性
  const {
    invoiceHeader,
    taxId,
    content,
    registeredAddress,
    registeredPhone,
    bankName,
    bankAccount,
    email,
    type,
    amount,
    remark,
    gmtCreate,
    gmtInvoice,
  } = detailData ?? {};
  const getDetailData = async () => {
    setLoading();
    const data = (await dealRes(getInvoiceDetailApi(id), '', '获取发票详情失败')) || {};
    setDetailDate(data || {});
    if (data?.invoiceStatus) {
      if (data?.recreateTimes < 1 && data.invoiceStatus === InvoiceStatus.已开具) {
        setBtnProps({
          text: '申请重开',
          disabled: false,
        });
      } else if (data?.recreateTimes < 1 && data.invoiceStatus === InvoiceStatus.待审核) {
        setBtnProps({
          text: '开票中',
          disabled: true,
        });
      } else if (data.invoiceStatus === InvoiceStatus.已开具) {
        setBtnProps({
          text: '已开票',
          disabled: true,
        });
      } else if (data?.recreateTimes >= 1 && data.invoiceStatus === InvoiceStatus.待审核) {
        setBtnProps({
          text: '重开中',
          disabled: true,
        });
      }
    }
    setLoading();
  };
  useEffect(() => {
    if (id) {
      getDetailData();
    }
  }, [id]);
  const list = [
    { label: '发票类型', value: type && InvoiceType[type] },
    { label: '发票抬头', value: invoiceHeader },
    { label: '税号', value: taxId },
    { label: '发票内容', value: content },
    { label: '发票金额', value: amount },
    { label: '开票日期', value: gmtInvoice },
    { label: '申请时间', value: gmtCreate },
    { label: '邮箱地址', value: email, hidden: type === 'PS' },
    { label: '注册地址', value: registeredAddress, hidden: !registeredAddress },
    { label: '注册电话', value: registeredPhone, hidden: !registeredPhone },
    { label: '开户银行', value: bankName, hidden: !bankName },
    { label: '银行账号', value: bankAccount, hidden: !bankAccount },
    { label: '备注', value: remark, hidden: !remark },
  ];

  const handleConfirm = () => {
    history.push({
      pathname: '/person/my-order/invoice',
      query: { id },
    });
  };

  return (
    <Modal
      title="发票详情"
      className={styles.root}
      width={555}
      {...otherProps}
      footer={[
        <Button key="cancel" onClick={otherProps?.onCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" disabled={btnProps.disabled} onClick={handleConfirm}>
          {btnProps.text}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Descriptions gutter={[28, 16]}>
          {list
            .filter((item) => !item.hidden)
            .map(
              (item) =>
                item.value && (
                  <Descriptions.Item key={item.label} span={24} label={item.label}>
                    <Tooltip title={item.value}>{item.value}</Tooltip>
                  </Descriptions.Item>
                ),
            )}
        </Descriptions>
      </Spin>
    </Modal>
  );
};

export default InvoiceDetailModal;
