import React from 'react';
import { history } from 'umi';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './OrderBreadcrumb.less';

const OrderBreadcrumb = (props) => {
  const { className, onClick, children, ...otherProps } = props;

  const handleClick = () => {
    if (onClick) {
      onClick?.();
    } else {
      history.push('/person/my-order');
    }
  };

  return (
    <div className={`${styles.root} ${className}`} {...otherProps} onClick={handleClick}>
      <ArrowLeftOutlined />

      <span className={styles.label}>{children || '我的订单'}</span>
    </div>
  );
};

export default OrderBreadcrumb;
