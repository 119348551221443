import React from 'react';
import { Modal, Tooltip } from 'antd';
import Descriptions from '@/components/Descriptions';
import { InvoiceProps } from '@/pages/person/my-order/services';
import styles from './InvoiceConfirmModal.less';

export interface InvoiceConfirmModalProps extends React.HTMLAttributes<HTMLDivElement> {
  values: InvoiceProps;
  onOk: () => void;
}

const InvoiceConfirmModal = (props: InvoiceConfirmModalProps) => {
  const { values, ...otherProps } = props;
  const {
    invoiceHeader,
    taxId,
    content,
    registeredAddress,
    registeredPhone,
    bankName,
    bankAccount,
    email,
    type,
  } = values;
  const list =
    type === 'PS'
      ? [
        { label: '发票抬头', value: invoiceHeader },
        { label: '税号', value: taxId },
        { label: '发票内容', value: content },
        { label: '注册地址', value: registeredAddress },
        { label: '注册电话', value: registeredPhone },
        { label: '开户银行', value: bankName },
        { label: '银行账号', value: bankAccount },
      ]
      : [
        { label: '发票抬头', value: invoiceHeader },
        { label: '税号', value: taxId },
        { label: '发票内容', value: content },
        { label: '电子邮箱', value: email },
      ];

  return (
    <Modal title="发票信息确认" className={styles.root} width={555} {...otherProps}>
      <Descriptions gutter={[28, 16]}>
        {list.map(
          (item) =>
            item.value && (
              <Descriptions.Item key={item.label} span={24} label={item.label}>
                <Tooltip title={item.value}>{item.value}</Tooltip>
              </Descriptions.Item>
            ),
        )}
      </Descriptions>
    </Modal>
  );
};

export default InvoiceConfirmModal;
