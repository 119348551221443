import React from 'react';
import { Row, Col } from 'antd';
import { history } from 'umi';
import Theme from '@/themes/theme';
import Container from '@/components/Container';
import styles from './EntryCard.less';

export interface EntryCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const cardMap = [
  { title: '培训', icon: Theme.icons.Train, url: '/pxgl/training-center?isOpen=1' },
  { title: '考试', icon: Theme.icons.Exam, url: '/exam/user-exam/exam' },
  { title: '练习', icon: Theme.icons.Practice, url: '/exam/user-exam/practice' },
  { title: '问卷', icon: Theme.icons.Questionnaire, url: '/wjquest/client/survey-center' },
  { title: '公开课', icon: Theme.icons.OpenClass, url: '/pxgl/training-center?isOpen=2' },
  { title: '报名', icon: Theme.icons.Register, url: '/person/my-register' },
  { title: '直播', icon: Theme.icons.Live, url: '/pxgl/live-list' },
  { title: '图书馆', icon: Theme.icons.Library, url: '/knowledge/library' },
];

const EntryCard = (props: EntryCardProps) => (
  <Container className={styles.root} style={{ backgroundImage: Theme.background.Gradient ?? 'unset' }}>
    <Row gutter={[0, 16]}>
      {cardMap.map((item, key) => (
        <Col
          span={6}
          key={key}
          className={styles.col}
          onClick={() => {
            if (item.url) {
              history.push(item.url);
            }
          }}
        >
          <img width={48} src={item.icon} />
          <span className={styles.text}>{item.title}</span>
        </Col>
      ))}
    </Row>
  </Container>
);

export default EntryCard;
