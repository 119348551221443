import { get, post } from '@/utils/fetch';
import { FetchResult } from '@/utils/defs';

/** 获取关注讲师数量 */
export const getFocusCount = () =>
  post<FetchResult>('/trainees/mobile/lecturer-focus/focusList', { pageNum: 1, pageSize: 1 });

/** 获取收藏数量 */
export const getCollectionCount = () =>
  post<FetchResult>('/trainees/mobile/zk-resource-collect/collectList', {
    pageNum: 1,
    pageSize: 1,
  });

/**
 * 查询收藏题目的总数
 */
export const getQuestionStoreTotal = () => get<FetchResult>('/ks-api/question/favorite/getCount');

/** 获取订单数量 */
export const getOrderCount = () =>
  post<FetchResult>('/trainees/order/user/page', { pageNum: 1, pageSize: 1 });
