import React from 'react';
import { Modal, Button, message, Form, Input, Alert, Space, Checkbox } from 'antd';
import { history, Link, useDispatch } from 'umi';
import { useLockFn } from 'ahooks';
import { ExclamationCircleFilled } from '@ant-design/icons';
import useQuery from '@/hooks/useQuery';
import AutoSelect from '@/components/AutoSelect';
import regExp from '@/utils/regExp';
import { login } from '@/utils/dingUtils';
import { getDictList } from '@/services/common';

import Wrap from './components/Wrap';

import styles from './BindInfo.less';

const BindInfo = () => {
  const [form] = Form.useForm();
  const { redirectUrl } = useQuery();
  const dispatch = useDispatch();

  const handleFinish = useLockFn(async (value) => {
    const { agreement, ...rest } = value || {};
    const { errMsg, success } = await login(rest);
    if (success) {
      dispatch({ type: 'common/getUserInfo' });
      if (redirectUrl === '/' || !redirectUrl) {
        history.replace('/home');
      } else {
        history.replace(redirectUrl);
      }
    } else {
      message.error(errMsg || '绑定失败，请重试');
      form.validateFields();
    }
  });

  return (
    <Wrap>
      <Form
        className={styles.root}
        onFinish={handleFinish}
        requiredMark={false}
        form={form}
        layout="vertical"
        initialValues={{ certType: '01' }}
      >
        <div className={styles.bindTitle}>钉钉账号绑定</div>
        <Alert
          type="warning"
          message="根据相关法律法规要求，请务必准确填写本人的证件信息"
          banner
          style={{ margin: '16px 0' }}
        />
        <Form.Item
          label="证件类型"
          name="certType"
          rules={[{ required: true, message: '请选择证件类型' }]}
        >
          <AutoSelect
            request={getDictList}
            config={{ label: 'dictName', value: 'dictValue' }}
            params={{ parentDictCode: 'card_type' }}
            placeholder="请选择证件类型"
          />
        </Form.Item>
        <Form.Item
          label="证件号码"
          name="certCode"
          dependencies={['certType']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                const certType = getFieldValue('certType');
                if (!value) {
                  return Promise.reject(new Error('请输入证件号码'));
                } else if (certType === '01' && !regExp('certCode').pattern?.test(value)) {
                  return Promise.reject(new Error('请输入正确格式的身份证号'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="请输入证件号码" />
        </Form.Item>
        <Form.Item label="姓名" name="userName" rules={[{ required: true, whitespace: true }]}>
          <Input placeholder="请输入证件上的姓名" />
        </Form.Item>
        <Form.Item
          name="agreement"
          rules={[{ required: true, message: '请勾选用户服务协议以及隐私政策' }]}
        >
          <Checkbox.Group>
            <Checkbox value="true">
              我已阅读并同意
              <Link to="/utc/base-tpl/agreement" target="_blank">
                《用户注册与隐私保护服务协议》
              </Link>
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
          <Button block shape="round" type="primary" htmlType="submit">
            绑定
          </Button>
          <Button
            block
            shape="round"
            onClick={() => {
              Modal.confirm({
                title: `取消后，所填写信息将丢失，确定取消吗？`,
                okText: '确定',
                cancelText: '取消',
                icon: <ExclamationCircleFilled />,
                onOk: () => {
                  window.open('/home', '_self');
                },
              });
            }}
          >
            取消
          </Button>
        </Space>
      </Form>
    </Wrap>
  );
};

export default BindInfo;
