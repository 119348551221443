import React, { useEffect, useState, useMemo, useContext } from 'react';
import { message, Avatar, Affix } from 'antd';
import { history, useSelector, useDispatch } from 'umi';
import { useSize } from 'ahooks';
import cn from 'classnames';
import useQuery from '@/hooks/useQuery';
import MyIcon from '@/components/MyIcon';
// import CourseSelection from '@/assets/images/courseSelection.svg';
import defaultAvatar from '@/assets/images/avatar.png';
import getEmitter, { Events } from '@/utils/EventEmitter';
import { prefixUUCImage } from '@/utils';
import LayoutContext from './LayoutContext';
import {
  getFocusCount,
  getCollectionCount,
  getOrderCount,
  getQuestionStoreTotal,
} from './services';
import styles from './_layout.less';

/** 切换tab需要刷新数量的路由 */
const needRefreshRoute = ['/my-focus', '/my-collection', '/my-order', '/my-course'];

const Sider = ({ menus }) => {
  const { activeMenu, routePush } = useContext(LayoutContext);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'common/getClassCreditInfo' });
  }, []);

  const {
    common: { userInfo, creditInfo },
  } = useSelector(({ common }) => ({ common }));

  const { userName, avatar } = userInfo || {};
  const { allScore } = creditInfo || {};

  return (
    <div className={styles.sider}>
      <Avatar size={80} src={prefixUUCImage(avatar, defaultAvatar)} className={styles.avatar} />
      <span className={styles.username}>{userName}</span>
      <div
        className={styles.meta}
        onClick={() => {
          history.push('/person/my-credit');
        }}
      >
        {`学分：${allScore ?? '-'}`}
      </div>
      <div className={styles.menu}>
        {menus.map((i) => {
          const { text, route, icon, value } = i;
          const isActive = activeMenu?.route === route;
          return (
            <div
              className={cn(styles.item, isActive ? styles.active : styles.inactive)}
              key={text}
              onClick={() => routePush(i)}
            >
              <span className={styles.icon}>{icon}</span>
              <span className={styles.text}>
                {needRefreshRoute.includes(route.slice(route.lastIndexOf('/')))
                  ? `${text}（${value}）`
                  : text}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Content = (props) => {
  const { children } = props;
  return <div className={styles.content}>{children}</div>;
};

const LayoutInternal = (props) => {
  const { children, hasLayout } = props;
  const [activeMenu, setActiveMenu] = useState({});

  const [count, setCount] = useState({
    focusCount: 0,
    collectionCount: 0,
    orderCount: 0,
    courseCount: 0,
  });

  /** 获取关注、收藏、订单、选课数量 */
  const getCount = () => {
    Promise.allSettled([
      getFocusCount(),
      getCollectionCount(),
      getOrderCount(),
      getQuestionStoreTotal(),
    ]).then(([resp1, resp2, resp3, resp4]) => {
      const { value: value1 } = resp1;
      const { value: value2 } = resp2;
      const { value: value3 } = resp3;
      const { value: value4 } = resp4;
      if (value1 || value2 || value3 || value4) {
        const { data: data1 } = value1 || {};
        const { data: data2 } = value2 || {};
        const { data: data3 } = value3 || {};
        const { data: questionStoreCount = 0 } = value4 || {};
        const { totalCount: focusCount = 0 } = data1 || {};
        const { totalCount: collectionCount = 0 } = data2 || {};
        const { totalCount: orderCount = 0 } = data3 || {};
        setCount({
          focusCount,
          collectionCount: collectionCount + questionStoreCount,
          orderCount,
          courseCount: 0,
        });
      } else {
        setCount({
          focusCount: 0,
          collectionCount: 0,
          orderCount: 0,
          courseCount: 0,
        });
      }
    });
  };

  useEffect(() => {
    const EE = getEmitter();
    const getCountEvent = () => {
      Promise.allSettled([getCollectionCount(), getQuestionStoreTotal()]).then(([resp1, resp2]) => {
        const { value: value1 } = resp1;
        const { value: value2 } = resp2;
        if (value1 && value2) {
          setCount({
            ...count,
            collectionCount: (value1?.data?.totalCount ?? 0) + (value2.data ?? 0),
          });
        }
      });
    };
    EE.on(Events.MODIFY_COLLECTION, getCountEvent);
    return () => {
      EE.off(Events.MODIFY_COLLECTION);
    };
  }, []);

  const menus = useMemo(
    () => [
      {
        text: '首页',
        route: '/person/my-center',
        icon: <MyIcon type="icon-guanlihoutai" />,
      },
      {
        text: '我的关注',
        value: count.focusCount,
        route: '/person/my-focus',
        icon: <MyIcon type="icon-guanzhu" />,
      },
      {
        text: '我的收藏',
        value: count.collectionCount,
        route: '/person/my-collection',
        icon: <MyIcon type="icon-shoucang1" />,
      },
      {
        text: '我的订单',
        value: count.orderCount,
        route: '/person/my-order',
        icon: <MyIcon type="icon-dingdan" />,
      },
      // {
      //   text: '选课单',
      //   value: count.courseCount,
      //   route: '',
      //   icon: <img src={CourseSelection} />,
      // },
      { text: '我的班级', route: '/person/my-class', icon: <MyIcon type="icon-banji1" /> },
      { text: '我的考试', route: '/person/my-exam', icon: <MyIcon type="icon-kaoshiguanli" /> },
      { text: '我的培训', route: '/person/my-training', icon: <MyIcon type="icon-peixun1" /> },
      { text: '我的报名', route: '/person/my-register', icon: <MyIcon type="icon-baoming" /> },
      { text: '我的练习', route: '/person/my-practice', icon: <MyIcon type="icon-wodelianxi1" /> },
      { text: '错题集', route: '/person/my-error', icon: <MyIcon type="icon-cuotiji" /> },
      {
        text: '我的直播',
        route: '/person/my-live',
        icon: <MyIcon type="icon-peixunzhiboguanli" />,
      },
      { text: '我的提问', route: '/person/my-qa', icon: <MyIcon type="icon-tiwen" /> },
      { text: '我的问卷', route: '/person/my-survey', icon: <MyIcon type="icon-wodewenjuan" /> },
      {
        text: '我的证书',
        route: '/person/my-certificate',
        icon: <MyIcon type="icon-wodezhengshu" />,
      },
      { text: '我的资源', route: '/person/my-resource', icon: <MyIcon type="icon-peixun1" /> },
    ],
    [count],
  );

  useEffect(() => {
    history.listen((route) => {
      const { pathname } = route;
      const d = menus.find((i) => i.route && String(pathname).includes(i.route));
      if (d) {
        setActiveMenu(d);
      }
    });
    getCount();
  }, []);

  const routePush = (menu) => {
    const { route } = menu;
    if (route) {
      // 切换我的关注、收藏、订单和选课时刷新数量 fix移动端操作后没刷新页面导致数量和列表对不上
      if (needRefreshRoute.includes(route.slice(route.lastIndexOf('/')))) getCount();
      // setActiveMenu(menu);
      document.querySelector('.portal-menu-layout-content').scrollTo(0, 0);

      history.push(menu.route);
    } else {
      message.info('暂未上线 敬请期待');
    }
  };

  const value = useMemo(() => ({ activeMenu, routePush, refreshCount: getCount }), [activeMenu]);

  const size = useSize(document.querySelector('.portal-menu-layout-content'));

  const offsetTop = useMemo(() => (size?.height < 956 ? -(956 - Number(size.height)) : 24), [
    size?.height,
  ]);

  return (
    <LayoutContext.Provider value={value}>
      <div className={cn(styles.layout, hasLayout ? styles.hasLayout : styles.noLayout)}>
        {hasLayout && (
          <Affix
            offsetTop={offsetTop}
            target={() => document.querySelector('.portal-menu-layout-content')}
          >
            <Sider menus={menus} />
          </Affix>
        )}
        <Content>{children}</Content>
      </div>
    </LayoutContext.Provider>
  );
};

const pureRules = [
  (pathname, query) => {
    if (pathname.includes('/person/my-order/confirm')) {
      if (query.from === 'class' || query.from === 'exam') {
        // 班级 | 考试
        return true;
      }
    }
    return false;
  },
];

/**
 * 过滤下不需要 Sider 的 Route
 */
const Layout = React.memo((props) => {
  const [mounted, setMounted] = useState(false);
  const [hasLayout, setHasLayout] = useState(false);
  const query = useQuery();

  useEffect(() => {
    const callback = () => {
      const { pathname } = window.location;
      const d = pureRules.some((rule) => {
        if (typeof rule === 'string') {
          return String(pathname).includes(rule);
        }
        if (rule instanceof Function) {
          return rule(pathname, query);
        }
        return false;
      });
      setHasLayout(!d);
      if (!mounted) {
        setMounted(true);
      }
    };
    callback();
    history.listen(callback);
  }, []);

  if (!mounted) {
    return null;
  }
  return <LayoutInternal {...props} hasLayout={hasLayout} />;
});

export default Layout;
