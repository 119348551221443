// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from 'F:/longrise/ycih/yn-portal/portal-web-fe/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/index.tsx').default,
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": require('@/pages/404.tsx').default
      },
      {
        "path": "/base-tpl/modify-password",
        "exact": true,
        "component": require('@/pages/base-tpl/modify-password/index.tsx').default
      },
      {
        "path": "/dd-login/bind-info/BindInfo",
        "exact": true,
        "component": require('@/pages/dd-login/bind-info/BindInfo.tsx').default
      },
      {
        "path": "/dd-login/bind-info",
        "exact": true,
        "component": require('@/pages/dd-login/bind-info/index.tsx').default
      },
      {
        "path": "/dd-login",
        "exact": true,
        "component": require('@/pages/dd-login/index.tsx').default
      },
      {
        "path": "/exception/403",
        "exact": true,
        "component": require('@/pages/exception/403/index.tsx').default
      },
      {
        "path": "/exception/404",
        "exact": true,
        "component": require('@/pages/exception/404/index.tsx').default
      },
      {
        "path": "/exception/500",
        "exact": true,
        "component": require('@/pages/exception/500/index.tsx').default
      },
      {
        "path": "/home/HomePage",
        "exact": true,
        "component": require('@/pages/home/HomePage.tsx').default
      },
      {
        "path": "/home",
        "exact": true,
        "component": require('@/pages/home/index.tsx').default
      },
      {
        "path": "/",
        "exact": true,
        "component": require('@/pages/index.tsx').default
      },
      {
        "path": "/my-message",
        "exact": true,
        "component": require('@/pages/my-message/index.tsx').default
      },
      {
        "path": "/my-message/MyMessage",
        "exact": true,
        "component": require('@/pages/my-message/MyMessage.tsx').default
      },
      {
        "path": "/search/constants",
        "exact": true,
        "component": require('@/pages/search/constants.tsx').default
      },
      {
        "path": "/search",
        "exact": true,
        "component": require('@/pages/search/index.tsx').default
      },
      {
        "path": "/search/SearchPage",
        "exact": true,
        "component": require('@/pages/search/SearchPage.tsx').default
      },
      {
        "path": "/study-calendar",
        "exact": true,
        "component": require('@/pages/study-calendar/index.tsx').default
      },
      {
        "path": "/study-calendar/StudyCalendar",
        "exact": true,
        "component": require('@/pages/study-calendar/StudyCalendar.tsx').default
      },
      {
        "path": "/utc/base-tpl/agreement",
        "exact": true,
        "component": require('@/pages/utc/base-tpl/agreement/index.tsx').default
      },
      {
        "path": "/utc/base-tpl/forget-psw",
        "exact": true,
        "component": require('@/pages/utc/base-tpl/forget-psw/index.tsx').default
      },
      {
        "path": "/utc/base-tpl/user-center/account-info",
        "exact": true,
        "component": require('@/pages/utc/base-tpl/user-center/account-info/index.tsx').default
      },
      {
        "path": "/utc/base-tpl/user-login-pc/bind-info",
        "exact": true,
        "component": require('@/pages/utc/base-tpl/user-login-pc/bind-info/index.tsx').default
      },
      {
        "path": "/utc/base-tpl/user-login-pc",
        "exact": true,
        "component": require('@/pages/utc/base-tpl/user-login-pc/index.tsx').default
      },
      {
        "path": "/utc/base-tpl/yn-register",
        "exact": true,
        "component": require('@/pages/utc/base-tpl/yn-register/index.tsx').default
      },
      {
        "path": "/person",
        "routes": [
          {
            "path": "/person",
            "exact": true,
            "component": require('@/pages/person/index.tsx').default
          },
          {
            "path": "/person/my-center",
            "exact": true,
            "component": require('@/pages/person/my-center/index.tsx').default
          },
          {
            "path": "/person/my-center/MyCenterPage",
            "exact": true,
            "component": require('@/pages/person/my-center/MyCenterPage.tsx').default
          },
          {
            "path": "/person/my-certificate/detail/DetailPage",
            "exact": true,
            "component": require('@/pages/person/my-certificate/detail/DetailPage.tsx').default
          },
          {
            "path": "/person/my-certificate/detail",
            "exact": true,
            "component": require('@/pages/person/my-certificate/detail/index.tsx').default
          },
          {
            "path": "/person/my-certificate",
            "exact": true,
            "component": require('@/pages/person/my-certificate/index.tsx').default
          },
          {
            "path": "/person/my-certificate/MyCertificatePage",
            "exact": true,
            "component": require('@/pages/person/my-certificate/MyCertificatePage.tsx').default
          },
          {
            "path": "/person/my-class",
            "exact": true,
            "component": require('@/pages/person/my-class/index.tsx').default
          },
          {
            "path": "/person/my-class/MyClassPage",
            "exact": true,
            "component": require('@/pages/person/my-class/MyClassPage.tsx').default
          },
          {
            "path": "/person/my-collection",
            "exact": true,
            "component": require('@/pages/person/my-collection/index.tsx').default
          },
          {
            "path": "/person/my-collection/MyCollectionPage",
            "exact": true,
            "component": require('@/pages/person/my-collection/MyCollectionPage.tsx').default
          },
          {
            "path": "/person/my-credit",
            "exact": true,
            "component": require('@/pages/person/my-credit/index.tsx').default
          },
          {
            "path": "/person/my-credit/MyCreditPage",
            "exact": true,
            "component": require('@/pages/person/my-credit/MyCreditPage.tsx').default
          },
          {
            "path": "/person/my-error",
            "exact": true,
            "component": require('@/pages/person/my-error/index.tsx').default
          },
          {
            "path": "/person/my-error/MyErrorPage",
            "exact": true,
            "component": require('@/pages/person/my-error/MyErrorPage.tsx').default
          },
          {
            "path": "/person/my-exam",
            "exact": true,
            "component": require('@/pages/person/my-exam/index.tsx').default
          },
          {
            "path": "/person/my-exam/MyExamPage",
            "exact": true,
            "component": require('@/pages/person/my-exam/MyExamPage.tsx').default
          },
          {
            "path": "/person/my-focus",
            "exact": true,
            "component": require('@/pages/person/my-focus/index.tsx').default
          },
          {
            "path": "/person/my-focus/MyFocusPage",
            "exact": true,
            "component": require('@/pages/person/my-focus/MyFocusPage.jsx').default
          },
          {
            "path": "/person/my-live",
            "exact": true,
            "component": require('@/pages/person/my-live/index.tsx').default
          },
          {
            "path": "/person/my-live/MyLivePage",
            "exact": true,
            "component": require('@/pages/person/my-live/MyLivePage.tsx').default
          },
          {
            "path": "/person/my-order/confirm/ConfirmPage",
            "exact": true,
            "component": require('@/pages/person/my-order/confirm/ConfirmPage.jsx').default
          },
          {
            "path": "/person/my-order/confirm",
            "exact": true,
            "component": require('@/pages/person/my-order/confirm/index.tsx').default
          },
          {
            "path": "/person/my-order",
            "exact": true,
            "component": require('@/pages/person/my-order/index.tsx').default
          },
          {
            "path": "/person/my-order/invoice",
            "exact": true,
            "component": require('@/pages/person/my-order/invoice/index.tsx').default
          },
          {
            "path": "/person/my-order/invoice/InvoicePage",
            "exact": true,
            "component": require('@/pages/person/my-order/invoice/InvoicePage.tsx').default
          },
          {
            "path": "/person/my-order/MyOrderPage",
            "exact": true,
            "component": require('@/pages/person/my-order/MyOrderPage.tsx').default
          },
          {
            "path": "/person/my-order/utils",
            "exact": true,
            "component": require('@/pages/person/my-order/utils.tsx').default
          },
          {
            "path": "/person/my-order/:deatail/DeatailPage",
            "exact": true,
            "component": require('@/pages/person/my-order/[deatail]/DeatailPage.tsx').default
          },
          {
            "path": "/person/my-order/:deatail",
            "exact": true,
            "component": require('@/pages/person/my-order/[deatail]/index.tsx').default
          },
          {
            "path": "/person/my-practice",
            "exact": true,
            "component": require('@/pages/person/my-practice/index.tsx').default
          },
          {
            "path": "/person/my-practice/MyPracticePage",
            "exact": true,
            "component": require('@/pages/person/my-practice/MyPracticePage.tsx').default
          },
          {
            "path": "/person/my-qa",
            "exact": true,
            "component": require('@/pages/person/my-qa/index.tsx').default
          },
          {
            "path": "/person/my-qa/MyQAPage",
            "exact": true,
            "component": require('@/pages/person/my-qa/MyQAPage.jsx').default
          },
          {
            "path": "/person/my-register",
            "exact": true,
            "component": require('@/pages/person/my-register/index.tsx').default
          },
          {
            "path": "/person/my-register/MyRegisterPage",
            "exact": true,
            "component": require('@/pages/person/my-register/MyRegisterPage.tsx').default
          },
          {
            "path": "/person/my-resource",
            "exact": true,
            "component": require('@/pages/person/my-resource/index.tsx').default
          },
          {
            "path": "/person/my-resource/MyResourcePage",
            "exact": true,
            "component": require('@/pages/person/my-resource/MyResourcePage.tsx').default
          },
          {
            "path": "/person/my-survey",
            "exact": true,
            "component": require('@/pages/person/my-survey/index.tsx').default
          },
          {
            "path": "/person/my-survey/MySurveyPage",
            "exact": true,
            "component": require('@/pages/person/my-survey/MySurveyPage.jsx').default
          },
          {
            "path": "/person/my-training",
            "exact": true,
            "component": require('@/pages/person/my-training/index.tsx').default
          },
          {
            "path": "/person/my-training/MyTrainingPage",
            "exact": true,
            "component": require('@/pages/person/my-training/MyTrainingPage.tsx').default
          },
          {
            "path": "/person/PersonPage",
            "exact": true,
            "component": require('@/pages/person/PersonPage.tsx').default
          }
        ],
        "component": require('@/pages/person/_layout.jsx').default
      },
      {
        "component": require('@/pages/404.tsx').default
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
