import React from 'react';
import Tabs, { TabPaneProps } from '@/components/Tabs';
import LayoutTitle from '../components/LayoutTitle';
import ResourceList from './components/ResourceList';
import styles from './MyResourcePage.less';

const MyResourcePage = () => {
  const tabPanes: Array<TabPaneProps> = [
    {
      key: '0',
      children: <ResourceList status="0" />,
      tab: '课程资源',
    },
    {
      key: '1',
      children: <ResourceList status="1" />,
      tab: '会员',
    },

    /* {
      key: '2',
      children: <ResourceList status="2" />,
      tab: '已结束',
    }, */
  ];

  return (
    <div className={styles.root}>
      <Tabs
        destroyInactiveTabPane
        extra={<LayoutTitle />}
        tabPanes={tabPanes}
        defaultActiveKey="0"
      />
    </div>
  );
};
export default MyResourcePage;
