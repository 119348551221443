import React, { useEffect, useState } from 'react';
import { history } from 'umi';
import studyImg from '@/assets/images/study.png';
import clockImg from '@/assets/images/clock.png';
import courseDefault from '@/assets/images/courseDefault.png';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Badge, Modal, Spin } from 'antd';
import useLoading from '@/hooks/useLoading';
import { formatDate } from '@/utils';
import Empty from '@/components/Empty';
import { getListApi } from './services';
import { now, initWeeks, DateType } from './constants';
import styles from './StudyCalendar.less';

const courseTypeMap = {
  '1': '培训',
  '2': '公开课',
  '3': '考试',
};
export const examTypeMap = {
  '1': '理论考试',
  '2': '实操考试',
  '3': '论文评价',
};

const CourseCard = ({ data }) => {
  const { type } = data;
  const isExam = type === 2;
  const name = isExam ? data.examName : data.courseName;
  const address = isExam
    ? data.siteName
      ? data.siteName + data.seatNumber
      : '—'
    : data.teachType === 1
      ? '线上课'
      : data.teachAddressList;
  const startTime = isExam ? data.examStartTime : data.gmtStart;
  const endTime = isExam ? data.examEndTime : data.gmtEnd;
  const tagType = isExam ? examTypeMap[data.examType] : courseTypeMap[data.courseType];

  const handleToDetail = () => {
    if (isExam) {
      if (data?.isMicrocomputerSideExam === 1) {
        Modal.warning({
          title: '提示',
          content: '该考试为微机考试，本设备无权限，请使用指定设备',
        });
        return;
      }
      const params = {
        planId: data.planId,
        type: data.examType,
        name: data.planName,
        model: data.model,
        questionnaireId: data.questionnaireId,
        startTime: data.examStartTime,
        endTime: data.examEndTime,
        examTime: data.examTime,
        isAheadSchedule: data.isAheadSchedule,
        aheadMinute: data.aheadMinute,
      };
      history.push({
        pathname: '/exam/user-exam/my-exam/enter-exam?from=main',
        state: params,
      });
      // window.history.pushState(params, '', '/exam/user-exam/my-exam/enter-exam');
    } else {
      history.push(`/pxgl/course?courseId=${data.courseId}`);
    }
  };

  const dom = (
    <div className={styles.courseCardRoot} onClick={handleToDetail}>
      <div className={styles.time}>
        <div className={styles.left}>
          <img src={clockImg} alt="" />
          <span className={styles.timeText}>
            {`${formatDate(startTime)}~${formatDate(endTime)}`}
          </span>
        </div>
        <div className={styles.right}>{tagType}</div>
      </div>
      <div className={styles.logo}>
        {isExam ? (
          <div className={styles.examLogo}>{examTypeMap[data.examType]}</div>
        ) : (
          <img src={data.fileId ? `/train/file/show/${data.fileId}` : courseDefault} alt="" />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.courseName} title={data.courseName}>
          {name || '—'}
        </div>
        <div className={styles.className} title={data.className}>
          {isExam ? '' : data.className || '—'}
        </div>
        <div className={styles.address} title={address}>
          <EnvironmentOutlined style={{ marginRight: 6 }} />
          <span>{address}</span>
        </div>
      </div>
    </div>
  );
  return data?.isMicrocomputerSideExam === 1 ? (
    <Badge.Ribbon text="微机考试" color="#ff9919" style={{ right: 20, top: 46 }}>
      {dom}
    </Badge.Ribbon>
  ) : (
    dom
  );
};

const StudyCalendar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [dates, setDates] = useState<Array<DateType>>([]);
  const [list, setList] = useState([]);
  const [loading, wrapGetListApi] = useLoading(getListApi);

  const getList = (timeStamp) => {
    wrapGetListApi({ timeStamp }).then((res: unknown) => {
      if (res.success) {
        const data = res.data || [];
        setList(data);
      }
    });
  };

  const handleClickDate = (index) => {
    getList(dates[index].timeStamp);
    setActiveIndex(index);
  };

  useEffect(() => {
    initWeeks(setDates, (map: Array<DateType>) => {
      getList(map[0].timeStamp);
    });
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.banner}>
        <img src={studyImg} alt="" />
      </div>
      <div className={styles.container}>
        <div className={styles.dateBox}>
          <div className={styles.yearMonth}>
            {now.getFullYear()}
            年
            {now.getMonth() + 1}
            月
          </div>
          <div className={styles.weeks}>
            {dates.map((dateItem, index) => (
              <div
                className={styles.weekItem}
                key={dateItem.timeStamp}
                onClick={() => {
                  handleClickDate(index);
                }}
              >
                {dateItem.week}
              </div>
            ))}
          </div>
          <div className={styles.dates}>
            {dates.map((dateItem, index) => (
              <div
                className={`${styles.dateItem} ${activeIndex === index && styles.active}`}
                onClick={() => {
                  handleClickDate(index);
                }}
                key={dateItem.timeStamp}
              >
                {dateItem.date}
              </div>
            ))}
          </div>
        </div>
        <Spin spinning={loading}>
          <div className={styles.listBox}>
            {list.length ? (
              list.map((item: unknown) => <CourseCard data={item} key={item.courseId} />)
            ) : (
              <Empty style={{ margin: '50px auto' }} />
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default StudyCalendar;
