import React, { useState } from 'react';
import Tabs, { TabPaneProps } from '@/components/Tabs';
import LayoutTitle from '../components/LayoutTitle';
import OrderList from './components/OrderList';
import styles from './MyOrderPage.less';

const MyOrderPage = () => {
  const [currentTab, setCurrentTab] = useState('0');

  const tabPanes: Array<TabPaneProps> = [
    {
      key: '0',
      tab: '所有订单',
    },
    {
      key: '1',
      tab: '待付款',
    },
    {
      key: '2',
      tab: '已付款',
    },
  ];

  return (
    <>
      <Tabs
        className={styles.tabs}
        extra={<LayoutTitle />}
        tabPanes={tabPanes}
        defaultActiveKey="0"
        onChange={setCurrentTab}
      />
      <OrderList orderStatus={currentTab !== '0' ? currentTab : undefined} />
    </>
  );
};

export default MyOrderPage;
