import { useState, useCallback } from 'react';

/**
 * @description 自动添加请求时的loading状态
 */

const useLoading = (
  req: (params?: any) => Promise<any>,
): [boolean, (params?: any) => Promise<any>] => {
  const [loading, setLoading] = useState(false);

  const wrapRequest = useCallback(
    (...args) => {
      setLoading(true);
      return req(...args)
        .then(data => Promise.resolve(data))
        .catch(err => Promise.reject(err))
        .finally(() => {
          setLoading(false);
        });
    },
    [req],
  );
  return [loading, wrapRequest];
};

export default useLoading;
