import React from 'react';
import { history } from 'umi';
import { Dropdown, Menu, message, Avatar as AntAvatar, Button, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { logout } from '@/services/common';
import { UserInfoProps } from '@/models/common';
import defaultAvatarImg from '@/assets/images/avatar.png';
import { isDingtalk } from '@/utils/dingUtils';
import { prefixUUCImage } from '@/utils';
import styles from './Avatar.less';

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  userInfo: UserInfoProps;
}

const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const { className = '', userInfo, ...otherProps } = props;
  const { userName, avatar } = userInfo || {};

  // 退出
  function onLogout() {
    logout().then(({ errCode, data }) => {
      if (
        String(errCode) === '0' &&
        (data?.indexOf('http://') > -1 || data?.indexOf('https://') > -1)
      ) {
        message.success('退出成功');
        // 退出登录回到首页
        document.cookie = `history_path=/home;path=/`;
        window.location.href = data;
      } else {
        message.error('退出失败');
      }
    });
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push('/utc/base-tpl/user-center/account-info');
        }}
        key="1"
      >
        个人中心维护
      </Menu.Item>
      {!isDingtalk && (
        <Menu.Item key="2" onClick={onLogout}>
          退出登录
        </Menu.Item>
      )}
    </Menu>
  );

  return userName ? (
    <Dropdown overlay={menu}>
      <div className={`${styles.root} ${className}`} {...otherProps}>
        <AntAvatar src={prefixUUCImage(avatar, defaultAvatarImg)} />
        <span className={styles.text}>{userName}</span>
        <CaretDownOutlined style={{ fontSize: '16px', color: 'rgba(0,10,32,0.65)' }} />
      </div>
    </Dropdown>
  ) : (
    <Space size={14}>
      <Button
        type="primary"
        size="small"
        shape="round"
        onClick={() => history.push('/utc/base-tpl/user-login-pc')}
      >
        登录
      </Button>
      <Button shape="round" size="small" onClick={() => history.push('/utc/base-tpl/yn-register')}>
        注册
      </Button>
    </Space>
  );
};

export default Avatar;
