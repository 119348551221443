/**
 基于 antd Icon 进一步封装
 */

import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import './MyIcon.less';
import '@/assets/font/iconfont.css';

const AliIcon = createFromIconfontCN({
  scriptUrl: ['//at.alicdn.com/t/font_3193845_75b71mlyg1h.js'],
});

interface IMyIcon {
  type: string;
  color?: string;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (params?: unknown) => void;
}

const MyIcon: React.FC<IMyIcon> = (props) => {
  const { type, color, size, className, style, onClick, ...otherProps } = props;
  const _style = {
    color,
    fontSize: `${size}px`,
    ...style,
  };
  // return <i className={`iconfont ${type} ${className}`} style={_style} onClick={onClick}></i>;
  return (
    <AliIcon style={_style} className={className} type={type} onClick={onClick} {...otherProps} />
  );
};

MyIcon.defaultProps = {
  size: 14,
  className: '',
};

export default MyIcon;
