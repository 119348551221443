import { getFileExtension } from '@/utils';
import { message, Modal, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState, useRef } from 'react';
import { delImgApi, downloadByIdApi } from '../../services';
import styles from './UploadImg.less';

const UploadImg = (props) => {
  const {
    maxSize = 20,
    maxCount = 5,
    value,
    action = '/zs-api/certificate/instance/uploadPic',
    data,
  } = props;
  const timer = useRef(-1);
  const [files, setFileList] = useState<unknown>([]);
  const [previewVisible, setVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const beforeUpload = (file) => {
    const type = ['jpeg', 'png', 'jpg', 'gif', 'bmp'];
    if (!type.includes(getFileExtension(file.name).toLowerCase())) {
      message.error('请上传jpg、jpeg、png、gif、bmp格式的图片！');
      return false;
    }
    if (file.size > maxSize * 1024 * 1024) {
      message.error(`文件大小不能超过${maxSize}MB！`);
      return false;
    }
    return true;
  };
  const uploadProps = {
    name: 'file',
    data: {
      instanceId: data.id,
      categoryId: data.categoryId,
    },
    action: `${window['apiPrefix'] ?? ''}${action}`,
    maxCount,
    multiple: true,
    accept: '.jpeg,.png,.jpg,.gif,.bmp',
    beforeUpload,
    onChange(info) {
      const { fileList, file } = info;
      const { status } = file;
      if (status) {
        if (status === 'done') {
          const { success } = file.response;
          if (!success) {
            const arr = fileList.filter((f) => f.uid !== file.uid);
            setFileList(arr);
            message.error(file.response.message);
          } else {
            setFileList(fileList);
          }
        } else {
          setFileList(fileList);
        }
      }
    },
    showUploadList: {
      showRemoveIcon: true,
      showDownloadIcon: true,
    },
  };
  const handlePreview = (file) => {
    setPreviewImage(file.thumbUrl);
    setVisible(true);
  };

  const handleDownload = async (file) => {
    const res = (await downloadByIdApi(file.response.data)) ?? {};
    if (res && `${res.code}` === '0') {
      const reader = new FileReader();
      reader.readAsDataURL(res.data);
      reader.onload = (e) => {
        const a = document.createElement('a');
        a.download = res.filename;
        // @ts-expect-error
        a.href = e.target?.result;
        document.body.appendChild(a);
        a.click();
        // @ts-expect-error
        timer.current = setTimeout(() => {
          document.body.removeChild(a);
        }, 1000);
      };
    } else {
      message.error(res?.message ?? '证书图片导出失败');
    }
  };

  const handleRemove = async (file) =>
    new Promise<boolean>((resolve) => {
      Modal.confirm({
        title: '确认删除证书图片？',
        content: '',
        cancelText: '取消',
        okText: '确定',
        onOk: async () => {
          const res = await delImgApi({
            fileId: file.response.data,
            instanceId: data.id,
            categoryId: data.categoryId,
          });
          if (res.success) {
            message.success('删除成功!');
            resolve(true);
          } else {
            message.error(res.message);
          }
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  useEffect(() => {
    if (value) {
      const fileIds = value || [];
      const fileList = fileIds.map((fileId) => ({
        status: 'done',
        thumbUrl: `/zs-api/common/downloadByFileId?fileId=${fileId}`,
        response: {
          data: fileId,
        },
      }));
      setFileList(fileList);
    }
  }, [value]);
  return (
    <div className={styles.root}>
      <ImgCrop aspect={16 / 9} beforeCrop={beforeUpload}>
        <Upload
          {...uploadProps}
          listType="picture-card"
          fileList={files}
          onPreview={handlePreview}
          onRemove={handleRemove}
          onDownload={handleDownload}
        >
          {files.length < maxCount && (
            <div className={styles.add}>
              <span>+</span>
              <div>选择图片</div>
            </div>
          )}
        </Upload>
      </ImgCrop>
      <Modal
        visible={previewVisible}
        title="预览图片"
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};
export default UploadImg;
