const regExp = (type) => {
  switch (type) {
    case 'userCode':
      return {
        pattern: /^[0-9a-zA-Z\-_]{4,30}$/,
        message: '请正确输入账号',
      };
    case 'certCode':
      return {
        pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        message: '请输入正确的身份证号',
      };
    case 'telephone':
      return {
        pattern: /^(0[0-9]{2,3}-)+([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/,
        message: '请正确输入座机号码,号码格式: 区号-电话号-分机号',
      };
    case 'phone':
      return {
        pattern: /^1[3456789]\d{9}$/,
        message: '请输入有效手机号',
      };
    case 'email':
      return {
        pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        message: '请输入有效邮箱',
      };
    case 'chinese':
      return {
        pattern: /^[\u4e00-\u9fa5]+$/,
        message: '请输入汉字',
      };
    case 'number':
      return {
        pattern: /^[0-9]*$/,
        message: '请输入大于或等于0的整数',
      };
    case 'url':
      return {
        pattern: /(http|https):\/\/([\w.]+\/?)\S*/,
        message: '请输入正确的网址名称',
      };
    default:
      return {};
  }
};

export default regExp;
