import React from 'react';
import { history } from 'umi';
import { Button } from 'antd';
import styles from './QuestCard.less';
import questAvatar from '@/assets/images/questAvatar.png';

const qStatusMap = {
  1: {
    btnLabel: '查看',
    disabled: false,
  },
  2: {
    btnLabel: '开始填写',
    disabled: false,
  },
  3: {
    btnLabel: '暂停',
    disabled: true,
  },
  4: {
    btnLabel: '结束',
    disabled: true,
  },
};

const QuestCard = (props) => {
  const { questionnaireTitle, labelName, createDate, fillingStatus, pcUrl } = props;
  const status = qStatusMap[fillingStatus] || {};
  const btnLabel = status.btnLabel;
  const disabled = status.disabled;

  const handleClick = ()=>{
    history.push(pcUrl)
  }

  return (
    <div className={`${styles.root} `}>
      <div className={styles.avatar}>
        <img src={questAvatar} alt="" />
      </div>
      <div className={styles.info}>
        <div className={styles.title} title={questionnaireTitle}>
          {questionnaireTitle || '-'}
        </div>
        <div className={styles.desc}>分类: {labelName || '-'}</div>
        <div className={styles.desc}>创建时间: {createDate || '-'}</div>
      </div>
      <Button className={styles.btn} disabled={disabled} onClick={handleClick}>
        {btnLabel}
      </Button>
    </div>
  );
};

export default QuestCard;
