import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import OrderTitle from '../../../components/OrderTitle';

import styles from './ConfirmQrCode.less';

const ConfirmQRCode = ({ payUrl, afterPayMount }) => {
  const [spinning, setSpin] = useState(false);
  useEffect(() => {
    afterPayMount();
    setSpin(true);
    const h = setTimeout(() => {
      setSpin(false);
    }, 500);
    return () => clearTimeout(h);
  }, []);

  const decodeUrl = decodeURIComponent(payUrl);
  window.console.log('decodeUrl', decodeUrl);

  return (
    <Spin spinning={spinning}>
      <div className={styles.qrCode}>
        <OrderTitle.SubTitle>扫码支付</OrderTitle.SubTitle>

        <div className={styles.body}>
          <div className={styles.content}>
            <QRCodeSVG value={decodeUrl} size={170} />
            <span className={styles.tips}>请使用手机银行龙支付/支付宝/微信扫码支付</span>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ConfirmQRCode;
