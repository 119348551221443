import { get, post } from '@/utils/fetch';

/**
 * 全部搜索接口
 * 	"searchKeyword": "测试关键字"  //全部页面的搜索只需要关键词即可
 */
export const allSerach = (searchKeyword = '') =>
  post('/search-center/index/query', { searchKeyword });

/**
 * @desc 按照类型搜索
 * @params
 * "pageNum": 1,
	"pageSize": 10,
	"searchKeyword": "关键字",
	"searchType": 0  //搜索类型选数据类型中的数字
 */
export const typeSearch = (params) => post(`/search-center/index/specific`, params);

/**
 * 按照类型统计
 */
export const querySerachCount = (searchKeyword = '') =>
  get(`/search-center/index/count`, { searchKeyword }).then((res) => res?.data?.countMap || {});
