import React from 'react';
import classnames from 'classnames';
import { useControllableValue } from 'ahooks';
// import AliyPayIcon from '@/assets/images/alipay.svg';
// import ConstructionBank from '@/assets/images/construction-bank.svg';
// import WechatIcon from '@/assets/images/wechat.svg';
import qrCode from '@/assets/images/qrCode.svg';
import OrderTitle from '../OrderTitle';
import { ChannelConst } from '../../utils';

import styles from './OrderChannel.less';

const conf = [
  { text: '个人线上支付', key: ChannelConst.ON_LINE, icon: qrCode },
  { text: '公司线下代支付', key: ChannelConst.OFF_LINE, icon: null },
];

const OrderChannel = (props) => {
  const { disabled, channels } = props;
  const [value, onChange] = useControllableValue(props, {
    defaultValue: conf[0].key,
  });

  return (
    <div className={styles.root}>
      <OrderTitle.SubTitle>确认支付渠道</OrderTitle.SubTitle>
      <div className={styles.channel}>
        {conf
          .filter((i) => (channels ? channels[i.key] : true))
          .map((d) => {
            const { text, key, icon } = d;
            const isSelected = key === value;
            const isText = !icon;
            return (
              <div
                className={classnames(styles.item, {
                  [styles.active]: isSelected,
                  [styles.offline]: isText,
                })}
                onClick={() => {
                  if (!disabled) {
                    onChange(key);
                  }
                }}
                key={text}
              >
                {icon && <img className={styles.img} src={icon} />}
                <span className={styles.label}>{text}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default OrderChannel;
