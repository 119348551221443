/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Normal from './theme-normal';
import National from './theme-national';
import { ThemeType } from './types';

const Themes = {
  Normal: 1, // 正常
  National: 2, // 国庆
};

function createTheme(theme): ThemeType {
  switch (theme) {
    case Themes.National:
      return National;
    case Themes.Normal:
    default:
      return Normal;
  }
}

// 控制开关
const theme = Themes.Normal;
const config: ThemeType = createTheme(theme);

export default config;
