import { post, get } from '@/utils/fetch';

export const getListApi = (data) => post('/trainees/mobile/zk-resource-collect/collectList', data);
// 个人信息查询
export const getAccountInfo = () => get('/uuc/user/management/userCenter/myUserInfo');
// 我的培训
export const getTrainingListApi = (data) => post('/train/mobile/class/page-self', data);
// 获取消息详情，读消息详情
export const readMessage = ({ id, readStatus }) =>
  get('/trainees/msg/detail', { id, readStatus });
// 通知消息
export const getMessageListApi = (data) => post('/trainees/msg/page', data);
// 我的收藏
export const getCollectionListApi = (data) => post('/trainees/mobile/zk-resource-collect/collectList', data);

// 我所有的课程
export const getAllClassApi = () => post('/train/mobile/class/getAllClass');
// 我的考试
export const getMyExamApi = () => post('/ks-api/user/plan/current', { type: 1, pageSize: 1 });

export const fetchCertificateApi = () => get('/zs-api/certificate/instance/my');
export const getMySurveyListApi = data => post('/wj-api/tasks/my', data);

// 查询购买记录
export const getBuyRecordApi = id => get(`/cart/api/goodsManage/payRecord?id=${id}`);
