import React, { useState } from 'react';
import { Upload, message, Spin, Image } from 'antd';
import { Rule } from 'rc-field-form/lib/interface.d';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { uploadSmallFile } from '@/services/common';
import styles from './PicUploader.less';

export interface PicUploaderProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string | string[] | undefined;
  wrapperCol: unknown;
  key: string;
  rules: Rule[];
  imgSrc: (key: string) => string;
  onStyle: boolean;
  fileType: string[];
  accept: string[];
  size: number;
  maxFileCount: number;
  listType: string;
  tip: string;
  value: unknown;
  onChange: (fileList: unknown[]) => void;
}

const PicUploader = (props: PicUploaderProps) => {
  const {
    className = '',
    key,
    fileType,
    accept,
    size = 5,
    maxFileCount = 1,
    // listType = 'picture-card',
    children,
    tip,
    value = [],
    onChange,
    ...otherProps
  } = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImgSrc, setPreviewImgSrc] = useState(null);

  const handleFileChange = ({ file, fileList }) => {
    const _fileList = fileList.filter((item) => {
      const { status } = item;
      return status !== 'error' && status !== 'removed';
    });
    onChange(_fileList);
  };

  const beforeUpload = (file) => {
    const typeConfie = fileType.includes(file.type);
    const sizeConfire = file.size <= size * 1024 * 1024;
    if (!typeConfie) {
      message.error('该文件不符合格式要求');
    } else if (!sizeConfire) {
      message.error('该文件尺寸过大');
    }
    return (typeConfie && sizeConfire) || Upload.LIST_IGNORE;
  };

  const handleUpload = ({ file, onSuccess, onError }) => {
    uploadSmallFile(file).then((res) => {
      if (res?.success) {
        onSuccess(res, file);
      } else {
        message.error('上传文件失败');
        onError(res);
      }
    });
  };

  const handlePreview = (src) => {
    setPreviewImgSrc(src);
    setPreviewVisible(true);
  };

  const itemRender = (option, file, fileList, actions) => {
    const { status } = file;
    const { remove } = actions;
    const isLoding = status === 'uploading';
    const id = file?.response?.data?.id || file.id;
    const src = `/train/file/show/${id}`;
    return (
      <Spin spinning={isLoding}>
        <div className={styles.imgBox}>
          <div className={styles.imgOpera}>
            <EyeOutlined
              onClick={() => {
                handlePreview(src);
              }}
            />
            <DeleteOutlined onClick={remove} />
          </div>
          {!isLoding && (
            <img
              className={styles.fileImg}
              key={id}
              width={100}
              height={100}
              alt="图片"
              src={src}
            />
          )}
        </div>
      </Spin>
    );
  };
  // (value?.length ?? 0) < maxFileCount
  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      <Upload
        accept={accept}
        // listType={listType}
        onChange={handleFileChange}
        beforeUpload={beforeUpload}
        customRequest={handleUpload}
        itemRender={itemRender}
        maxCount={maxFileCount}
      >
        {(value?.length ?? 0) < maxFileCount ? children : null}
      </Upload>
      {tip && <span className={styles.uploadTip}>{tip}</span>}
      {previewVisible && (
        <Image
          width={200}
          style={{ display: 'none' }}
          src={previewImgSrc}
          preview={{
            visible: previewVisible,
            src: previewImgSrc,
            onVisibleChange: (value) => {
              setPreviewVisible(value);
            },
          }}
        />
      )}
    </div>
  );
};

export default PicUploader;
