import React from 'react';
import YNlogoImg from '@/assets/images/logo.png';
import BgImg from '@/assets/images/bg1.png';
import styles from './Wrap.less';

const Wrap = ({ children, className = '', ...rest }) => {
  return (
    <div
      className={`${styles.root} ${className}`}
      style={{
        background: `url(${BgImg}) no-repeat center/100%`,
        backgroundSize: 'cover',
      }}
      {...rest}
    >
      <div
        className={styles.header}
        onClick={() => {
          window.open('/home', '_self');
        }}
      >
        <img src={YNlogoImg} className={styles.YNLogo} />
        <div className={styles.title}>全员学习成长平台</div>
      </div>
      {children}
    </div>
  );
};

export default Wrap;
