import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useAntdTable } from 'ahooks';
import { Params as PaginatedParams } from 'ahooks/lib/useAntdTable/types';
import useDeepUpdateEffect from '@/hooks/useDeepUpdateEffect';

interface OriginResult {
  success: boolean;
  data: {
    totalCount: number;
    list: Array<unknown>;
  };
}

interface Result {
  total: number;
  list: Array<unknown>;
}

export interface OptionsProps {
  form: FormInstance;
  params?: Record<string, unknown>; // 查询页面需要的额外参数, 动态变化时重置状态重新发起请求
  useRequestOptions?: Record<string, unknown>; // useRequest的配置项
}

const useTable = (
  request: (params?: unknown) => Promise<OriginResult>,
  { form, params, useRequestOptions }: OptionsProps,
): unknown => {
  // 自动请求表格数据
  const getTableData = async (
    { current, pageSize, sorter = {} }: PaginatedParams[0],
    formData: Record<string, unknown>,
  ): Promise<Result> => {
    if (request) {
      const orderField = sorter.field;
      const order = sorter.order;
      const { data } = await request({
        pageNum: current,
        pageSize,
        orderField: order ? orderField : undefined,
        sort: order ? order === 'ascend' : undefined,
        ...formData,
        ...params,
      });
      const { list, totalCount } = data || {};

      return { total: totalCount ?? 0, list: list || [] };
    }
    return { total: 0, list: [] };
  };

  const { tableProps, search, pagination, refresh } = useAntdTable(getTableData, {
    defaultPageSize: 10,
    form,
    ...useRequestOptions, // 请参考 umiRequest....
  });

  const { changeCurrent } = pagination || {};

  const { reset } = search;

  const del = (deleteCount = 1) => {
    const { totalPage, current } = pagination;
    const { dataSource } = tableProps;
    const shouldChangeCurrent = current === totalPage && dataSource?.length <= deleteCount;
    if (shouldChangeCurrent) {
      changeCurrent(Math.max(1, current - 1));
    } else {
      refresh();
    }
  };

  // 深比较,防止过度渲染
  useDeepUpdateEffect(() => {
    reset();
  }, [params]);

  return [tableProps, search, refresh, changeCurrent, del];
};

export default useTable;
