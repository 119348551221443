import React, { useMemo, useContext } from 'react';
import { Space, Button, Image, Tooltip } from 'antd';
import { history } from 'umi';
import {
  ZGBM_GRJF,
  renderDefaultImg,
  handleDelete,
  handleCancel,
  handleRefund,
  handleCancelRefund,
  getGoodImage,
  handleViewReason,
} from '../../utils';
import LayoutContext from '../../../LayoutContext';
import styles from './OrderCard.less';

// payWay
// 支付方式  1线上 2线下

//orderStatus
// 订单状态 1待付款 2已付款 3退款中 4交易成功 5交易关闭

// statusRemark
// 订单状态备注
// 管理端 【用户端】
// 1.退款失败 【退款中】

// 用户端 【管理端】
// 2.退款成功 【交易关闭】
// 3.拒绝退款 【已付款】
// 4.取消订单 【交易关闭】
// 5.管理员取消 取消订单 【交易关闭】
// 6.订单超时 【交易关闭】

const OrderCard = (props) => {
  const {
    className = '',
    goodsName,
    gmtCreate,
    orderStatus,
    amountOrder,
    amountReceive,
    statusRemark,
    originalChannel,
    orderCode,
    payType,
    refresh = () => {},
    delRefresh = () => {},
  } = props;

  const image = getGoodImage(props);
  const imgLoadErrorHandler = (e: React.SyntheticEvent<HTMLImageElement>) => {
    (e.target as HTMLImageElement).setAttribute('src', renderDefaultImg[originalChannel || 1]);
  };

  const { refreshCount } = useContext(LayoutContext);

  const orderStatusMap = {
    1: {
      status: <span className={styles.primary}>待付款</span>,
      action: (
        <>
          <Button
            type="primary"
            shape="round"
            style={{ width: '90px', marginBottom: 4 }}
            onClick={() => {
              history.push({
                pathname: '/person/my-order/confirm',
                query: { orderCode, from: 'order' },
              });
            }}
          >
            付款
          </Button>
          {/* 主管报名个人缴费的订单不允许学员取消订单 屏蔽取消订单 */}
          {/* {payType !== ZGBM_GRJF && (
            <span className={styles.cancel} onClick={() => handleCancel(orderCode, refresh)}>
              取消订单
            </span>
          )} */}
        </>
      ),
    },
    2: {
      status: <span className={styles.warn}>已付款</span>,
      statusRemark: statusRemark === 3 ? (<>
        <span className={styles.error}>拒绝退款</span>
        <span className={styles.view} onClick={() => handleViewReason(orderCode)}>查看原因</span>
      </>) : null,
      action: (
        <Button shape="round" onClick={() => handleRefund(orderCode, refresh)}>
          申请退款
        </Button>
      ),
    },
    3: {
      status: <span className={styles.default}>退款中</span>,
      action: (
        // <Button shape="round" onClick={() => handleCancelRefund(orderCode, refresh)}>
        //   取消退款
        // </Button>
        <Button shape="round" disabled>
          退款审核中
        </Button>
      ),
    },
    4: {
      status: <span className={styles.default}>交易成功</span>,
      // action: (
      //   <span
      //     className={styles.cancel}
      //     onClick={() => handleDelete(orderCode, delRefresh, refreshCount)}
      //   >
      //     删除订单
      //   </span>
      // ),
      action: null,
    },
    5: {
      status: <span className={styles.default}>交易关闭</span>,
      statusRemark: statusRemark === 2 ? <span className={styles.success}>退款成功</span> : null,
      action: (
        <>
          {/* 再次购买去确认页 */}
          <Button
            shape="round"
            type="primary"
            onClick={() => {
              history.push({
                pathname: '/person/my-order/confirm',
                query: { orderCode, from: 'buy-again' },
              });
            }}
            style={{ marginBottom: 4 }}
          >
            再次购买
          </Button>
          <span
            className={styles.cancel}
            onClick={() => handleDelete(orderCode, delRefresh, refreshCount)}
          >
            删除订单
          </span>
        </>
      ),
    },
  };

  const { status, action, statusRemark: _statusRemark } = useMemo(
    () => orderStatusMap[orderStatus] || {},
    [orderStatus],
  );

  const isHideAmountReceive = statusRemark === 4 || statusRemark === 5 || statusRemark === 6;

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.header}>
        <Space size={20}>
          <span>{gmtCreate || '-'}</span>
          <span>订单号：{orderCode || '-'}</span>
        </Space>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/person/my-order/${orderCode}`)}
        >
          订单详情
        </span>
      </div>
      <div className={styles.content}>
        <div className={styles.col}>
          <Image
            width={50}
            height={36}
            preview={false}
            style={{ objectFit: 'cover', borderRadius: 4 }}
            src={image}
            onError={imgLoadErrorHandler}
          />
          <Tooltip title={goodsName}>
            <div className={styles.title}>{goodsName || '-'}</div>
          </Tooltip>
        </div>
        <div className={styles.col}>
          <span className={styles.desc}>订单金额(元)</span>
          <span className={styles.black}>¥{amountOrder || '-'}</span>
        </div>
        <div className={styles.col}>
          {isHideAmountReceive ? (
            <span className={styles.error}>-</span>
          ) : orderStatus === 1 ? (
            <>
              <span className={styles.desc}>需付款(元)</span>
              <span className={styles.error}>¥{amountOrder || '-'}</span>
            </>
          ) : (
            <>
              <span className={styles.desc}>实付款(元)</span>
              <span className={styles.error}>¥{amountReceive || '-'}</span>
            </>
          )}
        </div>
        <div className={styles.col}>
          {status}
          {_statusRemark}
        </div>
        <div className={styles.col}>{action}</div>
      </div>
    </div>
  );
};

export default OrderCard;
