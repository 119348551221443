import { Button, message, Tooltip, Space } from 'antd';
import React, { useState } from 'react';
import { history } from 'umi';
import exportUtils from '@/utils/exportUtils';
import { get } from '@/utils/fetch';
import { getTypeImg, getFileName, fileSizeFormat } from '@/utils/fileUtils';
import styles from './LibraryCard.less';
import cn from 'classnames';
import { ArrowDownOutlined } from '@ant-design/icons';

export const downloadApi = (id) => {
  return get(
    '/know/document/download/' + id,
    {},
    {
      responseType: 'blob',
    },
  );
};

const LibraryCard = (props) => {
  const [disabledIndex, setDisabledIndex] = useState(null);

  const handleDownload = (e, id, fileName) => {
    e.stopPropagation();
    setDisabledIndex(id);
    message.info('文件下载中...');
    exportUtils(downloadApi(id), fileName, () => {
      message.success('文件下载成功!');
      setDisabledIndex(null);
    });
  };

  const handleToPreview = (id, type) => {
    history.push({
      pathname: '/knowledge/file-detail',
      query: {
        id,
        type,
      },
    });
  };

  const {
    fileId,
    fileName,
    docName,
    fileSize,
    docSize,
    fileSuffix,
    docSuffix,
    gmtModified,
    gmtModify,
    createUserName,
    downloadStatus,
    id,
    zkDocumentId,
    notInSearch,
  } = props;

  const fId = id || zkDocumentId;
  const fSuffix = fileSuffix || docSuffix;
  const fName = fileName || docName;
  const fSize = fileSize || docSize;
  const modifyDate = gmtModified || gmtModify;

  return (
    <div
      className={cn(styles.listItem, { [styles.inline]: notInSearch })}
      key={fileId}
      onClick={() => handleToPreview(fId, fSuffix)}
    >
      {notInSearch ? (
        <>
          <div className={styles.left}>
            <img src={getTypeImg(fSuffix)} className={styles.itemLogo} alt="" />
            <div className={styles.info}>
              <Tooltip title={getFileName(fName, fSuffix)}>
                <div className={styles.fileName}>{getFileName(fName, fSuffix)}</div>
              </Tooltip>
              <Space size={6} className={styles.otherInfo}>
                {fileSizeFormat(fSize)}｜{modifyDate} ｜ {createUserName || '—'}
              </Space>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.fileInfo}>
            <img src={getTypeImg(fSuffix)} className={styles.itemLogo} alt="" />
            <Tooltip title={getFileName(fName, fSuffix)}>
              <div className={styles.fileName}>{getFileName(fName, fSuffix)}</div>
            </Tooltip>
          </div>
          <Space size={20} className={styles.otherInfo}>
            {fileSizeFormat(fSize)}｜{modifyDate} ｜ {createUserName || '—'}
          </Space>
        </>
      )}
      <Button
        type="link"
        disabled={downloadStatus === 1 || disabledIndex === fId}
        className={cn({ [styles.radius]: notInSearch })}
        onClick={(e) => handleDownload(e, fId, getFileName(fName, fSuffix))}
      >
        {notInSearch ? <ArrowDownOutlined /> : '下载'}
      </Button>
    </div>
  );
};

export default LibraryCard;
