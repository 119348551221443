class EventEmitter {
  constructor() {
    this.events = {};
  }

  on(type, callback) {
    let fns = (this.events[type] = this.events[type] || []);
    if (fns.indexOf(callback) === -1) {
      fns.push(callback);
    }
    return this;
  }

  emit(type, data) {
    let fns = this.events[type];
    if (Array.isArray(fns)) {
      fns.forEach((fn) => fn(data));
    }
    return this;
  }

  off(type, callback) {
    let fns = this.events[type];

    if (Array.isArray(fns)) {
      if (callback) {
        let index = fns.indexOf(callback);
        if (index !== -1) {
          fns.splice(index, 1);
        }
      } else {
        fns = [];
      }
    }
    return this;
  }
}

const Events = {
  // 收藏更新数量
  MODIFY_COLLECTION: '33',
};

let ins;
function getEventEmitter() {
  if (!ins) {
    ins = new EventEmitter();
  }
  return ins;
}

export { EventEmitter, Events };

export default getEventEmitter;
