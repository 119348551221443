import React from 'react';
import { renderDefaultImg, getGoodImage } from '../../utils';
import OrderTitle from '../OrderTitle';
import styles from './OrderBanner.less';

const OrderBanner = (props) => {
  const {
    // 订单
    amountOrder,
    amountReceive,
    originalChannel,
    goodsName,
    goodsRemark,
    orderStatus,
    statusRemark,
    rejectReason,
    // 商品
    goodsFeeRemark,
    goodsPrice,
    // 是否是确认页
    isOrderConfirm,
  } = props;

  const image = getGoodImage(props);

  const imgLoadErrorHandler = (e) => {
    e.target.setAttribute('src', renderDefaultImg[originalChannel || 1]);
  };

  const isHideAmountReceive =
    isOrderConfirm || statusRemark === 4 || statusRemark === 5 || statusRemark === 6;

  return (
    <div className={styles.wrapper}>
      <OrderTitle.SubTitle>您购买的是</OrderTitle.SubTitle>
      <div className={styles.meta}>
        <div className={styles.banner}>
          <img className={styles.img} src={image} onError={imgLoadErrorHandler} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{goodsName || '-'}</div>
          {(goodsRemark || goodsFeeRemark) && (
            <div className={styles.extra}>
              {(originalChannel === 6 && '资源商品') || goodsRemark || goodsFeeRemark || '暂无说明'}
            </div>
          )}
          {(amountOrder || goodsPrice) && (
            <div className={styles.extra}>
              <span className={styles.label}>价格：</span>
              <span className={styles.total}>{`¥${amountOrder || goodsPrice || 0}`}</span>
            </div>
          )}
          {isOrderConfirm && (
            <div className={styles.extra}>
              <span className={styles.label}>需付款</span>
              <span className={styles.largeTotal}>{`¥${amountOrder || goodsPrice || 0}`}</span>
            </div>
          )}
          {!isHideAmountReceive && (
            <div className={styles.extra}>
              {orderStatus === 1 ? (
                <>
                  <span className={styles.label}>需付款：</span>
                  <span className={styles.largeTotal}>{`¥${amountOrder || 0}`}</span>
                </>
              ) : (
                <>
                  <span className={styles.label}>实付款：</span>
                  <span className={styles.largeTotal}>{`¥${amountReceive || 0}`}</span>
                </>
              )}
              {statusRemark === 3 ? (
                <>
                  <span className={styles.error}>拒绝退款</span>
                  <div>
                    拒绝原因：
                    {rejectReason}
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderBanner;
