import React from 'react';
import { Spin, Pagination, Space, List } from 'antd';
import Empty from '@/components/Empty';
import useList from '@/hooks/useList';
import { useSelector, history, Link } from 'umi';
import { useRequest } from 'ahooks';
import LayoutTitle from '../components/LayoutTitle';
import { getCreditList, getCreditRule } from './services';
import styles from './MyCreditPage.less';
import CreditDesc from './components/CreditDesc';

const MyCreditPage = () => {
  const [listProps] = useList(getCreditList, {
    defaultPageSize: 8,
  });
  const { data: requestRes } = useRequest(getCreditRule);
  const { data: descData } = requestRes || {};
  const {
    location: { query },
  } = history;

  const {
    common: { creditInfo },
  } = useSelector(({ common }) => ({ common }));

  const { allScore, titleName } = creditInfo || {};

  const { loading, pagination, list } = listProps;
  const { total, pageNum, pageSize, changePage } = pagination;

  const renderList = () => (
    <Spin spinning={loading}>
      {list.length > 0 ? (
        <>
          <List
            itemLayout="horizontal"
            dataSource={list}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={<div>{item.creditDetailName || ''}</div>}
                  description={item.gmtCreate || '-'}
                />
                <div>{`+${item.score ?? 0}`}</div>
              </List.Item>
            )}
          />
          <Pagination
            current={pageNum}
            pageSize={pageSize}
            onChange={changePage}
            total={total}
            hideOnSinglePage
          />
        </>
      ) : (
        <Empty />
      )}
    </Spin>
  );

  const renderDesc = () => <CreditDesc data={descData} />;

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <LayoutTitle title="我的学分记录" />
        <div className={styles.desc}>
          <Link to="/person/my-credit">
            <Space size={16}>{titleName ? `${titleName} ${allScore ?? '-'}分` : null}</Space>
          </Link>
          <Link to="/person/my-credit?desc=1">学分说明</Link>
        </div>
      </div>
      <div className={styles.body}>{query?.desc ? renderDesc() : renderList()}</div>
    </div>
  );
};

export default MyCreditPage;
