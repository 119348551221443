import { get, post, postDownload } from '@/utils/fetch';

/**
 * 获取我的证书
 */
export const fetchCertificate = () => get('/zs-api/certificate/instance/my');

/**
 * 获取我的专业证书
 * @returns list
 */
export const fetchMyOutCertificate = (certno) => get(`/zs-api/certificate/instance/myout?certno=${certno}`);

// 个人信息查询
export const getAccountInfo = () => get('/uuc/user/management/userCenter/myUserInfo');

/**
 * 上传专业证书图片
 * "instanceId": 123,   //实例ID
 * "categoryId": 123,   //分类ID
 * "file": file  //文件
 */
export const uploadCertificate = (data) => post('/zs-api/certificate/instance/uploadPic', data);

/**
 * 删除专业证书图片
 * "instanceId": 123,   //实例ID
 * "categoryId": 123,   //分类ID
 * "fileId": 12  //文件Id
 */
export const delImgApi = (data) => post('/zs-api/certificate/instance/deletePic', data);

/**
 * 下载导入模板
 * @param data
 * @returns
 */
export const downloadByIdApi = (fileId: string) =>
  postDownload<{
    success?: boolean;
    data?: unknown;
  }>(`/zs-api/common/downloadByFileId?fileId=${fileId}`);

/**
 * 电子证书-下载证书
 * @param data
 * @returns
 */
export const downloadByField = (fileKey: string) =>
  postDownload<{
    success?: boolean;
    data?: unknown;
  }>(`/zs-api/common/download?fileKey=${fileKey}`);

export const zhuceDetail = (id) => get(`/zs-api/certificate/registration/${id}`);
export const feiZhuceDetail = (id) => get(`/zs-api/certificate/non-registration/${id}`);

export const zhichengDetail = (id) => get(`/zs-api/certificate/professional/${id}`);

export const zhiyeDetail = (id) => get(`/zs-api/certificate/skill/${id}`);

export const qitaDetail = (id) => get(`/zs-api/certificate/other/${id}`);
