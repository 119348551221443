import React, { useRef } from 'react';
import { history } from 'umi';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import Container from '@/components/Container';
import { Carousel, Spin } from 'antd';
import Empty from '@/components/Empty';
import { getBannerList } from '../../services';
import styles from './Swiper.less';

export interface SwiperProps extends React.HTMLAttributes<HTMLDivElement> {}

export interface BannerItemProps {
  type: 1 | 2;
  id: number | string;
  advertisingImageUrl: string;
  informationId: number | string;
}

/** 广告中心（banner）图片访问地址 */
const previewUrl = (key) =>
  `${window['apiPrefix'] ?? ''}/information/common/download?fileKey=${key}`;

const Swiper = (props: SwiperProps) => {
  const carouselRef = useRef(null);

  const { data: requestData, loading } = useRequest(getBannerList);
  const { data } = requestData || {};
  const { list } = data || {};

  // 跳转到banner详情
  const handleDetail = ({ newsType, type, advertisingUrl, informationId }) => {
    // 外部链接或关联外部链接
    if (type === 1 || newsType === 1) {
      window.open(advertisingUrl);
    } else {
      history.push({
        pathname: '/info/info-center/information-detail',
        query: {
          pageType: informationId,
        },
      });
    }
  };

  return (
    <Container className={styles.root}>
      <Spin spinning={loading}>
        {list?.length ? (
          <>
            <Carousel
              ref={carouselRef}
              autoplay
              dots
              autoplaySpeed={5000}
              className={styles.carousel}
            >
              {list.map((item) => (
                <div key={item.id}>
                  <div
                    onClick={() => {
                      handleDetail(item);
                    }}
                    className={styles.bannerItem}
                    style={{
                      backgroundImage: `linear-gradient(180deg, rgba(0,24,67,0.00) 85%, rgba(0,11,33,0.34) 100%),url(${previewUrl(
                        item.advertisingImageUrl,
                      )})`,
                    }}
                  />
                </div>
              ))}
            </Carousel>
            <LeftCircleFilled
              className={styles['slick-prev']}
              onClick={() => carouselRef.current.prev()}
            />
            <RightCircleFilled
              className={styles['slick-next']}
              onClick={() => carouselRef.current.next()}
            />
          </>
        ) : (
          <Empty style={{ margin: '105px auto' }} />
        )}
      </Spin>
    </Container>
  );
};

export default Swiper;
