// loading 位置样式（居中且zoom）
import React from 'react';
import { Spin } from 'antd';
import loadingIcon from '@/assets/images/loading.png';
import getEmitter, { Events } from './utils/EventEmitter';

// 判断当前build环境是预发还是生产
const isProd = ENV_BUILD === 'prod';

// 开发环境 子应用地址为各个子应用的预发环境地址

// 生产环境 子应用地址为合并部署策略改造之后的存在于打包之后的包静态地址

const _configJson = isProd ? '/config.json' : '/config-dev.json';
/**
 * 禁止所有子应用修改页面的标题
 */
try {
  document.title = '数字学习成长平台';
  window.originalTitle = document.title;
  Object.defineProperty(document, 'title', {
    get: () => '数字学习成长平台',
    set: () => {},
  });
} catch (e) {}

const spinId = 'spin-id';
// const spinNodeHTML = `
// <div id=${spinId} class="ant-spin ant-spin-spinning ant-spin-show-text">
//   <span class="ant-spin-dot ant-spin-dot-spin">
//     <i class="ant-spin-dot-item"></i>
//     <i class="ant-spin-dot-item"></i>
//     <i class="ant-spin-dot-item"></i>
//     <i class="ant-spin-dot-item"></i>
//   </span>
//   <div class="ant-spin-text">努力加载...</div>
// </div>
// `;

export const qiankun = fetch(_configJson)
  .then((res) => res.text())
  .then((res) => {
    const { apps, routes } = JSON.parse(res) || {};
    console.log(apps);
    console.log(routes);
    return {
      // 注册子应用信息
      apps,
      // 注册路由
      routes,
      lifeCycles: {
        // 只会触发一次，加载时间较长
        beforeLoad: (props: unknown) => {
          // loading
          let wrapEle = document.querySelector('.spin-wrap');
          if (!wrapEle) {
            wrapEle = document.createElement('div');
            wrapEle.setAttribute('class', 'spin-wrap');
            wrapEle.setAttribute('style', 'position: absolute; top: 50%; left: 50%;');
            document.body.appendChild(wrapEle);
          }
          const spinEle = document.querySelector(`#${spinId}`);
          if (!spinEle) {
            // wrapEle.innerHTML = spinNodeHTML;
            wrapEle.innerHTML = `<img id=${spinId} src="${loadingIcon}" alt="" style="width: 60px; height: 60px" />`;
          }
          // console.log('beforeLoad...');
        },
        beforeMount: (props: unknown) => {
          // console.log('beforeMount', props);
        },
        afterMount: (props: unknown) => {
          // 删除 loading
          const wrapEle = document.querySelector('.spin-wrap');
          const spinEle = document.querySelector(`#${spinId}`);
          if (wrapEle && spinEle) {
            wrapEle.removeChild(spinEle);
          }
          // console.log('afterMount', props);
        },
        afterUnmount: (props: unknown) => {
          // console.log('afterMount', props);
        },
      },
    };
  });

export function render(oldRender) {
  Spin.setDefaultIndicator(<img src={loadingIcon} alt="" style={{ width: 60, height: 60 }} />);
  oldRender();
}


export function useQiankunStateForSlave() {
  return {
    via: 'portal-web-fe',
    getEmitter,
    Events,
  };
}
