import React from 'react';
import { Button, Tooltip } from 'antd';
import examImg from '@/assets/images/exam.png';
import practiceImg from '@/assets/images/practice.png';
import styles from './ExamCard.less';

export interface ExamProps {
  id: string;
  name: string;
  workCategoryName: string; // 工种
  desc: string;
  startTime: string;
  endTime: string;
  examineeNumber: number; // 考试人数
  isPayCost: number;
  model?: number;
  paymentAmount: number;
  signNumber: number; // 报名人数
  typeId: string;
  typeName: string; // 考试类型名称
}
export interface ExamCardProps extends React.HTMLAttributes<HTMLDivElement> {
  cardClassName?: string; // 卡片样式
  data: ExamProps[];
  onClick?: (id) => void;
  btnText?: string; // 按钮名称
  type?: 'exam' | 'practice'; // 考试/练习
  btnDisabled?: boolean; // 是否禁用按钮
  incorrect?: boolean; // 是否错题模式
}

const ExamCard = (props: ExamCardProps) => {
  const {
    className = '',
    cardClassName = '',
    data = [],
    btnText,
    type,
    btnDisabled = false,
    onClick = () => {},
    incorrect,
    ...otherProps
  } = props;

  const renderItem = (item) => {
    const {
      id,
      name,
      typeName,
      workCategoryName,
      startTime,
      endTime,
      signNumber,
      examineeNumber,
      paymentAmount,
      isPayCost,
      sitePlace,
      siteName,
    } = item;
    const isFull = signNumber === examineeNumber; // 报名人数是否已满，报名人数===考试人数

    const tagText =
      type === 'exam'
        ? `${workCategoryName || '--'} - ${typeName || '--'}`
        : `${workCategoryName || '--'}`;
    // 考试错题卡片状态
    const incorrectExam = type === 'exam' && incorrect;

    const examPlace = sitePlace || siteName ? `${sitePlace || ''}${siteName || ''}` : '--';

    return (
      <div className={`${styles.item} ${cardClassName}`} key={id}>
        <div className={styles.flex}>
          <img src={type === 'practice' ? practiceImg : examImg} />
          <div className={styles.main}>
            <div className={styles.title} title={name}>
              {name}
            </div>
            <div className={styles.desc}>{!incorrectExam && tagText}</div>
          </div>
          {!incorrectExam && (
            <div className={styles.money}>
              {isPayCost > 0 ? (
                <span>
                  ¥
                  <span className={styles.amount}>
                    {paymentAmount}
                    {' '}
                  </span>
                </span>
              ) : (
                <span className={styles.free}>免费</span>
              )}
            </div>
          )}
        </div>
        <div className={styles.flex}>
          <div className={styles.main}>
            <div className={styles.desc}>
              {!incorrectExam && (
                <div className={styles.num}>
                  {isFull ? (
                    <span>
                      报名人数：
                      <span className={styles.full}>已满</span>
                    </span>
                  ) : (
                    `报名人数：${signNumber || 0}`
                  )}
                </div>
              )}
              {incorrectExam && (
                <div>
                  <span>
                    考试地点：
                    <span>{examPlace}</span>
                  </span>
                </div>
              )}
            </div>
            {startTime && (
              <Tooltip title={`${startTime}~${endTime}`}>
                <div className={styles.time}>{`考试计划时间：${startTime}~${endTime}`}</div>
              </Tooltip>
            )}
          </div>
          <Button
            className={`${styles.btn} ${btnDisabled && styles.disabled}`}
            disabled={btnDisabled}
            onClick={() => {
              onClick(id, item);
            }}
          >
            {btnText || '考试详情'}
          </Button>
        </div>
      </div>
    );
  };
  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      {data.map((item) => renderItem(item))}
    </div>
  );
};
export default ExamCard;
