import React, { useState, useEffect } from 'react';
import { Spin, Modal } from 'antd';
import useLoading from '@/hooks/useLoading';
import { ConfigType } from '../typing.d';
import { formItemsMap } from './constant';
import UploadImg from '../components/uploadImg';
import styles from './DetailPage.less';

export interface DetailProps extends React.HTMLAttributes<HTMLDivElement> {
  isModalVisible: boolean;
  infoData: unknown;
  setVisible: (val: boolean) => void;
}

const DetailPage = (props: DetailProps) => {
  const { isModalVisible, setVisible = () => {}, infoData = {}} = props;
  const { type = '0', id } = infoData;
  const [detailInfo, setDetailInfo] = useState<unknown>({});
  const { config, detailApi } = formItemsMap[type];
  const [loading, wrapDetailApi] = useLoading(detailApi);
  const renderInfo = (key: string, value: string) => (
    <div className={styles.info}>
      <p>{`${key}：`}</p>
      <p title={value}>{value ? value : '-'}</p>
    </div>
  );

  const infoRender = () =>
    config.map((item: ConfigType, index: number) => {
      const { label, key } = item;
      return (
        <React.Fragment key={label}>
          {renderInfo(
            label,
            item.format ? item.format(detailInfo[key], detailInfo) : detailInfo[key],
          )}
        </React.Fragment>
      );
    });

  useEffect(() => {
    wrapDetailApi(id).then((res) => {
      if (res.success) {
        const data = res.data || {};
        setDetailInfo(data);
      }
    });
  }, []);

  return (
    <Modal
      title="证书详情"
      footer={null}
      className={styles.root}
      width={844}
      visible={isModalVisible}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Spin spinning={loading}>
        <div className={styles.infoRoot}>{infoRender()}</div>
        <div className={styles.infoPhoto}>
          <p className={styles.photoP}>证书图片：</p>
          <div className={styles.photoDiv}>
            <UploadImg value={detailInfo.fileIds} data={detailInfo} />
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default DetailPage;
