import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message as Message, Modal, Spin } from 'antd';
import { useRequest } from 'ahooks';

import lx from '@/assets/order-icon/练习@2x.png';
import ks from '@/assets/order-icon/考试@2x.png';
import px from '@/assets/order-icon/班级@2x.png';
import { deleteApi, cancelApi, startRefundApi, cancelrefundApi, getRefundReasonApi } from './services';

const { confirm, error } = Modal;

// 个人线上支付/公司线下代支付
export const ChannelConst = {
  ON_LINE: 1,
  OFF_LINE: 2,
};

// 商品类型，培训 / 考试 / 练习
export const OrderTypeConst = {
  CLASS: 1,
  EXAM: 2,
  PRACTICE: 3,
};

// 是否允许线下缴费 0:否 1:是
export const FeeModeConst = {
  NOT: 0,
  YES: 1,
};

// 主管报名-个人缴费
export const ZGBM_GRJF = 2;

export const renderDefaultImg = {
  [OrderTypeConst.CLASS]: px,
  [OrderTypeConst.EXAM]: ks,
  [OrderTypeConst.PRACTICE]: lx,
};

// 获取商品的图片，获取则不到使用默认的
export const getGoodImage = (props) => {
  const { originalChannel, goodsImageKey, goodsFileId } = props;
  return goodsImageKey || goodsFileId || renderDefaultImg[originalChannel || 1];
};

// 删除
export const handleDelete = (orderCode, refresh, refreshCount?: () => void) => {
  confirm({
    title: '确定要删除吗？',
    icon: <ExclamationCircleOutlined />,
    content: '删除订单不可恢复',
    okText: '确定',
    cancelText: '取消',
    onOk: async () => {
      const { success, message } = await deleteApi(orderCode);
      if (success) {
        Message.success('删除成功!');
        refresh();
        if (typeof refreshCount === 'function') refreshCount();
      } else {
        Message.error(message);
      }
    },
  });
};

// 取消
export const handleCancel = (orderCode, refresh) => {
  confirm({
    title: '确定要取消吗？',
    icon: <ExclamationCircleOutlined />,
    content: '取消订单不可恢复',
    onOk: async () => {
      const { success, message } = await cancelApi(orderCode);
      if (success) {
        Message.success('取消成功');
        refresh();
      } else {
        Message.error(message || '取消失败');
      }
    },
  });
};

const RefundContent = (<div>
  <div>{'退款申请 申请退款将影响本次考试，如果确定退款请按照以下步骤进行申请。'}</div>
  <div>{'1、点击“确定”提交申请；'}</div>
  <div>{'2、上传凭证至指定邮箱，具体流程请参照“首页>图书馆>平台退费操作”。'}</div>
  <div>{'3、耐心等待财务审核；”'}</div>
</div>)

// 申请退款
export const handleRefund = (orderCode, refresh) => {
  confirm({
    title: '确定要申请退款吗？',
    width: 640,
    icon: <ExclamationCircleOutlined />,
    content: RefundContent,
    onOk: async () => {
      const { success, message } = await startRefundApi(orderCode);
      if (success) {
        Message.success('申请提交成功');
        refresh();
      } else {
        Message.error(message || '申请提交失败');
      }
    },
  });
};

// 取消退款
export const handleCancelRefund = (orderCode, refresh) => {
  confirm({
    title: '确定要取消退款吗？',
    icon: <ExclamationCircleOutlined />,
    onOk: async () => {
      const { success, message } = await cancelrefundApi(orderCode);
      if (success) {
        Message.success('取消退款成功!');
        refresh();
      } else {
        Message.error(message);
      }
    },
  });
};

export const PageFromConst = {
  ORDER: 'order', // 从订单列表页面
  BUYAGAIN: 'buy-again', // 从订单列表中的再次购买
  CLASS: 'class', // 班级页面
  EXAM: 'exam', // 考试页面
  PRACTICE: 'practice', // 练习页面
};

function ReasonContent({ order }) {
  const { data = {}, loading } = useRequest(async () => await getRefundReasonApi(order));
  const { data: { rejectReason = '' } = {} } = data;
  return <Spin spinning={loading}>
    <div>{rejectReason ?? ''}</div>
  </Spin>
}

export const handleViewReason = (order) => {
  error({
    title: '退款拒绝原因：',
    content: <ReasonContent order={order} />,
  })
}