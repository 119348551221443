import React, { useEffect } from 'react';
import classDefault from '@/assets/images/classDefault.png';
import { Progress, Space } from 'antd';
import { history } from 'umi';
import styles from './GoodsCard.less';
import { getGoodsDetailApi } from '../../services';

const GoodsCard = (props) => {
  const { data } = props;
  const [coursewareList, setCoursewareList] = React.useState([]);
  const [questionList, setQuestionList] = React.useState([]);
  const [videoList, setVideoList] = React.useState([]);
  // const isStarting = data.studyDuration > 0;
  // const isOpen = data.isOpen === 2;
  const handleToDetail = () => {
    history.push(`/zyzx/goods-detail?id=${data.id}&hasPurchase=1`);
  };

  function parseJsonSafely(jsonString) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      // 返回空数组作为默认值
      return [];
    }
  }

  // const coursewareList = parseJsonSafely(data.relatedCourseId);
  // const questionList = parseJsonSafely(data.relatedQuizId);
  // const videoList = parseJsonSafely(data.relatedVideoId);

  useEffect(() => {
    getGoodsDetailApi(data.id).then((res) => {
      const goods = res.data;

      setCoursewareList(parseJsonSafely(goods.relatedCourseId));
      setQuestionList(parseJsonSafely(goods.relatedQuizId));
      setVideoList(parseJsonSafely(goods.relatedVideoId));
      console.log('goodsInfo', data, 'coursewareList', coursewareList);
    });
  }, []);

  return (
    <div className={styles.root} onClick={handleToDetail}>
      <div className={styles.left}>
        {data.isPremium === 1 && <div className={styles.isOpenClass}>精品资源</div>}
        <img src={data.goodsImage ? `/train/file/show/${data.goodsImage}` : classDefault} alt="" />
      </div>
      <div className={styles.right}>
        <div className={styles.className}>{data.goodsName}</div>
        {data.goodsType === 0 && (
          <div className={styles.count}>
            {coursewareList && (
              <div className={styles.signupCount}>
                课件数：
                {coursewareList.length}
                {/* {data.downloadCourse === 1 && <div className={styles.courseCount}>可下载</div>} */}
              </div>
            )}
            <div className={styles.line}>|</div>
            {questionList && (
              <div className={styles.signupCount}>
                练习数：
                {questionList.length}
              </div>
            )}
            <div className={styles.line}>|</div>
            {videoList && (
              <div className={styles.signupCount}>
                视频数： {videoList.length}
                {/* {data.downloadVideo === 1 && <div className={styles.courseCount}>可下载</div>} */}
              </div>
            )}

            {/* <div className={styles.signupCount}>
            {data.countOfSignUp}
            人已报名
          </div> */}
          </div>
        )}
        <div className={styles.progress}>
          <span className={true ? styles.progressText : styles.noStart}>
            {data.order.amountReceive && `实付款：￥${data.order.amountReceive}`}
          </span>
          {/* {isStarting && (
            <Progress percent={data.studyDuration} strokeColor="#59C014" size="small" />
          )} */}
        </div>
        <div className={styles.order}>
          <Space size={20}>
            <span>
              订单号：
              {data.order.orderCode || '-'}
            </span>
            <span>
              时间：
              {data.order.gmtCreate || '-'}
            </span>
          </Space>
        </div>
      </div>
    </div>
  );
};
export default GoodsCard;
