import React from 'react';
import { Row, Col, Spin, Pagination } from 'antd';
import Empty from '@/components/Empty';
import useList from '@/hooks/useList';
import LayoutTitle from '../components/LayoutTitle';
import TeacherCard from './components/TeacherCard';
import { getFocusList } from './services';
import styles from './MyFocusPage.less';

const MyFocusPage = () => {
  const [listProps] = useList(getFocusList, {
    defaultPageSize: 8,
  });

  const { loading, pagination, list } = listProps;
  const { total, pageNum, pageSize, changePage } = pagination;

  return (
    <div className={styles.root}>
      <LayoutTitle />
      <div className={styles.body}>
        <Spin spinning={loading}>
          {list.length > 0 ? (
            <>
              <div className={styles.list}>
                <Row gutter={[17, 18]}>
                  {list.map(item => (
                    <Col key={item.id}>
                      <TeacherCard value={item} />
                    </Col>
                  ))}
                </Row>
              </div>
              <Pagination
                current={pageNum}
                pageSize={pageSize}
                onChange={changePage}
                total={total}
                hideOnSinglePage
              />
            </>
          ) : (
            <Empty />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default MyFocusPage;
