import React from 'react';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { history } from 'umi';
import styles from './TopMenu.less';

export interface TopMenuProps extends MenuProps {}

const menuData = [
  {
    text: '首页',
    key: '/home',
  },
  {
    text: '学习中心',
    key: '/knowledge/library',
  },
  {
    text: '资源中心',
    key: '/zyzx/goods-center',
  },

  /* {
    text: '学习日历',
    key: '/study-calendar',
  }, */
  {
    text: '我的',
    key: '/person',
  },
];

const TopMenu = (props: TopMenuProps) => {
  let selectedKeys: string[] = [];
  if (
    location.pathname.includes('person/my-order/confirm') &&
    !location.search.includes('from=order') &&
    !location.search.includes('from=buy-again')
  ) {
    selectedKeys = [];
  } else {
    selectedKeys = location.pathname.split('/')[1].includes('person')
      ? ['/person']
      : [location.pathname];
  }
  return (
    <div className={styles.menu}>
      <Menu
        onClick={(item) => {
          if (item.key) {
            history.push(item.key);
          }
        }}
        selectedKeys={selectedKeys}
        mode="horizontal"
      >
        {menuData.map((item) => (
          <Menu.Item key={item.key}>{item.text}</Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

TopMenu.defaultProps = {
  className: '',
  data: [],
};

export default TopMenu;
