import { post } from '@/utils/fetch';
import { FetchResult } from '@/utils/defs';

export type CreditRuleProps = {
  creditName: string; // 类型名称
  score: number; // 单项得分
  upperLimit: number; // 积分上限
  remark: string; // 描述信息
};

/** 获取学分列表 */
export const getCreditList = (params) =>
  post<FetchResult>('/information/creditDetail/page', params);

/** 获取学分说明 */
export const getCreditRule = () =>
  post<FetchResult<CreditRuleProps>>('/information//creditRule/list', {});
