/* eslint-disable arrow-parens */
import React, { useEffect } from 'react';
import AutoTable from '@/components/AutoTable';
import { Tooltip } from 'antd';
import { getQAListApi } from './services';
import LayoutTitle from '../components/LayoutTitle';
import styles from './MyQAPage.less';

const formItems = [
  {
    type: 'Input',
    label: '课程名称',
    name: 'courseName',
    placeholder: '请输入',
    rules: [{ max: 20 }],
  },
  {
    type: 'RangePicker',
    label: '提问时间',
    name: 'range',
    style: {
      width: 250,
    },
  },
];

// 课程答疑信息
const qaManagementColumns = [
  {
    title: '课程名称',
    dataIndex: 'courseName',
    key: 'courseName',
    width: 170,
    render: (v) => (
      <Tooltip placement="topLeft" title={v}>
        <span className="ellipsis2">{v || '—'}</span>
      </Tooltip>
    ),
  },
  {
    title: '问题描述',
    dataIndex: 'qaContent',
    key: 'qaContent',
    width: 170,
    render: (v) => (
      <Tooltip placement="topLeft" title={v}>
        <span className="ellipsis3">{v || '—'}</span>
      </Tooltip>
    ),
  },
  {
    title: '回答',
    dataIndex: 'answerContent',
    key: 'answerContent',
    render: (v) => (
      <Tooltip placement="topLeft" title={v}>
        <span className="ellipsis3">{v || '—'}</span>
      </Tooltip>
    ),
  },
  {
    title: '提问时间',
    dataIndex: 'gmtSubmit',
    key: 'gmtSubmit',
    width: 190,
  },
  {
    title: '回答时间',
    dataIndex: 'gmtAnswer',
    key: 'gmtAnswer',
    width: 190,
  },
];

const MyQAPage = (props) => {
  useEffect(() => {}, []);
  return (
    <div className={styles.root}>
      <LayoutTitle />
      <AutoTable
        formItems={formItems}
        request={getQAListApi}
        showType="mini"
        // params={{ courseId, questionStatus: 1 }}
        columns={qaManagementColumns}
        showOrderIndex
        {...props}
      />
    </div>
  );
};

export default MyQAPage;
