import React from 'react';
import { history } from 'umi';
import { Spin } from 'antd';
import Empty from '@/components/Empty';
import ContentCard from '@/components/LecturerCard';
import { useRequest } from 'ahooks';
import Theme from '@/themes/theme';
import Container from '@/components/Container';
import { getLecturerList } from '../../services';
import styles from './LecturerCard.less';

export interface LecturerCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const LecturerCard = (props: LecturerCardProps) => {
  const { data: requestData, loading } = useRequest(getLecturerList);
  const { data } = requestData || {};
  const { list } = data || {};

  return (
    <Container
      className={styles.root}
      title="讲师中心"
      extra={
        <span
          onClick={() => {
            history.push('/teacher/teacher-list');
          }}
        >
          查看全部讲师
        </span>
      }
      style={{ backgroundImage: Theme.background.Gradient ?? 'unset' }}
    >
      <Spin spinning={loading}>
        {list?.length > 0 ? (
          list.map((item) => <ContentCard key={item.id} {...item} />)
        ) : (
          <Empty style={{ margin: '190px auto' }} />
        )}
      </Spin>
    </Container>
  );
};

export default LecturerCard;
