import regExp from '@/utils/regExp';

const fieldPropsObj = {
  yx: { fieldProps: { maxLength: 100 } },
  gzdw: { fieldProps: { maxLength: 200 } },
  gw: { fieldProps: { maxLength: 100 } },
  zw: { fieldProps: { maxLength: 100 } },
  xl: { fieldProps: { maxLength: 100 } },
  zy: { fieldProps: { maxLength: 100 } },
};

const rulesObj = {
  yx: regExp('email'),
  sjhm: regExp('phone'),
};

export { fieldPropsObj, rulesObj };
