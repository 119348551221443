import React from 'react';
import {
  TabPaneProps as AntdTabPaneProps,
  TabsProps as AntdTabsProps,
  Tabs as AntdTabs,
} from 'antd';
import styles from './Tabs.less';
const { TabPane } = AntdTabs;

export interface TabsProps extends AntdTabsProps {
  tabPanes: Array<TabPaneProps>; //tab切换的子组件
  extra?: React.ReactNode;
  headerClassName?: string;
}

export interface TabPaneProps extends AntdTabPaneProps {
  key: string;
}

const Tabs: React.FC<TabsProps> = (props) => {
  const { tabPanes, extra, headerClassName = '', ...otherTabsProps } = props;

  const renderTabBar = (props, DefaultTabBar) => (
    <div className={`${styles.header} ${headerClassName}`}>
      {extra}
      <DefaultTabBar {...props} />
    </div>
  );

  return (
    <div className={styles.root}>
      <AntdTabs renderTabBar={renderTabBar} {...otherTabsProps}>
        {tabPanes.map((tabp) => (
          <TabPane tab={tabp.tab} key={tabp.key}>
            {tabp.children}
          </TabPane>
        ))}
      </AntdTabs>
    </div>
  );
};

Tabs.defaultProps = {
  tabPanes: [],
};

export default Tabs;
