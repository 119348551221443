import React from 'react';
import { Result } from 'antd';
import OrderTitle from '../../../components/OrderTitle';
import styles from './ConfirmSucess.less';

const ConfirmSuccess = (props) => {
  const { type, title, extra } = props;
  return (
    <div className={styles.qrCode}>
      <OrderTitle.SubTitle>{type}</OrderTitle.SubTitle>
      <div className={styles.body}>
        <div className={styles.content}>
          <Result status="success" title={title} extra={extra} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmSuccess;
