import React from 'react';
import logo from '@/assets/images/logo.png';
import copy from '@/assets/images/copy.png';
import Theme from '@/themes/theme';
import styles from './Footer.less';

export interface FooterProps extends React.HTMLAttributes<HTMLDivElement> {
  pathname?: string;
}

const Footer = (props: FooterProps) => {
  const { className = '', style, pathname, ...otherProps } = props;
  const isHomePage = String(pathname).includes('home');
  return (
    <div
      className={`${styles.root} ${className}`}
      {...otherProps}
      style={{ ...style, color: isHomePage ? Theme.color.footerColor : 'rgba(0,10,32,0.45)' }}
    >
      <img
        src={isHomePage ? Theme.background.Logo : logo}
        width="131"
        style={{ marginBottom: 16 }}
      />
      <div>云南省建筑技工学校版权所有©2022 Powered by 滇ICP备12005733号-4</div>
      <div
        className={styles.link}
        onClick={() =>
          window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53010302001217')
        }
      >
        <img src={copy} />
        <span style={{ marginLeft: 4 }}>滇公网安备 53010302001217号</span>
      </div>
    </div>
  );
};

export default Footer;
