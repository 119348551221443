import React, { useMemo } from 'react';
import { Spin } from 'antd';
import { useRequest } from 'ahooks';
import { useLocation, history } from 'umi';
import usePromisifyModal from '@/hooks/usePromisifyModal';
import InvoiceDetailModal from '@/pages/person/my-order/invoice/components/InvoiceDetailModal';
import OrderBreadcrumb from '../components/OrderBreadcrumb';
import OrderTitle from '../components/OrderTitle';
import OrderChannel from '../components/OrderChannel';
import OrderBanner from '../components/OrderBanner';
import OrderInfo from '../components/OrderInfo';
import FooterBar from '../components/FooterBar';
import CountDown from '../components/CountDown';
import { detailApi, clsoeOrder } from '../services';
import { ZGBM_GRJF, handleDelete, handleCancel, handleRefund, handleCancelRefund } from '../utils';
import styles from './DeatailPage.less';

const DeatailPage = () => {
  const location = useLocation();
  const modal = usePromisifyModal();
  const { data: requestData, loading, run } = useRequest(() =>
    detailApi(location.pathname.split('/')?.[3]),
  );

  const { data = {} } = requestData || {};

  const { orderStatus, statusRemark, orderCode, payWay, gmtPayDeadline, payType } = data || {};

  const refresh = () => {
    setTimeout(() => {
      history.push('/person/my-order');
    }, 1000);
  };

  // 主管报名个人缴费的订单不允许学员取消订单
  const cancelBtn =
    payType !== ZGBM_GRJF
      ? // ? [{ text: '取消订单', onClick: () => handleCancel(orderCode, refresh) }]
        // 屏蔽取消订单
        []
      : [];

  const payWayMap = {
    '1': {
      label: '线上',
      action: [
        {
          type: 'primary',
          text: '立即支付',
          onClick: () => {
            history.push({
              pathname: '/person/my-order/confirm',
              query: { orderCode, from: 'order' },
            });
          },
        },
        ...cancelBtn,
      ],
    },
    '2': {
      label: '线下',
      action: cancelBtn,
    },
  };

  const statusRemarkMap = {
    '2': <span className={styles.default}>退款成功，交易关闭</span>,
    '4': <span className={styles.default}>交易已取消</span>,
    '5': <span className={styles.default}>管理员取消交易</span>,
    '6': <span className={styles.default}>超时未付款，订单自动关闭</span>,
  };

  const orderStatusMap = {
    '1': {
      status: gmtPayDeadline ? (
        <CountDown
          targetDate={gmtPayDeadline}
          onEnd={() => {
            // 自动关闭订单、更新订单状态
            clsoeOrder(orderCode).finally(() => run());
          }}
        />
      ) : null,
      action: payWayMap[payWay]?.action,
    },
    '2': {
      status: <span className={styles.warn}>已付款</span>,
      action: [
        {
          text: '申请退款',
          onClick: () => handleRefund(orderCode, refresh),
        },
      ],
    },
    '3': {
      status: <span className={styles.default}>退款中</span>,
      action: [
        {
          text: '取消退款',
          onClick: () => handleCancelRefund(orderCode, refresh),
        },
      ],
    },
    '4': {
      status: <span className={styles.success}>交易成功</span>,
      // action: [
      //   {
      //     text: '删除订单',
      //     onClick: () => handleDelete(orderCode, refresh),
      //   },
      // ],
      action: [],
    },
    '5': {
      status: statusRemarkMap[statusRemark],
      action: [
        {
          text: '再次购买',
          type: 'primary',
          onClick: () => {
            history.push({
              pathname: '/person/my-order/confirm',
              query: { orderCode, from: 'buy-again' },
            });
          },
        },
        {
          text: '删除订单',
          onClick: () => handleDelete(orderCode, refresh),
        },
      ],
    },
  };

  const invoiceAction = useMemo(() => {
    const { invoiceId, invoiceStatus, orderStatus } = data ?? {};
    if (typeof invoiceStatus === 'undefined') return [];
    //  发票状态:10作废中 11已作废 , 20 待审核, 21 已审核 ,30开具中, 31已开具,-1未申请
    if (invoiceStatus === -1 && orderStatus === 4) {
      return [
        {
          text: '开发票',
          type: 'primary',
          onClick: () => {
            history.push({
              pathname: '/person/my-order/invoice',
              query: { orderCode, amount: data.amountReceive || 0 },
            });
          },
        },
      ];
    }
    if (invoiceId) {
      return [
        {
          type: 'primary',
          text: '开票详情',
          render: null,
          loading: false,
          onClick: () => {
            modal.show(<InvoiceDetailModal id={data.invoiceId} />);
          },
        },
      ];
    }
    return [];
  }, [data]);

  const { status, action } = useMemo(() => orderStatusMap[orderStatus] || {}, [orderStatus]);

  return (
    <>
      <OrderBreadcrumb />
      <Spin spinning={loading}>
        <div className={styles.root}>
          <div style={{ flex: 1 }}>
            <OrderTitle extra={status}>订单详情</OrderTitle>
            <OrderBanner {...data} />
            {/* 未支付状态时展示确认支付渠道 */}
            {orderStatus === 1 && <OrderChannel value={payWay} disabled />}
            <OrderInfo {...data} />
          </div>
          <FooterBar config={[...(action || []), ...invoiceAction]} />
        </div>
      </Spin>
      {modal.instance}
    </>
  );
};

export default DeatailPage;
