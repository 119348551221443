import excelIcon from '@/assets/file-icon/excel.svg';
import pptIcon from '@/assets/file-icon/ppt.svg';
import unknownIcon from '@/assets/file-icon/unknown.svg';
import vedioIcon from '@/assets/file-icon/vedio.svg';
import pdfIcon from '@/assets/file-icon/pdf.svg';
import wordIcon from '@/assets/file-icon/word.svg';
import imgIcon from '@/assets/file-icon/image.svg';
import classTest from '@/assets/file-icon/classTest.svg';
import zipIcon from '@/assets/file-icon/zip.svg';
import txtIcon from '@/assets/file-icon/txt.svg';

export const getTypeImg = (fileType) => {
  switch (fileType?.toLocaleLowerCase()) {
    case 'ppt':
    case 'pptx':
      return pptIcon;
    case 'xls':
    case 'xlsx':
      return excelIcon;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'svg':
      return imgIcon;
    case 'doc':
    case 'docx':
      return wordIcon;
    case 'pdf':
      return pdfIcon;
    case 'mp4':
      return vedioIcon;
    case 'class':
      return classTest;
    case 'zip':
    case 'rar':
    case '7z':
      return zipIcon;
    case 'txt':
      return txtIcon;
    default:
      return unknownIcon;
  }
};

export const getFileName = (fileName: string, docSuffix: string) => {
  if (!fileName) return '——';
  if (fileName.indexOf(docSuffix) > -1) {
    return fileName;
  } else {
    return fileName + '.' + docSuffix;
  }
};

export const fileSizeFormat = (fileSize) => {
  if (isNaN(Number(fileSize))) return '—MB';
  return (Number(fileSize) / 1024 / 1024).toFixed(3) + 'MB';
};
