import React, { useState } from 'react';
import { useSelector, useDispatch } from 'umi';
import { Menu, Badge, Spin, Tooltip, Pagination } from 'antd';
import Empty from '@/components/Empty';
import MyIcon from '@/components/MyIcon';
import useList from '@/hooks/useList';
// import useMessageCount from './useMessageCount';
import { getTrainMessage, readMessage } from './services';
import styles from './MyMessage.less';

export interface MyMessageProps extends React.HTMLAttributes<HTMLDivElement> {}

const MyMessage = (props: MyMessageProps) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(['1']); // 选中菜单

  const {
    common: { messageUnReadCount, todoUnReadCount },
  } = useSelector(({ common }) => ({ common }));

  const dispatch = useDispatch();

  const [listProps] = useList(getTrainMessage, {
    queryParams: { type: selectedKeys?.[0] },
  });

  const { loading, pagination, list } = listProps;
  const { total, pageNum, pageSize, changePage } = pagination;

  /** 选中菜单 */
  const handleSelected = ({ selectedKeys: _selectedKeys }) => {
    setSelectedKeys(_selectedKeys);
  };

  const refreshUnreadCount = () => {
    dispatch({ type: 'common/getUnReadCount' });
  };

  const handleClick = (item) => {
    const { id, readStatus, pcUrl } = item;
    if (readStatus === 0) {
      readMessage({ id, readStatus }).finally(() => {
        // refresh();
        refreshUnreadCount();
      });
    }
    if (pcUrl) {
      window.open(pcUrl, '_self');
    }
  };

  const messageTypeList = [
    {
      key: '1',
      title: '通知消息',
      icon: <MyIcon type="icon-peixun" />,
      badgeValue: messageUnReadCount,
    },
    {
      key: '2',
      title: '待办消息',
      icon: <MyIcon type="icon-daiban" />,
      badgeValue: todoUnReadCount,
    },
  ];

  const PanelHeader = ({ item }) => (
    <div className={styles.panelHeader}>
      <div className={`${styles.panelTitle} ${+item.readStatus !== 1 && styles.unread}`}>
        {item.title}
      </div>
      <Tooltip title={item.content} overlayStyle={{ maxWidth: 1200 }}>
        <div className={styles.panelContent}>{item.content}</div>
      </Tooltip>
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.affix}>
            <Menu selectedKeys={selectedKeys} onSelect={handleSelected}>
              {messageTypeList.map((item) => (
                <Menu.Item key={item.key} icon={item.icon}>
                  {item.title}
                  <Badge count={item.badgeValue} size="small" style={{ marginLeft: 5 }} />
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightHeader}>{messageTypeList[+selectedKeys - 1].title}</div>
          <div className={styles.rightBody}>
            <Spin spinning={loading}>
              {list.length > 0 ? (
                <>
                  {list.map((item) => (
                    <div
                      style={{ borderBottom: '1px solid rgba(0, 10, 32, 0.1)' }}
                      key={item.id}
                      onClick={() => handleClick(item)}
                    >
                      <PanelHeader item={item} />
                    </div>
                  ))}
                  <Pagination
                    total={total}
                    current={pageNum}
                    pageSize={pageSize}
                    onChange={changePage}
                    showLessItems
                    showSizeChanger
                    showQuickJumper
                    showTotal={(totalCount) => `共有 ${totalCount} 条 `}
                  />
                </>
              ) : (
                <Empty />
              )}
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyMessage;
