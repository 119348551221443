import React, { useState, useRef } from 'react';
import { history, useLocation, useDispatch, useSelector } from 'umi';
import { useClickAway, useUpdateEffect } from 'ahooks';
import { Input, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SearchHistoryBox from '@/components/SearchHistoryBox';
import { setSearchRecord } from '@/utils';
import styles from './Search.less';

export interface SearchProps extends React.HTMLAttributes<HTMLDivElement> {}

const Search = (props: SearchProps) => {
  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);

  const {
    common: { keyword },
  } = useSelector(({ common }) => ({ common }));

  const location = useLocation();

  const dispatch = useDispatch();

  useClickAway(() => {
    if (visible) setVisible(false);
  }, rootRef);

  useUpdateEffect(() => {
    setValue(keyword);
  }, [keyword]);

  useUpdateEffect(() => {
    if (location.pathname !== '/search') {
      setValue('');
    }
  }, [location]);

  const handlePressEnter = (keyword: string) => {
    // console.log('pressEnter', keyword);
    // 跳转到搜索结果页
    if (location.pathname !== '/search') {
      history.push('/search');
    }
    dispatch({ type: 'common/update', keyword });
    if (keyword?.trim()) {
      // 更新搜索历史记录
      setSearchRecord(keyword?.trim());
    }
  };

  const handleChange = (value: string) => {
    setValue(value);
  };

  function handleFocus() {
    setVisible(true);
  }

  return (
    <div className={styles.root} ref={rootRef}>
      <SearchOutlined className={styles.icon} />
      <Divider style={{ top: 0, height: 18 }} type="vertical" />
      <Input
        value={value}
        allowClear
        placeholder="请输入要搜索的内容"
        bordered={false}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleFocus}
        onPressEnter={(e) => handlePressEnter(e.target.value)}
      />
      {!keyword && visible && location.pathname !== '/search' && (
        <SearchHistoryBox className={styles.box} />
      )}
    </div>
  );
};

export default Search;
