import React from 'react';
import { Image } from 'antd';
import cn from 'classnames';
import defaultImg from '@/assets/images/defaultImg.png';
import styles from './InfoBox.less';

export interface InfoBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  status?: string; // 类型，分为考试和培训
  label: string;
  col?: number;
  type: string;
  value: string;
}

const InfoBox = (props: InfoBoxProps) => {
  const { className = '', label, col, type, value, status, ...otherProps } = props;

  const contentRender = (type, value) => {
    if (type === 'img') {
      const imgList =
        value
          ?.split(',')
          ?.filter((i) => i)
          ?.map((item) => (
            <Image
              id={item}
              width={104}
              height={104}
              src={
                status === 'exam'
                  ? `/ks-api/common/download?fileKey=${item}`
                  : `/train/file/show/${item}`
              }
              key={item}
              fallback={defaultImg}
            />
          )) ?? null;
      return imgList;
    }
    return value || '——';
  };

  const title = type === 'img' ? '图片' : value || '——';

  return (
    <div
      className={cn(
        styles.root,
        className,
        { [styles.imageInfo]: type === 'img' },
        { [styles.imagePhoto]: status === 'exam' },
      )}
      {...otherProps}
    >
      {label && <div className={styles.label}>{`${label}:`}</div>}
      <div className={styles.content} title={title}>
        {contentRender(type, value)}
      </div>
    </div>
  );
};

export default InfoBox;
