import React from 'react';
import { MicroAppWithMemoHistory } from 'umi';
import LayoutTitle from '../components/LayoutTitle';
import styles from './MySurveyPage.less';

const MySurveyPage = () => (
  <div className={styles.root}>
    <LayoutTitle style={{ paddingLeft: 24, marginBottom: 0 }} />
    <MicroAppWithMemoHistory name="问卷中心" url="/client/my-survey" />
  </div>
);

export default MySurveyPage;
