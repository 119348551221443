import React from 'react';
import { history } from 'umi';
import dayjs from 'dayjs';
import Card from '@/components/Card';
import classDefault from '@/assets/images/classDefault.png';
import styles from './TrainCard.less';

/** 培训中心 图片访问地址 */
const previewUrl = (key) => `/train/file/show/${key}`;

export const formatDate = (date, format?) => {
  return dayjs(date).format(format || 'MM.DD HH:mm');
};

// 培训卡片分为 培训班级、公开课两种类型
const typeMap = {
  train: 1,
  class: 2,
};

export interface TrainCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const TrainCard = (props) => {
  const {
    className,
    courseNum,
    countOfCourse,
    registerNum,
    countOfSignUp,
    gmtStart,
    gmtEnd,
    fileId,
    id,
    classId,
    type = 'train',
    feeText,
    ...rest
  } = props;

  const handleDetail = () => {
    history.push(`/pxgl/training-detail?classId=${id || classId}&isOpen=${typeMap[type]}`);
  };
  const CountDesc = () => (
    <div>
      <span style={{ marginRight: 12 }}>课程数: {courseNum ?? countOfCourse}</span>
      <span style={{ marginRight: 12 }}>报名人数: {registerNum ?? countOfSignUp}</span>
      <span style={{ color: '#ff5653' }}>{feeText && feeText !== '0' ? `¥${feeText}` : '免费'}</span>
    </div>
  );

  const imgExtra = <div className={styles.isOpenClass}>公开课</div>;

  return (
    <Card
      onClick={handleDetail}
      title={className}
      desc={<CountDesc />}
      url={fileId ? previewUrl(fileId) : classDefault}
      time={formatDate(gmtStart) + '~' + formatDate(gmtEnd)}
      imgExtra={typeMap[type] === 2 ? imgExtra : null}
      {...rest}
    />
  );
};

export default TrainCard;
