import React, { FC } from 'react';
import MyIcon from '@/components/MyIcon';
import cn from 'classnames';
import styles from './Card.less';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  url: string;
  title?: string;
  desc?: React.ReactNode;
  descExtra?: React.ReactNode;
  tag?: React.ReactNode;
  time?: string;
  className?: string;
  size?: 'large' | 'default';
  imgExtra?: React.ReactNode
}

const Card: FC<CardProps> = (props) => {
  const {
    className = '',
    title,
    desc,
    url,
    time,
    tag,
    descExtra,
    imgExtra,
    size = 'default',
    ...otherProps
  } = props;
  return (
    <div className={`${styles.item} ${className}`} {...otherProps}>
      <div className={styles.imageBox}>
        <img className={cn(styles.image, { [styles.large]: size === 'large' })} src={url} />
        {imgExtra}
      </div>
      <div className={styles.content}>
        {title && <div className={styles.title}>{title}</div>}
        {desc && <div className={styles.desc}>{desc}</div>}
        {descExtra && <div className={styles.desc}>{descExtra}</div>}
        {tag && <div className={styles.tag}>{tag}</div>}
        {time && (
          <div className={styles.time}>
            <MyIcon type="icon-shijian" />
            <span className={styles.timeText}>{time}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
