import dayjs from 'dayjs';
import { history } from 'umi';
import { message } from 'antd';

const ef = (res) => {};

export const getLocalStorage = (key) => {
  let currentValue = window.localStorage.getItem(key);
  if (currentValue) {
    currentValue = JSON.parse(currentValue);
    return currentValue;
  }
  return undefined;
};

export const setLocalStorage = (key, value) => {
  if (value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeStorage = (key) => {
  window.localStorage.removeItem(key);
};

export const searchHistoryDataSource = 'SearchHistory-Record';

export const localStorageKeys = {
  searchHistoryDataSource, // 历史搜索记录
};

// 保存搜索记录
export const setSearchRecord = (value) => {
  const againRecordArr = getLocalStorage(localStorageKeys.searchHistoryDataSource) || [];
  againRecordArr.unshift(value);
  const newRecord = [...new Set(againRecordArr)];
  if (newRecord.length > 5) {
    newRecord.pop();
  }
  setLocalStorage(localStorageKeys.searchHistoryDataSource, newRecord);
};

// 根据文件名称获取文件扩展名
export const getFileExtension = (fileName = '') => {
  const index = fileName.lastIndexOf('.');
  const extension = fileName.substring(index + 1);
  return extension || '';
};

export const formatDate = (date, format?) => dayjs(date).format(format || 'MM.DD HH:mm');

/** 提取富文本文字内容 */
export const getEditorText = (value) => {
  const div = document.createElement('div');
  div.innerHTML = value;
  return div.innerText.trim();
};

// 移除所有的HTML字符串
export const removeAllHTMLTag = (str) => typeof str === 'string' ? str.replace(/<[^>]+>/g, '') : str;

/**
 * 处理接口返回数据
 */
export const dealRes = (request, successMsg = '', errorMsg = '', callBack = ef) =>
  request
    .then((res) => {
      const { code, data, message: msg } = res;
      if (`${code}` === '200') {
        if (successMsg) {
          message.success(successMsg || '查询成功');
        }
        callBack(data);
        return data;
      }
      message.error(msg || errorMsg || '查询失败');
      return null;
    })
    .catch(() => {
      message.error(errorMsg || '查询失败');
      return null;
    });

export const prefixUUCImage = (src, defaultAvatarImg) => {
  if (!src) {
    return defaultAvatarImg;
  }
  const isOld = String(src).includes('http');
  return (isOld ? src : `/uuc/oss/download?url=${src}`) || defaultAvatarImg;
};

export const toDingtalkLogin = () => {
  const { pathname, search } = window.location || {};
  const redirectUrl = encodeURI(`${pathname}${search}`);
  history.replace(`/dd-login?redirectUrl=${redirectUrl}`);
};

/*
 * a / b 保留一位小数
 */
export const getPercent = (a, b) => {
  if (!a || !b) return 0;
  if (typeof a !== 'number' || typeof b !== 'number') return 0;
  return Math.min(100, Number(((a / b) * 100).toFixed(1)));
};

export const percentFormat = (percent, subffix = '%') => {
  let d = Number(percent);
  if (typeof d === 'number') {
    d = d.toFixed(d % 1 === 0 ? 0 : 1);
  } else {
    d = percent;
  }
  return `${d}${subffix}`;
};
