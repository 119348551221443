import React from 'react';
import { Button } from 'antd';
import styles from './FooterBar.less';

const FooterBar = ({ config, children }) => {
  const renderContent = () =>
    (config || []).map((i) => {
      if (!i) {
        return null;
      }
      const { type = 'default', text, render, onClick, ...rest } = i;
      if (render) {
        return render;
      }
      return (
        <Button shape="round" onClick={onClick} type={type} key={text} {...rest}>
          {text}
        </Button>
      );
    });

  return (
    <div className={styles.wrapper}>
      {children}
      {renderContent()}
    </div>
  );
};

export default FooterBar;
