import React, { FC, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { TablePaginationConfig, TableProps as AntTableProps } from 'antd/lib/table';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import renderColunms from './renderColunms';
import styles from './CommonTable.less';

export interface TableProps<RecordType extends Record<string, unknown> = any>
  extends AntTableProps<RecordType> {
  classname?: string;
  pagination?: TablePaginationConfig;
  onSelectChange?: (selectedRowKeys, selectedRows) => void;
  onDelete?: (selectedRowKeys) => void;
  type?: 'Single' | 'Multiple'; // 单选，多选表格，默认单选
  showOrderIndex?: boolean; //是否显示序号
}

const CommonTable: FC<TableProps> = (props) => {
  const {
    classname,
    rowKey,
    columns,
    pagination,
    onSelectChange,
    type,
    onDelete,
    showOrderIndex,
    ...otherProps
  } = props;

  const { total = 0, current, pageSize } = pagination || {};

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const col = renderColunms({ showOrderIndex, columns, current, pageSize });

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    if (onSelectChange) {
      onSelectChange(selectedRowKeys, selectedRows);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
    type: type === 'Multiple' ? 'checkbox' : 'radio',
  };

  // 批量删除
  const handleDelete = () => {
    Modal.confirm({
      title: '确认批量删除这些数据?',
      icon: <ExclamationCircleOutlined />,
      content: '删除后数据不能恢复',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        if (onDelete instanceof Function) onDelete(selectedRowKeys);
      },
    });
  };

  return (
    <div className={styles.root}>
      <Table
        className={`${styles.table} ${classname}`}
        bordered
        columns={col}
        rowKey={rowKey}
        rowSelection={type ? rowSelection : undefined}
        pagination={{
          showLessItems: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `共有 ${total} 条 `,
          ...pagination,
        }}
        {...otherProps}
      />
      {type === 'Multiple' && total > 0 && selectedRowKeys.length > 0 && (
        <div className={styles.bottom}>
          {onDelete instanceof Function && (
            <Button onClick={handleDelete} shape="round">
              批量删除
            </Button>
          )}
          <span className={styles.total}>
            当前已选
            <em>{selectedRowKeys.length}</em>项
          </span>
        </div>
      )}
    </div>
  );
};

CommonTable.defaultProps = {
  classname: '',
  rowKey: 'id',
  showDeleteBtn: true,
  showOrderIndex: false,
};

export default CommonTable;
