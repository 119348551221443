import React from 'react';
import styles from './OrderTitle.less';

const OrderTitle = (props) => {
  const { className, children, extra, ...otherProps } = props;
  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      <div>{children}</div>
      <div>{extra}</div>
    </div>
  );
};

const SubTitle = (props) => {
  const { children } = props;
  return <div className={styles.subTitle}>{children}</div>;
};

OrderTitle.SubTitle = SubTitle;

export default OrderTitle;
