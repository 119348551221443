// 10作废中 11已作废 , 20 待审核, 21 已审核 ,30开具中, 31已开具
export enum InvoiceStatus {
  作废中 = 10,
  已作废 = 11,
  待审核 = 20,
  已审核 = 21,
  开具中 = 30,
  已开具 = 31,
}

// 发票类型
export const InvoiceType = {
  PI: '纸质普票',
  PS: '纸质专票',
  E: '电子发票',
};
