import peixun from '@/assets/national-day/icon-01.png';
import kaoshi from '@/assets/national-day/icon-02.png';
import lianxi from '@/assets/national-day/icon-03.png';
import wenjuan from '@/assets/national-day/icon-04.png';
import kecheng from '@/assets/national-day/icon-05.png';
import baoming from '@/assets/national-day/icon-06.png';
import zhibo from '@/assets/national-day/icon-07.png';
import library from '@/assets/national-day/icon-08.png';
import homeBg from '@/assets/national-day/bg.png';
import logoImg from '@/assets/national-day/logo2.png';
import { ThemeType } from './types';

const Icons: Record<string, string> = {
  Train: peixun, // 培训
  Exam: kaoshi, // 考试
  Practice: lianxi, // 练习
  Questionnaire: wenjuan, // 问卷
  OpenClass: kecheng, // 公开课
  Register: baoming, // 报名
  Live: zhibo, // 直播
  Library: library, // 图书馆
};

const Background: Record<string, string | null | undefined> = {
  Home: homeBg,
  Logo: logoImg,
  Gradient: 'linear-gradient(0deg, #FCE2C4 0%, #FFF7EE 100%)',
};

const Color: Record<string, string> = {
  footerColor: ' rgba(255,255,255,0.65)',
};

const Theme: ThemeType = { icons: Icons, background: Background, color: Color };

export default Theme;
