import React from 'react';
import Descriptions from '@/components/Descriptions';
import OrderTitle from '../OrderTitle';
import { ChannelConst, ZGBM_GRJF } from '../../utils';
import { Tooltip } from 'antd';

export const payChannelMap = {
  [ChannelConst.ON_LINE]: '个人线上支付',
  [ChannelConst.OFF_LINE]: '线下支付',
};

//缴费类型
export const paymentTypeMap = {
  1: '个人报名-个人缴费',
  [ZGBM_GRJF]: '主管报名-个人缴费',
  3: '主管报名-公司代缴',
  4: '管理员报名-公司代缴费',
  5: '管理员报名-个人缴费',
};

const OrderInfo = (props) => {
  const {
    orderCode,
    orderCreateUserName,
    gmtCreate,
    gmtPay,
    gmtRefund,
    gmtStartRefund,
    gmtCancel,
    payWay,
    payType,
  } = props;
  const list = [
    { label: '订单编号', value: orderCode },
    { label: '订单发起人', value: orderCreateUserName },
    { label: '下单时间', value: gmtCreate },
    { label: '付款时间', value: gmtPay },
    { label: '退款时间', value: gmtRefund },
    { label: '申请退款时间', value: gmtStartRefund },
    { label: '取消时间', value: gmtCancel },
    { label: '支付渠道', value: payChannelMap[payWay] },
    { label: '缴费类型', value: paymentTypeMap[payType] },
  ];

  return (
    <>
      <OrderTitle.SubTitle>订单信息</OrderTitle.SubTitle>
      <Descriptions gutter={[28, 24]}>
        {list.map(
          (item) =>
            item.value && (
              <Descriptions.Item key={item.label} span={8} label={item.label}>
                <Tooltip title={item.value}>{item.value}</Tooltip>
              </Descriptions.Item>
            ),
        )}
      </Descriptions>
    </>
  );
};

export default OrderInfo;
