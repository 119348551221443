import React from 'react';
import { Pagination, Spin } from 'antd';
import LibraryCard from '@/components/LibraryCard';
import Empty from '@/components/Empty';
import useList from '@/hooks/useList';
import { getListApi } from '@/pages/person/my-collection/services';
import styles from './FileList.less';

export interface FileListProps extends React.HTMLAttributes<HTMLDivElement> {}

const FileList = (props: FileListProps) => {
  const { className = '', ...otherProps } = props;
  const [tableProps] = useList(getListApi, {
    defaultPageSize: 14,
  });
  const { loading, pagination, list } = tableProps;
  const { pageSize, pageNum, total, changePage } = pagination;
  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      {list.length ? (
        <div className={styles.body}>
          <Spin spinning={loading}>
            <div className={styles.listBox}>
              {list.map((item) => (
                <LibraryCard {...item} notInSearch key={item.fileId} />
              ))}
            </div>
          </Spin>
          <div className={styles.pagination}>
            <Pagination onChange={changePage} current={pageNum} total={total} pageSize={pageSize} />
          </div>
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default FileList;
