import React from 'react';
import { MicroAppWithMemoHistory } from 'umi';
import styles from './MyErrorPage.less';

const MyErrorPage = () => (
  <div className={styles.root}>
    <MicroAppWithMemoHistory name="考试中心" url="/user-exam/practice/errorquestions" />
  </div>
);

export default MyErrorPage;
