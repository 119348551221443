import React from 'react';
import styles from './ClassCard.less';
import classDefault from '@/assets/images/classDefault.png';
import { Progress } from 'antd';
import { history } from 'umi';

const ClassCard = (props) => {
  const { data } = props;
  const isStarting = data.studyDuration > 0;
  const isOpen = data.isOpen === 2;
  const handleToDetail = () => {
    history.push(`/pxgl/training-detail?classId=${data.classId}&isOpen=${data.isOpen}`);
  };
  return (
    <div className={styles.root} onClick={handleToDetail}>
      <div className={styles.left}>
        {isOpen && <div className={styles.isOpenClass}>公开课</div>}
        <img src={data.fileId ? `/train/file/show/${data.fileId}` : classDefault} alt="" />
      </div>
      <div className={styles.right}>
        <div className={styles.className}>{data.className}</div>
        <div className={styles.count}>
          <div className={styles.courseCount}>{data.countOfCourse}门课</div>
          <div className={styles.line}>|</div>
          <div className={styles.signupCount}>{data.countOfSignUp}人已报名</div>
        </div>
        <div className={styles.progress}>
          <span className={isStarting ? styles.progressText : styles.noStart}>
            {isStarting ? '学习进度' : '未开始'}
          </span>
          {isStarting && (
            <Progress percent={data.studyDuration} strokeColor="#59C014" size="small" />
          )}
        </div>
      </div>
    </div>
  );
};
export default ClassCard;
