import React from 'react';
import { Button, message as Message, Popconfirm } from 'antd';
import MyIcon from '@/components/MyIcon';
import usePromisifyModal from '@/hooks/usePromisifyModal';
import { dealRes, formatDate } from '@/utils';
import { history } from 'umi';
import ExamSignDetail from '@/pages/person/my-register/components/RegisterList/components/ExamSignDetail';
import dayjs from 'dayjs';
import RegisterDetail from '../RegisterDetail';
import RegisterModalForm from '../RegisterModalForm';
import { cancelExamSignApi, submitRegister } from '../../../../services';
import styles from './RegisterItem.less';

export interface RegisterItemProps extends React.HTMLAttributes<HTMLDivElement> {
  id: number | string;
  classId: number;
  className: string;
  gmtStart: string;
  gmtEnd: string;
  tenantName: string;
  dealStauts: number | boolean;
  registrationType: number;
  registrationStatus: number; // 0 待处理 1已处理 2考试
  handleRefresh: (boolean) => void;
  name?: string; // 考试名称
  startTime?: string; // 考试开始时间
  endTime?: string; // 考试结束时间
  typeName?: string; // 考试类型
  examineeStatus?: string; // 审核状态
  registerCancelSetting: number; // 取消报名设置(设置天数，-1：不可取消，其他为开考前多少天可取消)
  examStartTime: string; // 考试开始时间,用于判断是否可取消报名
  configs: { examStartTime: string }[]; // 考试配置项
  orderCode: string;
}

const dealItemStatusObj = {
  '1': {
    type: 'icon-yitongguo',
    text: '已通过',
    color: '#6DD400',
  },
  '2': {
    type: 'icon-yijujue',
    text: '已拒绝',
    color: '#E02020',
  },
  '3': {
    type: 'icon-yifangqi',
    text: '已放弃',
    color: '#C8C8C8',
  },
  '4': {
    type: 'icon-shenhezhong',
    text: '审批中',
    color: '#FF9919',
  },
  '5': {
    type: 'icon-yiwancheng',
    text: ' 已完成',
    color: '#536AF7',
  },
};

/**
 * 考试报名审批状态
 */
const ExamSignStatus = {
  '2': {
    type: 'icon-shenhezhong',
    text: '审核中',
    color: '#FF9919',
  },
  '3': {
    type: 'icon-yitongguo',
    text: '已通过',
    color: '#6DD400',
  },
  '4': {
    type: 'icon-yijujue',
    text: '已拒绝',
    color: '#E02020',
  },
  '5': {
    type: 'icon-daijiaofei',
    text: ' 待缴费',
    color: '#FF9919',
  },
  '6': {
    type: 'icon-yijiaofei',
    text: ' 已缴费',
    color: '#59C014',
  },
  '9': {
    type: 'icon-daijiaofei',
    text: ' 待缴费',
    color: '#FF9919',
  },
  '11': {
    type: 'icon-yiquxiao',
    text: ' 已取消',
    color: '#C8C8C8',
  },
  '12': {
    type: 'icon-tuikuanzhong',
    text: ' 退款中',
    color: '#5364FF',
  },
};

const RegisterItem = (props: RegisterItemProps) => {
  const {
    id,
    classId,
    className,
    gmtStart,
    gmtEnd,
    // tenantName,
    tenantName, // 培训组织名称
    name, // 考试名称
    startTime, // 考试开始时间
    endTime, // 考试结束时间
    typeName, // 考试类型
    examineeStatus = 1, // 考试的处理状态
    dealStauts, // 处理状态
    registrationType, // 报名类型
    registrationStatus, // 报名状态
    handleRefresh,
    registerCancelSetting, // 取消报名设置(设置天数，-1：不可取消，其他为开考前多少天可取消)
    configs,
    orderCode, // 订单编码,如果提交过订单,会生成订单编码
    ...otherProps
  } = props;
  const dealed = registrationStatus === 1;

  const modal = usePromisifyModal();
  // 拒绝报名
  const handleReject = () => {
    submitRegister({
      id,
      accept: 0,
      classId,
      userInfo: {},
    }).then((res) => {
      const { success } = res;
      if (success) {
        Message.success('拒绝成功');
        handleRefresh(false);
        return true;
      }
      Message.error('拒绝失败');
      return false;
    });
  };
  // 报名按钮点击方法
  const handleRegisterClick = () => {
    modal.show(
      <RegisterModalForm id={id} modal={modal} handleRefresh={handleRefresh} classId={classId} />,
    );
  };

  // 报名详情展示方法
  const handleShowDetail = () => {
    if (registrationStatus === 1) {
      const classInfo = {
        className,
        gmtStart,
        gmtEnd,
        tenantName,
      };
      modal.show(<RegisterDetail id={id} classId={classId} classInfo={classInfo} />);
    } else if (registrationStatus === 2) {
      modal.show(<ExamSignDetail id={id} />);
    }
  };

  // 待处理项渲染
  const waitItemRender = (registrationType) => {
    if (registrationType !== 3) {
      return (
        <>
          <Popconfirm
            title="确认拒绝此次报名吗？"
            onConfirm={() => {
              handleReject(id);
            }}
            okText="确认"
            cancelText="取消"
          >
            <Button shape="round">拒绝报名</Button>
          </Popconfirm>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              handleRegisterClick();
            }}
          >
            去报名
          </Button>
        </>
      );
    }
    return (
      <Button
        type="primary"
        shape="round"
        onClick={() => {
          handleRegisterClick();
        }}
      >
        填写报名表
      </Button>
    );
  };
  // 已处理项渲染
  const dealedItemRender = (dealStauts) => {
    const { color, type } = dealItemStatusObj[dealStauts] || {};
    return <MyIcon type={type} color={color} />;
  };

  // 取消报名,申请退费
  const handleCancelSign = (type) => {
    dealRes(
      cancelExamSignApi(id),
      type === 'cancel' ? '取消成功' : '申请提交成功',
      type === 'cancel' ? '取消失败' : '申请提交失败',
      () => {
        // 重新刷新列表
        handleRefresh(false);
      },
    );
  };

  // 考试缴费
  const handlePay = () => {
    // 跳转到订单页面支付
    let url = `/person/my-order/confirm?goodsId=${id}&from=exam`;
    if (orderCode) {
      url += `&orderCode=${orderCode}`;
    }
    // window.history.pushState({}, '', url);
    history.push(url);
  };

  // 报名项渲染
  const examItemRender = () => {
    switch (examineeStatus) {
      case 1:
        return null;
      case 2:
      case 4:
        return (
          <div className={styles.btnGroup}>
            <Button size="small" type="link" onClick={handleShowDetail}>
              查看详情
            </Button>
            <MyIcon
              type={ExamSignStatus[`${examineeStatus}`].type}
              color={ExamSignStatus[`${examineeStatus}`].color}
            />
          </div>
        );
      case 3: // 已通过
        if (
          registerCancelSetting > 0 &&
          dayjs().add(registerCancelSetting, 'd') < dayjs(configs[0]?.examStartTime)
        ) {
          // 如果在取消报名条件内(当前时间加上提前时间小于或等于第一场考试时间),则显示取消报名按钮
          return (
            <div className={styles.btnGroup}>
              <Popconfirm
                placement="top"
                title="您确认要取消该考试计划的报名吗?"
                onConfirm={() => handleCancelSign('cancel')}
              >
                <Button size="small" type="link">
                  取消报名
                </Button>
              </Popconfirm>
              <Button size="small" type="link" onClick={handleShowDetail}>
                查看详情
              </Button>
              <MyIcon
                type={ExamSignStatus[`${examineeStatus}`].type}
                color={ExamSignStatus[`${examineeStatus}`].color}
              />
            </div>
          );
        }
        return (
          <div className={styles.btnGroup}>
            <Button size="small" type="link" onClick={handleShowDetail}>
              查看详情
            </Button>
            <MyIcon
              type={ExamSignStatus[`${examineeStatus}`].type}
              color={ExamSignStatus[`${examineeStatus}`].color}
            />
          </div>
        );
      case 5: // 待缴费
      case 9: // 缴费中
        if (
          registerCancelSetting > 0 &&
          dayjs().add(registerCancelSetting, 'd') < dayjs(configs[0]?.examStartTime)
        ) {
          // 如果在取消报名条件内,则显示取消报名按钮
          return (
            <div className={styles.btnGroup}>
              <Popconfirm
                placement="top"
                title="您确认要取消该考试计划的报名吗?"
                onConfirm={() => handleCancelSign('cancel')}
              >
                <Button size="small" type="link">
                  取消报名
                </Button>
              </Popconfirm>
              <Button size="small" type="link" onClick={handlePay}>
                考试缴费
              </Button>
              <Button size="small" type="link" onClick={handleShowDetail}>
                查看详情
              </Button>
              <MyIcon
                type={ExamSignStatus[`${examineeStatus}`].type}
                color={ExamSignStatus[`${examineeStatus}`].color}
              />
            </div>
          );
        }
        return (
          <div className={styles.btnGroup}>
            <Button size="small" type="link" onClick={handlePay}>
              考试缴费
            </Button>
            <Button size="small" type="link" onClick={handleShowDetail}>
              查看详情
            </Button>
            <MyIcon
              type={ExamSignStatus[`${examineeStatus}`].type}
              color={ExamSignStatus[`${examineeStatus}`].color}
            />
          </div>
        );
      case 6: // 已缴费
        if (
          registerCancelSetting > 0 &&
          dayjs().add(registerCancelSetting, 'd') < dayjs(configs[0]?.examStartTime)
        ) {
          // 如果在取消报名条件内,则显示申请退费按钮
          return (
            <div className={styles.btnGroup}>
              <Popconfirm
                placement="top"
                title="您确认要申请退款并取消该考试计划的报名吗?"
                onConfirm={() => handleCancelSign('refund')}
              >
                <Button size="small" type="link">
                  申请退费
                </Button>
              </Popconfirm>
              <Button size="small" type="link" onClick={handleShowDetail}>
                查看详情
              </Button>
              <MyIcon
                type={ExamSignStatus[`${examineeStatus}`].type}
                color={ExamSignStatus[`${examineeStatus}`].color}
              />
            </div>
          );
        }
        return (
          <div className={styles.btnGroup}>
            <Button size="small" type="link" onClick={handleShowDetail}>
              查看详情
            </Button>
            <MyIcon
              type={ExamSignStatus[`${examineeStatus}`].type}
              color={ExamSignStatus[`${examineeStatus}`].color}
            />
          </div>
        );
      case 11: // 取消报名
      case 12: // 退款中
        return (
          <div className={styles.btnGroup}>
            <Button size="small" type="link" onClick={handleShowDetail}>
              查看详情
            </Button>
            <MyIcon
              type={ExamSignStatus[`${examineeStatus}`].type}
              color={ExamSignStatus[`${examineeStatus}`].color}
            />
          </div>
        );
      default:
        return null;
    }
  };
  // 操作区渲染
  const operaRender = (registrationStatus, registrationType, dealStauts) => {
    if (registrationStatus === 0) {
      return waitItemRender(registrationType);
    } else if (registrationStatus === 2) {
      // 我的考试报名
      return examItemRender();
    }
    return dealedItemRender(dealStauts);
  };

  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      <div className={styles.content}>
        <div className={styles.leftPart}>
          <div
            className={`${(dealed || registrationStatus === 2) && styles.pointer} ${styles.title}`}
            onClick={handleShowDetail}
          >
            {`${registrationStatus}` === '2' ? name : className}
          </div>
          {`${registrationStatus}` === '2' && (
            <>
              <div className={styles.center}>
                {`考试时间：${formatDate(startTime)}~${formatDate(endTime)}`}
              </div>
              <div className={styles.time}>{`考试类型：${typeName || ''}`}</div>
            </>
          )}
          {`${registrationStatus}` !== '2' && (
            <>
              <div className={styles.center}>
                <MyIcon type="icon-jigou" />
                {tenantName}
              </div>
              <div className={styles.time}>
                <MyIcon type="icon-shijian" />
                {`${gmtStart}开班 ~ ${gmtEnd} 结班`}
              </div>
            </>
          )}
        </div>
        <div className={styles.rightPart}>
          {operaRender(registrationStatus, registrationType, dealStauts)}
        </div>
      </div>
      {modal.instance}
    </div>
  );
};

export default RegisterItem;
