import { post, get } from '@/utils/fetch';

import { FetchResult } from '@/utils/defs';

export interface ConsultListProps {
  catalogId: number | undefined;
  pageNum: number;
  pageSize: number;
}

// 我的任务
export const getTaskList = () =>
  get<FetchResult>('/train/mobile/course/home/calendar', {
    timeStamp: Date.now(),
    limit: 2,
  });

/** 获取已发布banner */
export const getBannerList = () =>
  post('/information/base/advertising/mobile/listAdvertising', {
    pageNum: 1,
    pageSize: 99,
    statusArray: [2],
  });

/**
 * 获取资讯列表
 * @param data
 * @returns
 * catalogId - 栏目ID（类型）
 * pageNum - 分页页码
 * pageSize - 分页大小
 */
export const getConsultList = () =>
  post<FetchResult>('/information/newsMobile/app/page', {
    catalogIds: [1],
    pageNum: 1,
    pageSize: 10,
    statusList: [1],
  });

// 获取金牌讲师
export const getLecturerList = () =>
  post<FetchResult>('/lecturer/lecturer/page/all', { pageSize: 4 });

// 获取公开课
export const getOpenClassList = (data) =>
  post<FetchResult>('/train/mobile/class/home/page-public', {
    pageNum: 1,
    pageSize: 10,
    isOpen: 2,
  });

  // 获取商品列表
export const getGoodsList = (data) =>
get<FetchResult>('/cart/api/goodsManage/page', {
  page: 1,
  pageSize: 10,
  status: 1,
});
