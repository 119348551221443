import { post, get } from '@/utils/fetch';
import { FetchResult } from '@/utils/defs';

export const MessageType = {
  All: '', // 所有
  Notification: '1',
  Todo: '2',
};
// 获取培训消息列表
export const getTrainMessage = (params) => {
  const { type } = params;
  // 通知消息
  if (type === MessageType.Notification) return post<FetchResult>('/trainees/msg/page', params);
  // 待办消息
  return Promise.resolve({
    requestId: '6c4d36a52ab34f1db532ebeabc65423c',
    code: '200',
    message: 'success',
    success: true,
    meta: null,
    data: {
      totalCount: 0,
      pageNum: 1,
      pageSize: 10,
      list: [],
    },
  });
};

// 获取消息详情，读消息详情
export const readMessage = ({ id, readStatus }) =>
  get<FetchResult>('/trainees/msg/detail', { id, readStatus });

/** 读消息 */
// export const readMessage = ({ id }) => post<FetchResult>(`/train/msg/read/${id}`);
