import React, { useState, useEffect, useList } from 'react';
import { message, Avatar, Affix, Spin } from 'antd';
import { history, useSelector, useDispatch } from 'umi';
import { prefixUUCImage } from '@/utils';
import defaultAvatar from '@/assets/images/avatar.png';
import MyIcon from '@/components/MyIcon';
import {
  getAccountInfo,
  getTrainingListApi,
  getMessageListApi,
  getCollectionListApi,
  readMessage,
  getAllClassApi,
  getMyExamApi,
  fetchCertificateApi,
  getMySurveyListApi,
} from './services';

import styles from './MyCenterPage.less';

const MyCenterPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [myuserInfo, setMyUserInfo] = useState({});
  const [mytrainingList, setMytrainingList] = useState([]);
  const [mymessageList, setMymessageList] = useState([]);
  const [myfavorList, setMyfavorList] = useState([]);
  const [myuserInfoExtMap, setMyUserInfoExtMap] = useState({});
  const [classCounter, setClassCounter] = useState(0);
  const [examCounter, setExamCounter] = useState(0);
  const [certCounter, setCertCounter] = useState(0);
  const [questCounter, setQuestCounter] = useState(0);
  const [expanCounter, setExpanCounter] = useState(0);
  const fetchAccountInfo = async () => {
    const { data, errCode } = await getAccountInfo();
    if (String(errCode) === '0') {
      const { userInfo = {}, userInfoExtMap = {}, tenantUserExtInfo = {} } = data || {};
      setMyUserInfo(userInfo);
      setMyUserInfoExtMap(tenantUserExtInfo ?? { belongCompany: '云南省建设投资控股集团有限公司' });
    }
  };

  const fetchTrainingList = async () => {
    const { data, success } = await getTrainingListApi({ status: 1, pageSize: 10 });
    if (success) {
      const { list = [], totalCount = 0 } = data || {};
      setMytrainingList(list);
      setExpanCounter(totalCount);
    }
  };

  const fetchMessageList = async () => {
    const { data, success } = await getMessageListApi({ type: 1, pageSize: 7 });
    if (success) {
      const { list = [] } = data || {};
      setMymessageList(list);
    }
  };

  const fetchFavorList = async () => {
    const { data, success } = await getCollectionListApi({ pageSize: 8 });
    if (success) {
      const { list = [] } = data || {};
      setMyfavorList(list);
    }
  };

  const fetchAllClassList = async () => {
    const { data, success } = await getAllClassApi();
    if (success) {
      setClassCounter((data || []).length);
    }
  };

  const fetchMyExamList = async () => {
    const { data, success } = await getMyExamApi();
    if (success) {
      const { list = [], totalCount = 0 } = data || {};
      setExamCounter(totalCount);
    }
  };

  const fetchCertList = async () => {
    const { data, success } = await fetchCertificateApi();
    if (success) {
      const {
        electronic = [],
        professional = [],
        nonRegistration = [],
        other = [],
        registration = [],
        skill = [],
      } = data || {};
      setCertCounter(
        (electronic || []).length +
          (professional || []).length +
          (nonRegistration || []).length +
          (registration || []).length +
          (skill || []).length +
          (other || []).length,
      );
    }
  };

  const fetchQuestList = async () => {
    const { data, success } = await getMySurveyListApi({
      pageSize: 1,
      cooperativeType: 0,
      isCompleted: 0,
    });
    if (success) {
      const { list = [], totalCount = 0 } = data || {};
      setQuestCounter(totalCount);
    }
  };

  const handleTrainingDetail = (data) => {
    history.push(`/pxgl/training-detail?classId=${data.classId}&isOpen=${data.isOpen}`);
  };

  const handleCollectionDetail = (id, type) => {
    history.push({
      pathname: '/knowledge/file-detail',
      query: {
        id,
        type,
      },
    });
  };
  const handleMessageDetail = (item) => {
    console.log(item);
    const { id, readStatus, pcUrl } = item;
    if (readStatus === 0) {
      readMessage({ id, readStatus }).finally(() => {
        // refresh();
      });
    }
    if (pcUrl) {
      window.open(pcUrl, '_self');
    }
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([
      fetchAccountInfo(),
      fetchTrainingList(),
      fetchMessageList(),
      fetchFavorList(),
      fetchAllClassList(),
      fetchMyExamList(),
      fetchCertList(),
      fetchQuestList(),
    ]).finally(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    loadData();
  }, []);

  const {
    common: { userInfo, creditInfo },
  } = useSelector(({ common }) => ({ common }));
  const { userName, avatar } = userInfo || {};

  return (
    <Spin spinning={!!loading}>
      <div className={styles.root}>
        <div className={styles.top}>
          <div className={styles.left}>
            <div className={styles.head}>
              <div className={styles.imgouter}>
                <Avatar
                  size={80}
                  src={prefixUUCImage(avatar, defaultAvatar)}
                  className={styles.avatar}
                />
              </div>
            </div>
            <div className={styles.info}>
              <b>{userName}</b>
              <span>
                <MyIcon type="icon-baoming" size="12" />
                &ensp;
                {myuserInfo['phone']}
              </span>
              <span>
                <MyIcon type="icon-peixun1" size="12" />
                &ensp;
                {myuserInfoExtMap['belongCompany']}
              </span>
            </div>
          </div>
          <div className={styles.right}>
            <div
              className={styles.tjitem}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/person/my-class');
              }}
            >
              <MyIcon type="icon-banji1" size="25" />
              <span>参加的班级</span>
              <b>{classCounter}</b>
            </div>
            <div
              className={styles.tjitem}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/person/my-exam');
              }}
            >
              <MyIcon type="icon-kaoshiguanli" size="25" />
              <span>参加的考试</span>
              <b>{examCounter}</b>
            </div>
            <div
              className={styles.tjitem}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/person/my-certificate');
              }}
            >
              <MyIcon type="icon-wodezhengshu" size="25" />
              <span>取得的证书</span>
              <b>{certCounter}</b>
            </div>
            <div
              className={styles.tjitem}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/person/my-survey');
              }}
            >
              <MyIcon type="icon-wodewenjuan" size="25" />
              <span>参与的问卷</span>
              <b>{questCounter}</b>
            </div>
            <div
              className={styles.tjitem}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/person/my-training');
              }}
            >
              <MyIcon type="icon-peixun1" size="25" />
              <span>进行的培训</span>
              <b>{expanCounter}</b>
            </div>
          </div>
        </div>
        <div className={styles.myjoininfos}>
          <div className={styles.mytask}>
            <ul className={styles.layuiTabTitle}>
              <li className={styles.mytaskTitle}>我的培训</li>
            </ul>
            <ul className={styles.list}>
              {mytrainingList.map((item) => (
                <li>
                  <a
                    onClick={() => {
                      handleTrainingDetail(item);
                    }}
                  >
                    {item.className}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.mynotice}>
            <div className={styles.title}>通知信息</div>
            <ul className={styles.list}>
              {mymessageList.map((item) => (
                <li>
                  <a
                    onClick={() => {
                      handleMessageDetail(item);
                    }}
                  >
                    {item.title}
                    <span>{item.gmtCreate}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.myhistory}>
            <div className={styles.title}>我的收藏</div>
            <ul className={styles.list}>
              {myfavorList.map((item) => (
                <li>
                  <a
                    onClick={() => {
                      handleCollectionDetail(item.id, item.type);
                    }}
                  >
                    {item.fileName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <ul className={styles.layuiTabTitle}>
            <li className={styles.mytaskTitle}>我的资源</li>
          </ul>
        </div>
      </div>
    </Spin>
  );
};

export default MyCenterPage;
