import { userInfo, getClassRank, getUserCredit } from '@/services/common';
import { getTrainMessage, MessageType } from '@/pages/my-message/services';
import { getCartCount } from '@/services/cart';

export interface UserInfoProps {
  id: number;
  guid: string;
  userCode: string;
  userName: string;
  firstLanding?: number;
  passwordRemaining?: number;
  avatar?: string;
  messageUnReadCount?: number;
  todoUnReadCount?: number;
  cartCount: number; // 购物车数量
}

const initData = {
  userInfo: {}, // 用户信息
  keyword: '', // 搜索关键词
  hasLoginStatus: false, // 是否能获取登录状态
  classInfo: {}, // 用户班级、学分等信息
  messageUnReadCount: 0, // 消息未读 - 通知
  todoUnReadCount: 0, // 消息未读 - 待办
  cartCount: 0, // 购物车数量
};

export default {
  namespace: 'common',
  state: {
    ...initData,
  },
  effects: {
    *getUserInfo(_, { call, put }) {
      try {
        const { data: userData, success } = yield call(userInfo);
        if (success) {
          yield put({
            type: 'update',
            userInfo: userData?.baseInfo || {},
          });
          yield put({ type: 'getUnReadCount' });
        }
      } finally {
        yield put({
          type: 'update',
          hasLoginStatus: true,
        });
      }
    },
    *getClassCreditInfo(_, { call, all, put }) {
      const { classRes, creditRes } = yield all({
        classRes: yield call(getClassRank),
        creditRes: yield call(getUserCredit),
      });
      if (classRes?.success) {
        yield put({
          type: 'update',
          classInfo: classRes?.data || {},
        });
      }
      if (creditRes?.success) {
        yield put({
          type: 'update',
          creditInfo: creditRes?.data || {},
        });
      }
    },
    *getUnReadCount(_, { call, put, select }) {
      const { userInfo } = yield select((x) => x.common);
      const isLogin = userInfo?.userName;
      const payload = {
        messageUnReadCount: 0,
        todoUnReadCount: 0,
      };
      if (isLogin) {
        const rets = yield call(() =>
          Promise.allSettled([
            getTrainMessage({ pageNum: 1, pageSize: 1, isRead: 0, type: MessageType.Notification }),
            getTrainMessage({ pageNum: 1, pageSize: 1, isRead: 0, type: MessageType.Todo }),
          ]),
        );
        if (rets[0].status === 'fulfilled') {
          payload.messageUnReadCount += rets[0].value?.data?.totalCount;
        }
        if (rets[1].status === 'fulfilled') {
          payload.todoUnReadCount += rets[1].value?.data?.totalCount;
        }
      }
      yield put({ type: 'update', ...payload });
    },

    *getCartCount(_, { call, put }) {
      const { data: cartData, success } = yield call(getCartCount);
      if (success) {
        yield put({
          type: 'update',
          cartCount: cartData?.list?.length || 0,
        });
        yield put({ type: 'getCartCount' });
      }
    },
  },
  reducers: {
    update: (state, { type, ...rest }) => ({
      ...state,
      ...rest,
    }),
    clearData: () => ({ ...initData }),
  },
};
