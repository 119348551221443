import React, { useState, useEffect } from 'react';
import { connect } from 'umi';
import { Pagination, Spin } from 'antd';
import Empty from '@/components/Empty';
import { getExamListApi, getRegisterListApi } from '../../services';
import RegisterItem from './components/RegisterItem';
import styles from './RegisterList.less';

export interface RegisterListProps extends React.HTMLAttributes<HTMLDivElement> {
  className: string;
  userCode: string | number;
  registrationStatus: number; // 注册项状态
}

const RegisterList = (props: RegisterListProps) => {
  const { className = '', userCode, registrationStatus, ...otherProps } = props;
  // 待处理报名项列表
  const [registerList, setregisterList] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  // 获取列表信息方法
  const getRegisterData = () => {
    const payload = {
      pageNum,
      pageSize,
      tcRegistrationRecordDTO: {
        registrationStatus,
      },
    };
    setLoading(true);
    const request =
      `${registrationStatus}` === '2'
        ? getExamListApi({
          pageNum,
          pageSize,
        })
        : getRegisterListApi(payload);
    request
      .then((res) => {
        if (res?.success) {
          const {
            data: { list, totalCount },
          } = res;
          setTotal(totalCount);
          setregisterList(list || []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (refresh === 0) return;
    getRegisterData();
  }, [refresh]);

  // 刷新列表
  const handleRefresh = (reset) => {
    if (reset) {
      setPageSize(10);
      setPageNum(1);
    }
    setRefresh((re) => re + 1);
  };

  // 当注册状态切换到自身的时候，重置列表的页数与尺寸
  useEffect(() => {
    handleRefresh(true);
  }, [registrationStatus]);

  const handlePageChange = (pageNum, pageSize) => {
    setPageSize(pageSize);
    setPageNum(pageNum);
    setRefresh((re) => re + 1);
  };

  // 报名项渲染方法
  const rigisterItemRender = (list) =>
    list.map((item, index) => (
      <RegisterItem
        key={item.id}
        className={styles.item}
        registrationStatus={registrationStatus}
        handleRefresh={handleRefresh}
        {...item}
      />
    ));

  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      <Spin spinning={loading}>
        {registerList.length > 0 ? (
          <>
            {rigisterItemRender(registerList)}
            <div className={styles.footer}>
              <Pagination
                showQuickJumper
                showSizeChanger
                current={pageNum}
                pageSize={pageSize}
                total={total}
                onChange={handlePageChange}
              />
            </div>
          </>
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default connect((common) => ({
  id: common.id,
  guid: common.guid,
  userCode: common.userCode,
}))(RegisterList);
