import { post, get } from '@/utils/fetch';


/** 获取商品详情 */
export const getGoodsDetailApi = id => get(`/cart/api/goodsManage/detail?id=${id}`);

export const getGoodsListApi = async (data) => {
  if (data?.pageNum) data.page = data.pageNum;
  const resp = await get('/cart/api/goodsManage/page', data);
  const list = {
    ...resp,
    data: {
      ...(resp.data || {}),
      list: resp.data?.records?.map((i) => ({
        ...i,
        id: String(i.id),
      })),
      totalCount: resp.data?.total,
    },
  };
  return list;
};

// 获取订单列表
export const getOrderListApi = (data) => post('/trainees/order/user/page', data);

export const getGoodsDetailByIdApi = id => get(`/cart/api/goodsManage/detail?id=${id}`);

/* export const getListApi = async (data) => {
  try {
    const response = await post('/trainees/order/user/page', data);
    const { code, data: responseData } = response || {};
    const { list = [] } = responseData || {};
    const filteredList = [];
    if (`${code}` === '200') {
      filteredList.push(...list.filter(item =>
        item.originalChannel === 6 && item.orderStatus === 2 || item.orderStatus === 4));

      const goodsList = await Promise.all(
        filteredList.flatMap(item => {
          const goodsRemark = JSON.parse(item.goodsRemark) || {};
          return (goodsRemark.list || []).map(async goods => {
            const goodsItem = await getGoodsDetailApi(goods.goodsId);
            return goodsItem.data;
          });
        })
      );

      return {
        ...response,
        data: {
          list: filteredList,
        },
      };
    }

    return response;
  } catch (error) {
    console.error('Error fetching list:', error);
    throw error; // 可以根据实际情况决定是否抛出或返回错误信息
  }
}; */
