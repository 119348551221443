import { useState, useEffect } from 'react';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import useDeepUpdateEffect from './useDeepUpdateEffect';

/**
 * @description 自动处理列表查询
 */

interface Result {
  success: boolean;
  data: {
    totalCount: number;
    list: Array<unknown>;
  };
}

interface OptionsProps {
  form?: FormInstance<unknown>;
  defaultPageSize?: number;
  queryParams?: Record<string, unknown>;
}

const useList = (
  request: (params?: unknown) => Promise<Result>,
  { form, defaultPageSize = 10, queryParams }: OptionsProps,
): any => {
  const [list, setList] = useState<Array<unknown>>([]);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = (params?: unknown) => {
    setLoading(true);
    request({
      pageNum,
      pageSize,
      ...queryParams,
      ...params,
    })
      .then((res) => {
        const { success, data } = res;
        if (success) {
          const { list, totalCount } = data || {};
          setList(list || []);
          setTotal(totalCount || 0);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reset = () => {
    if (form) {
      form.resetFields();
    }
    setPageNum(1);
    getData({ pageNum: 1, pageSize });
  };

  useEffect(() => {
    if (form) {
      const formData = form?.getFieldsValue() || {};
      getData({ ...formData, pageNum, pageSize });
    } else {
      getData({ pageNum, pageSize });
    }
  }, []);

  useDeepUpdateEffect(() => {
    reset();
  }, [queryParams]);

  const changePage = (pageNum, pageSize) => {
    setPageNum(pageNum);
    setPageSize(pageSize);

    const formData = form?.getFieldsValue() || {};
    getData({ ...formData, pageNum, pageSize });
  };

  const submit = () => {
    const formData = form?.getFieldsValue() || {};
    setPageNum(1);
    getData({ ...formData, pageNum: 1, pageSize });
  };

  const refresh = (_pageNum) => {
    const current = _pageNum ?? pageNum;
    const formData = form?.getFieldsValue() || {};
    setPageNum(current);
    getData({ ...formData, pageNum: current, pageSize });
  };

  const search = { submit, reset };

  const pagination = { total, pageNum, pageSize, changePage };

  const listProps = { loading, pagination, list };

  return [listProps, search, refresh];
};

export default useList;
