import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import Empty from '@/components/Empty';

interface Result {
  totalCount: number;
  list?: Array<unknown>;
}

export interface LoadMoreProps extends React.HTMLAttributes<HTMLDivElement> {
  children: (item) => React.ReactNode;
  onLoadMore: (pageNum: number) => void;
  data: Result;
}

// TODO:自动请求

const LoadMore = (props: LoadMoreProps) => {
  const { data, children, onLoadMore } = props;

  const [, setPageNum] = useState<number>(1);
  const [disabled, setDisabled] = useState<boolean>(false);

  const { list, totalCount } = data || {};

  useEffect(() => {
    if (Array.isArray(list) && list.length === totalCount) {
      setDisabled(true);
    }
  }, [list]);

  const handleMore = () => {
    setPageNum((prev) => {
      const next = prev + 1;
      onLoadMore(next);
      return next;
    });
  };

  return (
    <>
      {list?.length ? list.map(children) : <Empty style={{ margin: '188px auto' }} />}
      {totalCount > 0 && (
        <Button
          style={{ marginTop: '12px' }}
          size="large"
          shape="round"
          block
          onClick={handleMore}
          disabled={disabled}
        >
          {disabled ? '已加载全部' : '查看更多'}
        </Button>
      )}
    </>
  );
};

LoadMore.defaultProps = {
  children: () => {},
  onLoadMore: () => {},
};

export default LoadMore;
