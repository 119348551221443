import { feiZhuceDetail, qitaDetail, zhichengDetail, zhiyeDetail, zhuceDetail } from '../services';
import { cer1Level, cer2Kinds } from '../constant';
import { ConfigType } from '../typing.d';

const zhuceConfig: ConfigType[] = [
  {
    label: '证书名称',
    key: 'certificateName',
  },
  {
    label: '注册编号',
    key: 'registrationNumber',
  },
  {
    label: '级别',
    key: 'certificateLevel',
    format: (val) => {
      if (val) {
        const { text } = cer1Level.find((item) => item.value === val) ?? {};
        return text;
      }
      return '';
    },
  },
  {
    label: '专业名称',
    key: 'majorName',
  },
  {
    label: '注册单位',
    key: 'registrationDepartment',
  },
  {
    label: '发证单位',
    key: 'certificateReleaseDepartment',
  },
  {
    label: '注册日期',
    key: 'registrationDate',
    format: (val) => (val ? val.slice(0, 10) : '-'),
  },
  {
    label: '有效期',
    key: 'effectiveDate',
    format: (val) => (val ? val.slice(0, 10) : '-'),
  },
  {
    label: '是否增项',
    key: 'isAddItem',
    format: (val) => {
      switch (Number(val)) {
        case 0:
          return '否';
        case 1:
          return '是';
        default:
          return '';
      }
    },
  },
  {
    label: '是否本岗位',
    key: 'isOriginalPosition',
    format: (val) => {
      switch (Number(val)) {
        case 0:
          return '否';
        case 1:
          return '是';
        default:
          return '';
      }
    },
  },
  {
    label: '备注',
    key: 'remark',
  },
];

const feiZhuceConfig: ConfigType[] = [
  {
    label: '专业工种名称',
    key: 'majorName',
  },
  {
    label: '证书编号',
    key: 'registrationNumber',
  },
  {
    label: '工作单位',
    key: 'workDepartment',
  },
  {
    label: '发证日期',
    key: 'certificateReleaseDate',
    format: (val) => (val ? val.slice(0, 10) : '-'),
  },
  {
    label: '有效期',
    key: 'effectiveDate',
    format: (val) => (val ? val.slice(0, 10) : '-'),
  },
  {
    label: '发证单位',
    key: 'certificateReleaseDepartment',
  },
  {
    label: '证书类别',
    key: 'certificateType',
    format: (val) => {
      if (val) {
        const { text } = cer2Kinds.find((item) => item.value === val) ?? {};
        return text;
      }
      return '';
    },
  },
  {
    label: '备注',
    key: 'remark',
  },
];

const zhichengConfig: ConfigType[] = [
  {
    label: '资格名称',
    key: 'qualifiedName',
  },
  {
    label: '专业名称',
    key: 'majorName',
  },
  {
    label: '工作单位',
    key: 'workDepartment',
  },
  {
    label: '评审组织',
    key: 'accreditationOrganization',
  },
  {
    label: '资格认定时间',
    key: 'accreditationTime',
    format: (val) => (val ? val.slice(0, 10) : '-'),
  },
  {
    label: '是否最高职称',
    key: 'isHighestTitle',
    format: (val) => {
      switch (Number(val)) {
        case 0:
          return '否';
        case 1:
          return '是';
        default:
          return '';
      }
    },
  },
  {
    label: '备注',
    key: 'remark',
  },
];

const zhiyeConfig: ConfigType[] = [
  {
    label: '职业名称',
    key: 'professionalName',
  },
  {
    label: '职业技能等级',
    key: 'occupationalSkillLevel',
  },
  {
    label: '发证单位',
    key: 'certificateReleaseDepartment',
  },
  {
    label: '发证日期',
    key: 'certificateReleaseDate',
    format: (val) => (val ? val.slice(0, 10) : '-'),
  },
  {
    label: '备注',
    key: 'remark',
  },
];

const qitaConfig: ConfigType[] = [
  {
    label: '工作单位',
    key: 'workDepartment',
  },
  {
    label: '培训时间',
    key: 'trainingTimeStart',
    format: (val, data) => {
      const start = data?.trainingTimeStart;
      const end = data?.trainingTimeEnd;
      return start && end ? `${start.split(' ')[0]}~${end.split(' ')[0]}` : '-';
    },
  },
  {
    label: '培训内容',
    key: 'trainingContent',
  },
  {
    label: '发证日期',
    key: 'certificateReleaseDate',
    format: (val) => (val ? val.slice(0, 10) : '-'),
  },
  {
    label: '发证单位',
    key: 'certificateReleaseDepartment',
  },
  {
    label: '备注',
    key: 'remark',
  },
];
interface MapProps {
  config: Array<ConfigType>;
  detailApi: (id?: unknown) => Promise<unknown>;
}
export const formItemsMap: Record<string, MapProps> = {
  '0': {
    config: zhuceConfig,
    detailApi: zhuceDetail,
  },
  '1': {
    config: feiZhuceConfig,
    detailApi: feiZhuceDetail,
  },
  '2': {
    config: zhichengConfig,
    detailApi: zhichengDetail,
  },
  '3': {
    config: zhiyeConfig,
    detailApi: zhiyeDetail,
  },
  '4': {
    config: qitaConfig,
    detailApi: qitaDetail,
  },
};
