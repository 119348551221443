import React, { useState, useReducer } from 'react';
import { Row, Col, List, Spin } from 'antd';
import { useSelector } from 'umi';
import { useSet, useRequest, useUpdateEffect } from 'ahooks';
import Empty from '@/components/Empty';
import Container from '@/components/Container';
import SearchHistoryBox from '@/components/SearchHistoryBox';
import Tabs, { TabPaneProps } from '@/components/Tabs';
import TrainCard from '@/components/TrainCard';
import LibraryCard from '@/components/LibraryCard';
import LecturerCard from '@/components/LecturerCard';
import QuestCard from './components/QuestCard';
import LoadMore from '@/components/LoadMore';
import { reducer, Card, ZxCard } from './constants';
import { allSerach, typeSearch, querySerachCount } from './services';

import styles from './SearchPage.less';

const SearchPage = () => {
  const [allSearchTypeData, dispatch] = useReducer(reducer, {});
  const [activeKey, setActiveKey] = useState<string>('0');
  const [set, { add, reset }] = useSet<string>([]);

  const {
    common: { keyword },
  } = useSelector(({ common }) => ({ common }));

  useUpdateEffect(() => {
    setActiveKey('0');
    reset();
    dispatch({ type: 'clear' });
  }, [keyword]);

  const { data: typeData } = useRequest(() => querySerachCount(keyword), {
    refreshDeps: [keyword],
  });

  const { data: allSearchRes, loading: allLoading } = useRequest(() => allSerach(keyword), {
    refreshDeps: [keyword],
  });

  const { data: allSearchData } = allSearchRes || {};

  const defaultParams = {
    pageNum: 1,
    pageSize: 20,
    searchKeyword: keyword,
    searchType: activeKey,
  };

  const { run, loading } = useRequest(typeSearch, {
    manual: true,
    onSuccess: (res) => {
      const { data } = res || {};
      dispatch({ data, activeKey });
    },
  });

  const fetch = (params) => {
    run({ ...defaultParams, ...params });
  };

  useUpdateEffect(() => {
    if (![...set].includes(activeKey) && activeKey !== '0') {
      add(activeKey);
      fetch({ searchType: activeKey });
    }
  }, [activeKey]);

  const handleChange = (key: string) => {
    setActiveKey(key);
  };

  const renderAllTypeContent = ({ type, searchDTOList }) => {
    if (searchDTOList?.length > 0) {
      const allTypeMap = {
        '1': (
          <Card title="培训" onClick={() => setActiveKey(String(type))} key={type}>
            <Row>
              {searchDTOList.map((item) => (
                <Col span={12} key={item.id}>
                  <TrainCard id={item?.id} {...item?.trainingClassSearchDTO} />
                </Col>
              ))}
            </Row>
          </Card>
        ),
        '2': (
          <Card title="公开课" onClick={() => setActiveKey(String(type))} key={type}>
            <Row>
              {searchDTOList.map((item) => (
                <Col span={12} key={item.id}>
                  <TrainCard type="class" id={item?.id} {...item?.trainingClassSearchDTO} />
                </Col>
              ))}
            </Row>
          </Card>
        ),
        '3': (
          <Card title="图书馆" onClick={() => setActiveKey(String(type))} key={type}>
            <List
              dataSource={searchDTOList}
              renderItem={(item) => (
                <List.Item key={item?.id}>
                  <LibraryCard id={item?.id} {...item?.librarySearchDTO} />
                </List.Item>
              )}
            />
          </Card>
        ),
        '4': (
          <Card title="资讯" onClick={() => setActiveKey(String(type))} key={type}>
            <Row>
              {searchDTOList.map((item) => (
                <ZxCard key={item.id} {...item} />
              ))}
            </Row>
          </Card>
        ),
        '5': (
          <Card title="讲师" onClick={() => setActiveKey(String(type))} key={type}>
            <Row>
              {searchDTOList.map((item) => (
                <Col span={12} key={item.id}>
                  <LecturerCard id={item.id} {...item.lectureSearchDTO} />
                </Col>
              ))}
            </Row>
          </Card>
        ),
        '6': (
          <Card title="问卷" onClick={() => setActiveKey(String(type))} key={type}>
            <List
              dataSource={searchDTOList}
              renderItem={(item) => (
                <List.Item key={item?.id}>
                  <QuestCard id={item?.id} {...item?.questionnaireSearchDTO} />
                </List.Item>
              )}
            />
          </Card>
        ),
      };
      return allTypeMap[type];
    }
    return null;
  };

  const renderCount = (key) => {
    const count = typeData?.[key] || '';
    return ` ${count > 100 ? '99+' : count}`;
  };

  const tabPanes: Array<TabPaneProps> = [
    {
      key: '0',
      tab: `全部${renderCount('all')}`,
      children: (
        <Spin spinning={allLoading}>
          {allSearchData?.length ? (
            allSearchData.map((item) => renderAllTypeContent({ ...item }))
          ) : (
            <Container style={{ width: '100%' }}>
              <Empty style={{ margin: '188px auto' }} />
            </Container>
          )}
        </Spin>
      ),
    },
    {
      key: '1',
      tab: `培训${renderCount('training_class')}`,
      children: (
        <Row>
          <LoadMore data={allSearchTypeData['1']} onLoadMore={(pageNum) => fetch({ pageNum })}>
            {({ id, trainingClassSearchDTO }) => (
              <Col span={12} key={id}>
                <TrainCard id={id} {...trainingClassSearchDTO} />
              </Col>
            )}
          </LoadMore>
        </Row>
      ),
    },
    {
      key: '2',
      tab: `公开课${renderCount('public_class')}`,
      children: (
        <Row>
          <LoadMore data={allSearchTypeData['2']} onLoadMore={(pageNum) => fetch({ pageNum })}>
            {({ id, trainingClassSearchDTO }) => (
              <Col span={12} key={id}>
                <TrainCard id={id} {...trainingClassSearchDTO} />
              </Col>
            )}
          </LoadMore>
        </Row>
      ),
    },
    {
      key: '3',
      tab: `图书馆${renderCount('library')}`,
      children: (
        <List>
          <LoadMore data={allSearchTypeData['3']} onLoadMore={(pageNum) => fetch({ pageNum })}>
            {({ id, librarySearchDTO }) => (
              <List.Item key={id}>
                <LibraryCard id={id} {...librarySearchDTO} />
              </List.Item>
            )}
          </LoadMore>
        </List>
      ),
    },
    {
      key: '4',
      tab: `资讯${renderCount('information')}`,
      children: (
        <Row>
          <LoadMore data={allSearchTypeData['4']} onLoadMore={(pageNum) => fetch({ pageNum })}>
            {(item) => <ZxCard key={item.id} {...item} />}
          </LoadMore>
        </Row>
      ),
    },
    {
      key: '5',
      tab: `讲师${renderCount('lecturer')}`,
      children: (
        <Row>
          <LoadMore data={allSearchTypeData['5']} onLoadMore={(pageNum) => fetch({ pageNum })}>
            {({ id, lectureSearchDTO }) => (
              <Col span={12} key={id}>
                <LecturerCard id={id} {...lectureSearchDTO} />
              </Col>
            )}
          </LoadMore>
        </Row>
      ),
    },
    {
      key: '6',
      tab: `问卷${renderCount('questionnaire')}`,
      children: (
        <List>
          <LoadMore data={allSearchTypeData['6']} onLoadMore={(pageNum) => fetch({ pageNum })}>
            {({ id, questionnaireSearchDTO }) => (
              <List.Item key={id}>
                <QuestCard id={id} {...questionnaireSearchDTO} />
              </List.Item>
            )}
          </LoadMore>
        </List>
      ),
    },
  ];

  return (
    <div className={`${styles.root}`}>
      <div className={styles.left}>
        <Spin spinning={loading}>
          <Tabs
            className={styles.content}
            headerClassName={styles.header}
            tabPanes={tabPanes}
            activeKey={activeKey}
            onChange={handleChange}
          />
        </Spin>
      </div>
      <div className={styles.right}>
        <SearchHistoryBox className={styles.affix} />
      </div>
    </div>
  );
};

export default SearchPage;
