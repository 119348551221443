import React from 'react';
import { Button, Form, Input, Space, Row, Col } from 'antd';
import cn from 'classnames';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import DatePicker from '@/components/DatePicker';
import AutoSelect from '@/components/AutoSelect';
import styles from './SearchForm.less';

const { RangePicker } = DatePicker;

export interface SearchFormProps {
  form: FormInstance;
  searchType?: 'advance' | 'simple'; // 搜索模式 , 分为 advance、simple两种模式 --默认为advance模式,  simple模式表示简易搜索,不需要搜索重置按钮
  onSubmit?: () => void;
  onReset?: () => void;
  formItems: Array<unknown>;
}

const renderFromItem = (formItems, searchType, onSubmit) =>
  formItems?.map((item, index) => {
    const { label, type, name, rules, Custom, ...otherProps } = item;
    let formItemChild: React.ReactNode;
    switch (type) {
      case 'Input': {
        formItemChild =
          searchType === 'simple' ? (
            <Input.Search allowClear {...otherProps} onSearch={onSubmit} />
          ) : (
            <Input allowClear placeholder="请输入" {...otherProps} onPressEnter={onSubmit} />
          );
        break;
      }
      case 'Select':
        formItemChild = <AutoSelect {...otherProps} />;
        break;
      case 'DatePicker':
        formItemChild = <DatePicker style={{ width: '100%' }} showTime {...otherProps} />;
        break;
      case 'RangePicker':
        formItemChild = <RangePicker style={{ width: '100%' }} {...otherProps} />;
        break;
      case 'Custom':
        formItemChild = Custom ? <Custom {...otherProps} onPressEnter={onSubmit} /> : null;
        break;
      default:
    }
    return (
      <Col span={8} key={name}>
        <Form.Item
          rules={rules}
          name={name}
          label={label}
          className={cn({ [styles.formItems]: formItems.length < 3 || (index + 1) % 3 > 0 })}
        >
          {formItemChild}
        </Form.Item>
      </Col>
    );
  });

const SearchForm = (props: SearchFormProps) => {
  const { form, onSubmit, onReset, formItems, searchType, ...formProps } = props;

  return formItems?.length > 0 ? (
    <Form form={form} className={styles.content} colon={false} labelAlign="right" {...formProps}>
      <Row gutter={[24, 0]}>
        {renderFromItem(formItems, searchType, onSubmit)}
        {searchType === 'advance' && (
          <Space className={styles.buttongGroup}>
            {onReset instanceof Function && (
              <Button shape="round" onClick={onReset}>
                重置
              </Button>
            )}
            {onSubmit instanceof Function && (
              <Button type="primary" shape="round" onClick={onSubmit}>
                查询
              </Button>
            )}
          </Space>
        )}
      </Row>
    </Form>
  ) : null;
};

SearchForm.defaultProps = {
  searchType: 'advance',
};

export default SearchForm;
