import React from 'react';
import Tabs, { TabPaneProps } from '@/components/Tabs';
import { MicroAppWithMemoHistory } from '@@/plugin-qiankun/MicroAppWithMemoHistory';
import LayoutTitle from '../components/LayoutTitle';
import FileList from './components/FileList';

const MyCollectionPage = () => {
  const tabPanes: Array<TabPaneProps> = [
    {
      key: '0',
      tab: '文件',
      children: <FileList />,
    },
    {
      key: '1',
      tab: '试题',
      children: (
        <MicroAppWithMemoHistory name="考试中心" url="/user-exam/practice/question-store" />
      ),
    },
  ];

  return <Tabs extra={<LayoutTitle />} tabPanes={tabPanes} defaultActiveKey="0" />;
};

export default MyCollectionPage;
