import React from 'react';
import { history, useSelector } from 'umi';
import { Row, Col, Spin } from 'antd';
import Empty from '@/components/Empty';
import { useRequest } from 'ahooks';
import Theme from '@/themes/theme';
import Container from '@/components/Container';
import InfoCard from '@/components/InfoCard';
import { getConsultList } from '../../services';
import styles from './InfoList.less';

export interface InfoListProps extends React.HTMLAttributes<HTMLDivElement> {}

const InfoList = (props: InfoListProps) => {
  const { data: requestData, loading } = useRequest(getConsultList);
  const { data } = requestData || {};
  const { list } = data || {};


  const {
    common: { userInfo },
  } = useSelector(({ common }) => ({ common }));
  const { userName } = userInfo || {};

  if (!userName) {
    return null;
  }

  return (
    <Container
      className={styles.root}
      title="热门资讯"
      extra={<span onClick={() => history.push('/info/info-center')}>查看更多</span>}
      style={{ backgroundImage: Theme.background.Gradient ?? 'unset' }}
    >
      <Spin spinning={loading}>
        <Row gutter={16}>
          {list?.length > 0 ? (
            list.map((item) => (
              <Col span={12} key={item.newsId}>
                <InfoCard
                  fileKey={item.coverImage}
                  title={item.newsTitle}
                  tag={item.catalogName}
                  time={item.gmtPublish}
                  {...item}
                />
              </Col>
            ))
          ) : (
            <Empty style={{ margin: '136px auto' }} />
          )}
        </Row>
      </Spin>
    </Container>
  );
};

export default InfoList;
