import React from 'react';
import { TextContent } from '@/pages/person/my-credit/components/CreditDesc/constant';
import { List } from 'antd';
import { CreditRuleProps } from '@/pages/person/my-credit/services';
import styles from './CreditDesc.less';

export interface CreditDescProps extends React.HTMLAttributes<HTMLDivElement> {
  data: CreditRuleProps[];
}

const CreditDesc = (props: CreditDescProps) => {
  const { className = '', data, ...otherProps } = props;
  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      <p>{TextContent.p1}</p>
      <p>
        <span className={styles.text}>{TextContent.p2}</span>
        <span>{TextContent.p3}</span>
      </p>
      <p>{TextContent.p4}</p>
      <List
        className={styles.list}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={<div className={styles.title}>{item?.creditName || ''}</div>}
              description={item?.remark || '-'}
            />
          </List.Item>
        )}
      />
      <div className={styles.footer}>
        <p>学分规则随时调整，请以页面说明为准</p>
        <p>*最终解释权归云南建投学习成长平台所有</p>
      </div>
    </div>
  );
};

export default CreditDesc;
