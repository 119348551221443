import React, { FC } from 'react';
import styles from './Container.less';

interface ContainerProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  title?: React.ReactNode;
  extra?: React.ReactNode;
}
const Container: FC<ContainerProps> = (props) => {
  const { children, className, style, title, extra } = props;
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      {title && (
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div className={styles.extra}>{extra}</div>
        </div>
      )}
      {children}
    </div>
  );
};

Container.defaultProps = {
  className: '',
};

export default Container;
