export const formItemsMap: Record<
  string,
  Array<{ label: string; key: string; format?: (val: unknown, data: unknown) => unknown }>
> = {
  '0': [
    { label: '证书名称', key: 'certificateName' },
    { label: '注册单位', key: 'registrationDepartment' },
    { label: '发证单位', key: 'certificateReleaseDepartment' },
    {
      label: '注册日期',
      key: 'registrationDate',
      format: (val) => (val ? val.slice(0, 10) : '-'),
    },
    { label: '有效期', key: 'effectiveDate', format: (val) => (val ? val.slice(0, 10) : '-') },
  ],
  '1': [
    { label: '专业名称', key: 'majorName' },
    { label: '工作单位', key: 'workDepartment' },
    { label: '发证单位', key: 'certificateReleaseDepartment' },
    {
      label: '发证日期',
      key: 'certificateReleaseDate',
      format: (val) => (val ? val.slice(0, 10) : '-'),
    },
    { label: '有效期', key: 'effectiveDate', format: (val) => (val ? val.slice(0, 10) : '-') },
  ],
  '2': [
    { label: '资格名称', key: 'qualifiedName' },
    { label: '工作单位', key: 'workDepartment' },
    { label: '评审组织', key: 'accreditationOrganization' },
    {
      label: '资格认定时间',
      key: 'accreditationTime',
      format: (val) => (val ? val.slice(0, 10) : '-'),
    },
    {
      label: '是否最高职称',
      key: 'isHighestTitle',
      format: (val) => {
        switch (val) {
          case 0:
            return '否';
          case 1:
            return '是';
          default:
            return '-';
        }
      },
    },
  ],
  '3': [
    { label: '职业名称', key: 'professionalName' },
    { label: '职业技能等级', key: 'occupationalSkillLevel' },
    { label: '发证单位', key: 'certificateReleaseDepartment' },
    {
      label: '发证日期',
      key: 'certificateReleaseDate',
      format: (val) => (val ? val.slice(0, 10) : '-'),
    },
  ],
  '4': [
    { label: '工作单位', key: 'workDepartment' },
    { label: '发证单位', key: 'certificateReleaseDepartment' },
    { label: '培训内容', key: 'trainingContent' },
    {
      label: '培训时间',
      key: 'trainingTimeStart',
      format: (val, data) => {
        const start = data.trainingTimeStart || '-';
        const end = data.trainingTimeEnd || '-';
        return `${start.split(' ')[0]}~${end.split(' ')[0]}`;
      },
    },
    {
      label: '发证日期',
      key: 'certificateReleaseDate',
      format: (val) => (val ? val.slice(0, 10) : '-'),
    },
  ],
  '5': [
    { label: '证书名称', key: 'staffName' },
    { label: '注册单位', key: 'staffPhone' },
    { label: '发证单位', key: 'certificateReleaseDepartment' },
    {
      label: '注册日期',
      key: 'trainingTimeEnd',
      format: (val) => (val ? val.slice(0, 10) : '-'),
    },
    { label: '有效期', key: 'certificateReleaseDate', format: (val) => (val ? val.slice(0, 10) : '-') },
  ],
};

export const typeMap: Record<string, string> = {
  registration: '0',
  nonRegistration: '1',
  professional: '2',
  skill: '3',
  other: '4',
};

// 证书类-级别
export const cer1Level = [
  {
    text: '一级',
    value: '1',
  },
  {
    text: '二级',
    value: '2',
  },
];

// 非证书类-证书类别
export const cer2Kinds = [
  {
    text: '管理岗',
    value: 1,
  },
  {
    text: '特殊工种',
    value: 2,
  },
  {
    text: '普通工种',
    value: 3,
  },
  {
    text: '特种作业',
    value: 4,
  },
  {
    text: '特种设备作业',
    value: 5,
  },
];
