import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, message as Message, Spin } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import PicUploader from '@/components/PicUploader';
import { StepsForm, ProFormText, ProFormSelect, ProFormDigit } from '@ant-design/pro-form';
import MyIcon from '@/components/MyIcon';
import ProCard from '@ant-design/pro-card';
import regExp from '@/utils/regExp';
import { handleFormat } from './submit';
import { fieldPropsObj, rulesObj } from './formConfigData';
// import result from './mock';
import { getRegisterConfig, submitRegister, getRegisterUserInfo } from '../../../../services';
import styles from './RegisterModalForm.less';

export interface RegisterModalFormProps extends React.HTMLAttributes<HTMLDivElement> {}

const fileConfig = {
  size: 5,
  maxFileCount: 1,
  accept: '.jpeg,.jpg,.png,.gif,.bmp',
  fileType: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
};

const RegisterModalForm = (props: RegisterModalFormProps) => {
  const { className = '', id, classId, modal, handleRefresh, ...otherProps } = props;
  // 表单配置数据
  const [formConfig, setFormConfig] = useState([]);
  const [steps, setSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  // 获取表单配置
  const getConfig = () => {
    setLoading(true);
    getRegisterConfig({ classId })
      .then((res) => {
        if (res?.success) {
          const { data } = res;
          const _steps =
            data?.filter((item) => {
              const { children } = item;
              return Array.isArray(children) && children.length > 0;
            })?.length ?? 0;
          setSteps(_steps);
          setFormConfig(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserInfo = () => {
    getRegisterUserInfo().then((res) => {
      if (res?.success) {
        const { data } = res;
        const { xm, sfzh, xb, sjhm, certType } = data;
        form.setFieldsValue({ xm, sfzh, xb, sjhm, certType });
      }
    });
  };

  useEffect(() => {
    getConfig();
    getUserInfo();
  }, []);

  const submitRender = (props) => {
    const { step, onSubmit, onPre } = props;
    if (steps === 1) {
      return (
        <div className={styles.stepButtonGroup}>
          <Button
            type="primary"
            size="large"
            shape="round"
            key="goToTree"
            onClick={() => onSubmit?.()}
            loading={submiting}
          >
            完成
          </Button>
        </div>
      );
    }
    if (steps > 1) {
      if (step === 0) {
        return (
          <div className={styles.stepButtonGroup}>
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={() => onSubmit?.()}
              loading={submiting}
            >
              下一步
            </Button>
          </div>
        );
      }
      if (step > 0 && step + 1 < steps) {
        return (
          <div className={styles.stepButtonGroup}>
            <Button key="pre" size="large" shape="round" onClick={() => onPre?.()}>
              上一步
            </Button>
            <Button
              type="primary"
              size="large"
              shape="round"
              key="goToTree"
              onClick={() => onSubmit?.()}
              loading={submiting}
            >
              下一步
            </Button>
          </div>
        );
      }
      return (
        <div className={styles.stepButtonGroup}>
          <Button key="gotoTwo" size="large" shape="round" onClick={() => onPre?.()}>
            上一步
          </Button>
          <Button
            type="primary"
            size="large"
            shape="round"
            key="goToTree"
            onClick={() => onSubmit?.()}
            loading={submiting}
          >
            完成
          </Button>
        </div>
      );
    }
    return null;
  };

  const handleSubmit = (values) => {
    const payload = handleFormat(values);
    setSubmiting(true);
    submitRegister({
      id,
      accept: 1,
      classId,
      userInfo: payload,
    })
      .then((res) => {
        const { success, message } = res;
        if (success) {
          Message.success('报名成功');
          handleRefresh(false);
          modal.hide();
          return true;
        }
        Message.error(message || '报名失败');
      })
      .finally(() => {
        setSubmiting(false);
      });
  };

  const baseFormItemRender = (itemList) => (
    <>
      {itemList.map((item) => {
        const { dictCode, dictName, type, required, features, originDictCode } = item;
        const target = originDictCode || dictCode;
        const extraProps = fieldPropsObj[target] || {};
        switch (type) {
          case 'input':
            if (dictCode === 'gznx') {
              return (
                <ProFormDigit
                  label="工作年限"
                  name="gznx"
                  min={0}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 12 }}
                  placeholder="请输入"
                  rules={[{ required: required === 1, message: `请输入${dictName}` }]}
                  fieldProps={{ precision: 1 }}
                />
              );
            }
            return (
              <ProFormText
                name={dictCode}
                label={dictName}
                width="md"
                placeholder="请输入"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
                rules={[
                  { required: required === 1, message: `请输入${dictName}` },
                  { whitespace: required === 1, message: `${dictName}不可为空` },
                  rulesObj[dictCode] || {},
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const certType = getFieldValue('certType');
                      if (
                        dictCode === 'sfzh' &&
                        value &&
                        certType === '01' &&
                        !regExp('certCode').pattern?.test(value)
                      ) {
                        return Promise.reject(new Error('请输入正确格式的身份证号'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                {...extraProps}
              />
            );
          case 'select':
            const options = JSON.parse(features || '[]')?.map((item) => ({
              label: item,
              value: item,
            }));
            return (
              <ProFormSelect
                name={dictCode}
                label={dictName}
                width="md"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
                placeholder="请选择"
                rules={[{ required: required === 1, message: `请选择是否${dictName}` }]}
                options={options}
              />
            );
          case 'file':
            if (dictCode === 'sfzzfmzp') {
              return (
                <Form.Item
                  label={dictName}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 12 }}
                  required={required === 1}
                >
                  <div className={styles.cardGroup}>
                    <Form.Item
                      noStyle
                      name="sfzzmzp"
                      rules={[{ required: required === 1, message: `请上传${dictName}` }]}
                    >
                      <PicUploader {...fileConfig}>
                        <div className={styles.cardPic}>
                          <MyIcon type="icon-shenfenzheng1" />
                          <div className={styles.uploadText}>上传头像面</div>
                        </div>
                      </PicUploader>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name="sfzfmzp"
                      rules={[{ required: required === 1, message: `请上传${dictName}` }]}
                    >
                      <PicUploader {...fileConfig}>
                        <div className={styles.cardPic}>
                          <MyIcon type="icon-shenfenzheng2" />
                          <div className={styles.uploadText}>上传国徽面</div>
                        </div>
                      </PicUploader>
                    </Form.Item>
                  </div>
                  <div className={styles.cardPicTip}>
                    允许上传图片的格式为jpg、jpeg、gif、bmp、png建议尺寸大小：640 x
                    360，大小不超过5M
                  </div>
                </Form.Item>
              );
            }
            return (
              <Form.Item
                name={dictCode}
                label={dictName}
                rules={[{ required: required === 1, message: `请上传${dictName}` }]}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
              >
                <PicUploader
                  tip="允许上传图片的格式为jpg、jpeg、gif、bmp、png，建议尺寸大小：640×360，大小不超过5M"
                  {...fileConfig}
                  maxFileCount={dictCode === 'zjz' ? 1 : 2}
                >
                  <div className={styles.uploadBox}>
                    <div className={styles.plus}>+</div>
                    <div className={styles.uploadText}>选择图片</div>
                  </div>
                </PicUploader>
              </Form.Item>
            );
          default:
            break;
        }
        return null;
      })}
      <ProFormText name="certType" hidden />
    </>
  );

  const zc = ['zgzc', 'zczm'];
  const zy = ['zyzg', 'zyzgzm'];

  const optionsRender = (fields, itemList, add, remove) =>
    fields.map(({ key, name, ...restField }, index) => {
      const _itemList = itemList.map((zc) => {
        const { dictCode } = zc;
        return {
          ...zc,
          dictCode: [name, dictCode],
          originDictCode: dictCode,
        };
      });
      return (
        <div className={styles.optionItem} key={`option-${key}`}>
          {baseFormItemRender(_itemList)}
          {fields.length > 1 && (
            <DeleteOutlined
              className={styles.deleteIcon}
              onClick={() => {
                remove(name);
              }}
            />
          )}
        </div>
      );
    });
  const workFormItemRender = (itemList) => {
    const baseList = itemList.filter(
      (item) => !(zc.includes(item.dictCode) || zy.includes(item.dictCode)),
    );
    const zcList = itemList.filter((item) => zc.includes(item.dictCode));
    const zyList = itemList.filter((item) => zy.includes(item.dictCode));
    const baseFormItem = baseFormItemRender(baseList);
    const zcFormItem = (
      <Form.List name="zgzc" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {optionsRender(fields, zcList, add, remove)}
            {fields.length < 10 && zcList?.length > 0 && (
              <Form.Item noStyle>
                <Button
                  className={styles.addButton}
                  onClick={() => {
                    add();
                  }}
                  icon={<PlusOutlined />}
                >
                  添加职称信息
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    );
    const zyFormItem = (
      <Form.List initialValue={[{}]} min={1} name="zyzg">
        {(fields, { add, remove }) => (
          <>
            {optionsRender(fields, zyList, add, remove)}
            {fields.length < 10 && zyList?.length > 0 && (
              <Form.Item noStyle>
                <Button
                  className={styles.addButton}
                  onClick={() => {
                    add();
                  }}
                  icon={<PlusOutlined />}
                >
                  添加职业资格信息
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    );
    return [baseFormItem, zcFormItem, zyFormItem];
  };

  const xl = ['xl', 'zy', 'xlzm'];

  const degreeFormItemRender = (itemList) => {
    const xlList = itemList.filter((item) => xl.includes(item.dictCode));
    const xlFormItem = (
      <Form.List initialValue={[{}]} min={1} name="xlxx">
        {(fields, { add, remove }) => (
          <>
            {optionsRender(fields, xlList, add, remove)}
            {fields.length < 10 && xlList?.length > 0 && (
              <Form.Item noStyle>
                <Button
                  className={styles.addButton}
                  onClick={() => {
                    add();
                  }}
                  icon={<PlusOutlined />}
                >
                  添加学历信息
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    );
    return xlFormItem;
  };

  const stepFormRender = () =>
    formConfig
      .map((item) => {
        const { children, dictCode, dictName } = item;
        const arrayJudge = Array.isArray(children) && children.length > 0;
        if (!arrayJudge) return null;
        let itemRenderFunc = baseFormItemRender;
        switch (dictCode) {
          case 'gzxx':
            itemRenderFunc = workFormItemRender;
            break;
          case 'xlxx':
            itemRenderFunc = degreeFormItemRender;
            break;
          default:
            break;
        }
        return (
          <StepsForm.StepForm
            form={dictCode === 'jbxx' ? form : null}
            name={dictCode}
            title={dictName}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            layout="horizontal"
            key={dictCode}
          >
            {itemRenderFunc(children)}
          </StepsForm.StepForm>
        );
      })
      .filter((item) => item);

  return (
    <Modal
      title="报名信息填报"
      width={645}
      className={`${styles.root} ${className}`}
      {...otherProps}
      footer={null}
    >
      <Spin spinning={loading} />
      <ProCard>
        <StepsForm
          onFinish={(values) => {
            handleSubmit(values);
          }}
          submitter={{
            render: submitRender,
          }}
        >
          {stepFormRender()}
        </StepsForm>
      </ProCard>
    </Modal>
  );
};

export default RegisterModalForm;
