import React, { useState, useMemo } from 'react';
import { message, Button } from 'antd';
import dayjs from 'dayjs';
import { history } from 'umi';
import useQuery from '@/hooks/useQuery';
import SuperOrder from './components/SuperOrder';
import {
  detailApi,
  queryPayResult,
  clsoeOrder,
  getGoodsDetail,
  buyAgainApi,
  createOrderApi,
} from '../services';
import { ChannelConst, OrderTypeConst, PageFromConst } from '../utils';

/**
 *
 * @component 已有订单,继续付款
 *
 */
const ExistsOrder = (props) => {
  const { customBreadCrumb } = props;
  const { orderCode } = useQuery();
  const [orderDetail, setOrderDetail] = useState({});
  const [channel, setChannel] = useState();
  const [hasError, setHasError] = useState(false);

  const hook = {
    mounted: (handle) =>
      detailApi(orderCode).then(
        (resp) => {
          if (resp?.success) {
            setOrderDetail(resp?.data || {});

            const d = resp?.data?.payWay || ChannelConst.ON_LINE;
            setChannel(d);

            if (d === ChannelConst.ON_LINE) {
              handle.toScan();
            }
          } else {
            setHasError(true);
            message.error(resp?.message || resp?.errMsg || '服务器错误');
          }
        },
        (err) => {
          setHasError(true);
          message.error(err?.message);
        },
      ),
    queryIsPay: () => queryPayResult(orderCode),
    closeOrder: () => clsoeOrder(orderCode),
  };

  const handleSubmit = (handle) => {
    const isExpired = dayjs().isAfter(dayjs(orderDetail.gmtPayDeadline));
    if (isExpired) {
      message.error('订单超时，请重新下单。');
      hook.closeOrder();
      return;
    }
    handle.toScan();
  };

  const renderContorls = (handle) => {
    if (channel === ChannelConst.OFF_LINE) {
      return null;
    }

    if (!handle.isPay) {
      return null;
    }
    return (
      <Button
        shape="round"
        onClick={() => handleSubmit(handle)}
        type="primary"
        key="submit"
        loading={false}
        disabled={hasError}
      >
        提交订单
      </Button>
    );
  };

  return (
    <SuperOrder
      hook={hook}
      customBreadCrumb={customBreadCrumb}
      title="订单支付"
      channelDisabled
      channel={channel}
      onChannelChange={setChannel}
      orderDetail={orderDetail}
      renderContorls={renderContorls}
    />
  );
};

/**
 * @component 订单创建
 */
const CreateOrder = (props) => {
  const { getGoodsInfo, customBreadCrumb } = props;

  const [goodsInfo, setGoodsInfo] = useState({});
  const [orderDetail, setOrderDetail] = useState();
  const [channel, setChannel] = useState();
  const [loading, seLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const hook = {
    mounted: () =>
      getGoodsInfo().then(
        (resp) => {
          if (resp?.success) {
            setGoodsInfo(resp?.data || {});
            setChannel(resp?.data?.payWay || ChannelConst.ON_LINE);
          } else {
            setHasError(true);
            message.error(resp?.message || resp?.errMsg || '服务器错误');
          }
        },
        (err) => {
          setHasError(true);
          message.error(err?.message);
        },
      ),
    queryIsPay: () => queryPayResult(orderDetail?.orderCode),
    closeOrder: () => clsoeOrder(orderDetail?.orderCode),
  };

  const handleSubmit = (handle) => {
    if (!orderDetail) {
      seLoading(true);
      message.loading('请稍候......');
      createOrderApi({
        goodsId: goodsInfo.goodsId,
        payWay: channel,
        originChannel: goodsInfo.originalChannel || OrderTypeConst.CLASS,
      })
        .then((resp) => {
          message.destroy();
          if (resp?.success) {
            setOrderDetail(resp?.data ?? {});
            if (channel === ChannelConst.ON_LINE) {
              // 个人线上支付
              handle.toScan();
            }
            if (channel === ChannelConst.OFF_LINE) {
              // 公司线下代支付
              handle.toSucess();
            }
          } else {
            setHasError(true);
            message.error(resp?.message || resp?.errMsg || '服务器错误');
          }
        })
        .finally(() => seLoading(false));
    } else {
      handle.toScan();
    }
  };

  const renderContorls = (handle) => {
    if (!handle.isPay) {
      return null;
    }
    return (
      <Button
        shape="round"
        onClick={() => handleSubmit(handle)}
        type="primary"
        key="submit"
        loading={loading}
        disabled={hasError}
      >
        提交订单
      </Button>
    );
  };

  return (
    <SuperOrder
      hook={hook}
      customBreadCrumb={customBreadCrumb}
      title="订单确认"
      channel={channel}
      onChannelChange={setChannel}
      channelDisabled={hasError}
      orderDetail={{
        ...goodsInfo,
        ...orderDetail,
      }}
      renderContorls={renderContorls}
    />
  );
};

const buildCustomBreadCrumb = (query) => ({
  text: '返回',
  goBack: (handle) => {
    if (handle.isSucess && query.url) {
      history.push({ pathname: decodeURIComponent(query.url) });
    } else {
      history.goBack();
    }
  },
});

const buildParams = (query) => {
  const { from } = query;
  switch (from) {
    case PageFromConst.CLASS:
      return { id: query.classId, originalChannel: OrderTypeConst.CLASS };
    case PageFromConst.EXAM:
      return { id: query.goodsId, originalChannel: OrderTypeConst.EXAM };
    case PageFromConst.PRACTICE:
      return { id: query.goodsId, originalChannel: OrderTypeConst.PRACTICE };
    default:
      return { message: 'not implemented' };
  }
};

const ConfirmPage = (props) => {
  const query = useQuery();
  const { from } = query;
  const params = useMemo(() => buildParams(query), [query]);
  const customBreadCrumb = useMemo(() => buildCustomBreadCrumb(query), [query]);

  // 从订单页面过来的，已有订单
  if (from === PageFromConst.ORDER) {
    return <ExistsOrder />;
  }

  // 从订单页面过来的，订单再次购买
  if (from === PageFromConst.BUYAGAIN) {
    const { orderCode } = query;
    return <CreateOrder getGoodsInfo={() => buyAgainApi(orderCode)} />;
  }

  // 从 班级页面 || 考试页面 || 练习页面 过来的
  if ([PageFromConst.CLASS, PageFromConst.EXAM, PageFromConst.PRACTICE].includes(from)) {
    const { orderCode } = query;
    // 已存在订单
    if (orderCode) {
      return <ExistsOrder customBreadCrumb={customBreadCrumb} />;
    }
    // 新建订单
    return (
      <CreateOrder
        customBreadCrumb={customBreadCrumb}
        getGoodsInfo={() => getGoodsDetail(params)}
      />
    );
  }

  return 'Not implemented';
};

export default ConfirmPage;
