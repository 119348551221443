import React, { useState, useEffect } from 'react';
import { Spin, Pagination } from 'antd';
import useList from '@/hooks/useList';
import ExamCard from '@/components/ExamCard';
import Empty from '@/components/Empty';
import { getRegisterPractice } from '../../services';
import styles from './PracticeList.less';

export interface PracticeListProps extends React.HTMLAttributes<HTMLDivElement> {
  registrationStatus: number; // 注册项状态
  current: number; // 当前选中的注册项状态
}

const PracticeList = (props: PracticeListProps) => {
  const { className = '', registrationStatus, current, ...otherProps } = props;
  const [practiceList, setPracticeList] = useState([]);
  const [listProps] = useList(getRegisterPractice, {
    defaultPageSize: 8,
    queryParams: { status },
  });
  const { loading, pagination, list } = listProps;
  const { total, pageNum, pageSize, changePage } = pagination;
  useEffect(() => {
    const _list = list.map((item) => {
      const { enrollment, workCategoryName } = item;
      return {
        ...item,
        signNumber: enrollment,
        typeName: workCategoryName,
      };
    });
    setPracticeList(_list);
  }, [list]);

  const handleClick = (id, item) => {
    window.history.pushState({}, '', `/exam/user-exam/practice/sign-practice?id=${item.id}`);
  };

  const btnText = '查看详情';

  return (
    <div className={`${styles.root} ${className}`} {...otherProps}>
      <Spin spinning={loading}>
        {practiceList.length > 0 ? (
          <>
            <ExamCard
              type="practice"
              data={practiceList}
              onClick={handleClick}
              cardClassName={styles.list}
              btnText={btnText}
              incorrect
            />
            <div className={styles.footer}>
              <Pagination
                onChange={changePage}
                current={pageNum}
                total={total}
                pageSize={pageSize}
              />
            </div>
          </>
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default PracticeList;
