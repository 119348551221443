import peixun from '@/assets/images/培训.png';
import kaoshi from '@/assets/images/考试.svg';
import lianxi from '@/assets/images/练习.png';
import wenjuan from '@/assets/images/问卷.png';
import kecheng from '@/assets/images/课程.png';
import baoming from '@/assets/images/报名.png';
import zhibo from '@/assets/images/直播.png';
import library from '@/assets/images/图书馆.png';
import logo from '@/assets/images/logo.png';
import { ThemeType } from './types';

const Icons: Record<string, string> = {
  Train: peixun, // 培训
  Exam: kaoshi, // 考试
  Practice: lianxi, // 练习
  Questionnaire: wenjuan, // 问卷
  OpenClass: kecheng, // 公开课
  Register: baoming, // 报名
  Live: zhibo, // 直播
  Library: library, // 图书馆
};

const Background: Record<string, string | null | undefined> = {
  Home: null,
  Logo: logo,
  Gradient: null,
};

const Color: Record<string, string> = {
  footerColor: 'rgba(0, 10, 32, 0.45)',
};

const Theme: ThemeType = { icons: Icons, background: Background, color: Color };

export default Theme;
