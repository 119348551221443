import React, { useContext } from 'react';
import LayoutContext from '../../LayoutContext';
import styles from './LayoutTitle.less';

const LayoutTitle = (props) => {
  const { style, title, ...restProps } = props;
  const { activeMenu } = useContext(LayoutContext);
  if (title) {
    return (
      <h4 className={styles.title} style={style} {...restProps}>
        {title}
      </h4>
    );
  }
  return (
    <h4 className={styles.title} style={style} {...restProps}>
      {activeMenu?.text ?? '-'}
    </h4>
  );
};

export default LayoutTitle;
