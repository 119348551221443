import React, { useState, useEffect } from 'react';
import Tabs from '@/components/Tabs';
// import { history } from 'umi';
import usePromisifyModal from '@/hooks/usePromisifyModal';
import RegisterModalForm from './components/RegisterList/components/RegisterModalForm';
import RegisterList from './components/RegisterList';
import PracticeList from './components/PracticeList';
import LayoutTitle from '../components/LayoutTitle';

import styles from './MyRegisterPage.less';

const MyRegisterPage = (props) => {
  const {
    location: { query },
  } = props;
  const { classId } = query ?? {};
  const modal = usePromisifyModal();
  useEffect(() => {
    if (classId) {
      modal.show(<RegisterModalForm modal={modal} classId={classId} />);
    }
  }, [classId]);

  const registerTabPanesList = [
    {
      tab: '待处理',
      key: '0',
      children: <RegisterList registrationStatus={0} />,
    },
    {
      tab: '已处理',
      key: '1',
      children: <RegisterList registrationStatus={1} />,
    },
  ];

  const renderRegisterTab = <Tabs className={styles.tabs} tabPanes={registerTabPanesList} destroyInactiveTabPane />;

  const tabPanesList = [
    {
      tab: '考试',
      key: '2',
      children: <RegisterList registrationStatus={2} />,
    },
    {
      tab: '培训',
      key: '1',
      children: renderRegisterTab,
    },
    {
      tab: '练习',
      key: '3',
      children: <PracticeList registrationStatus={3} />,
    },
  ];

  return (
    <div className={`${styles.root}`}>
      <Tabs extra={<LayoutTitle />} tabPanes={tabPanesList} destroyInactiveTabPane />
      {modal.instance}
    </div>
  );
};

export default MyRegisterPage;
