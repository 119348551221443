import { useState, useMemo } from 'react';

const StepConst = {
  // 选择支付渠道
  PAY: 0,
  // 扫码支付二维码
  SCAN: 1,
  // 支付成功/提交成功
  SUCCESS: 2,
};

const useSteper = () => {
  const [step, setStep] = useState(StepConst.PAY);

  const handle = useMemo(
    () => ({
      toPay: () => setStep(StepConst.PAY),
      toScan: () => setStep(StepConst.SCAN),
      toSucess: () => setStep(StepConst.SUCCESS),
      isPay: step === StepConst.PAY,
      isScan: step === StepConst.SCAN,
      isSucess: step === StepConst.SUCCESS,
    }),
    [step],
  );
  return handle;
};

export default useSteper;
