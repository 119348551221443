import React, { useState } from 'react';
import { Button } from 'antd';
import { history } from 'umi';
import defaultPhoto from '@/assets/images/默认讲师头像.png';
import goldIcon from '@/assets/images/金牌教师.png';
import { getEditorText } from '@/utils';
import styles from './TeacherCard.less';

export interface TeacherCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const TeacherCard = (props: TeacherCardProps) => {
  const { value, className = '' } = props;
  const { id, photo, lecturerName, levelName, workBranchName, isGold, remark } = value;

  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`${styles.root} ${className}`}
      onMouseEnter={() => {
        setIsHover(true);
      }}
    >
      <div className={styles.body}>
        <img
          src={photo ? `${window['apiPrefix'] ?? ''}/lecturer/file/preview?key=${photo}` : defaultPhoto}
          alt=""
          className={styles.photo}
        />
        {isGold ? <img src={goldIcon} alt="" className={styles.icon} /> : null}
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.name}>{lecturerName}</div>
            <div className={styles.tagList}>
              <div className={styles.levelTag}>{levelName}</div>
              <div className={styles.branchTag}>{workBranchName}</div>
            </div>
          </div>
          <div className={styles.bottom}>{getEditorText(remark)}</div>
        </div>
      </div>
      <div
        className={styles.hoverWrapper}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        style={{ opacity: isHover ? 1 : 0 }}
      >
        <div className={styles.title}>{lecturerName}</div>
        <div className={styles.content}>{getEditorText(remark)}</div>
        <Button
          type="primary"
          onClick={() => {
            history.push(`/teacher/teacher-list/teacher-detail?id=${id}`);
          }}
        >
          {'查看详情 >'}
        </Button>
      </div>
    </div>
  );
};

export default TeacherCard;
