import React from 'react';
import Swiper from './components/Swiper';
import CourseCard from './components/CourseCard';
import InfoList from './components/InfoList';
import UserCard from './components/UserCard';
import TaskCard from './components/TaskCard';
import EntryCard from './components/EntryCard';
import LecturerCard from './components/LecturerCard';
import styles from './HomePage.less';
import GoodsCard from './components/GoodsCard';

const HomePage = (props) => (
  <div className={styles.root}>
    <div>
      {/* banner */}
      <Swiper />
      {/* 热门资讯 */}
      <InfoList />
      {/* 公开课 */}
      <CourseCard />
      {/* 商品中心 */}
      <GoodsCard />
    </div>
    <div style={{ width: 384 }}>
      {/* 用户信息 */}
      <UserCard />
      {/* 宫格入口 */}
      <EntryCard />
      {/* 我的任务 */}
      <TaskCard />
      {/* 金牌讲师 */}
      <LecturerCard />
    </div>
  </div>
);

export default HomePage;
