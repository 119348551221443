import React from 'react';
import { Row, Col, Spin } from 'antd';
import Empty from '@/components/Empty';
import Container from '@/components/Container';
import TrainCard from '@/components/TrainCard';
import { history } from 'umi';
import Theme from '@/themes/theme';
import styles from './CourseCard.less';
import { useRequest } from 'ahooks';
import { getOpenClassList } from '../../services';

export interface CourseCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const CourseCard = (props: CourseCardProps) => {
  const { data: requestData, loading } = useRequest(getOpenClassList);
  const { data } = requestData || {};
  const { list } = data || {};
  return (
    <Container
      className={styles.root}
      title="公开课"
      extra={
        <span
          onClick={() => {
            history.push('/pxgl/training-center?isOpen=2');
          }}
        >
          查看更多
        </span>
      }
      style={{ backgroundImage: Theme.background.Gradient ?? 'unset' }}
    >
      <Spin spinning={loading}>
        <Row gutter={16}>
          {list?.length > 0 ? (
            list.map((item, key) => (
              <Col span={12} key={key}>
                <TrainCard type="class" {...item} />
              </Col>
            ))
          ) : (
            <Empty style={{ margin: '136px auto' }} />
          )}
        </Row>
      </Spin>
    </Container>
  );
};

export default CourseCard;
