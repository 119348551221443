import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Spin } from 'antd';
import cn from 'classnames';
import InfoBox from '@/pages/person/my-register/components/RegisterList/components/InfoBox';
import { getExamDetailApi } from '@/pages/person/my-register/services';
import { dealRes, formatDate } from '@/utils';
import { ResultScene } from '@/pages/person/my-register/constant';
import styles from './ExamSignDetail.less';

export const ExamType = {
  '1': '理论试卷',
  '2': '实操考试',
  '3': '论文评价考试',
};

export interface configType {
  type: number;
  examName: string;
  examStartTime: string;
  examEndTime: string;
}

export interface ExamDetailType {
  certificateFileKey: string;
  configUsers: configType[];
  department: string;
  examCardNumber: string;
  examineeGuid: string;
  examineeName: string;
  gender: string;
  id: string;
  idCardNumber: string;
  mobilePhone: string;
  number: string;
  resultApplicableScenario: string;
  workCategoryName: string;
}

export interface ExamSignDetailProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
}

const ExamSignDetail = (props: ExamSignDetailProps) => {
  const { className = '', id, ...otherProps } = props;
  const [loading, setLoading] = useState(false);
  const [detailDate, setDetailData] = useState<ExamDetailType>({});

  const getDetail = async () => {
    setLoading(true);
    const data = await dealRes(getExamDetailApi(id), '', '获取详情失败');
    const { resultApplicableScenario } = data ?? {};
    setDetailData({
      ...data,
      resultApplicableScenario: ResultScene[resultApplicableScenario],
    });
    setLoading(false);
  };

  useEffect(() => {
    getDetail();
  }, [id]);

  const baseConfig = [
    {
      label: '姓名',
      type: 'text',
      key: 'examineeName',
      col: 18,
    },
    {
      label: '身份证号',
      type: 'text',
      key: 'idCardNumber',
      col: 18,
    },
    {
      label: '',
      type: 'img',
      key: 'certificateFileKey',
      col: 6,
    },
    {
      label: '公司名称',
      type: 'text',
      key: 'department',
    },
    {
      label: '手机号',
      type: 'text',
      key: 'mobilePhone',
    },
    {
      label: '考试结果运用',
      type: 'text',
      key: 'resultApplicableScenario',
    },
  ];

  // 信息项渲染方法
  const infoRender = () => {
    const content = baseConfig.map((item, index) => (
      <Col
        span={item.col ?? 24}
        key={item.key}
        className={cn({ [styles.phone]: item.type === 'img' })}
      >
        <InfoBox label={item.label} type={item.type} value={detailDate[item.key]} status="exam" />
      </Col>
    ));
    return <Row gutter={20}>{content}</Row>;
  };

  // 考试课目项渲染方法
  const examRender = () => {
    const content = detailDate?.configUsers.map((item, index) => (
      <>
        <h4>{ExamType[item.type]}</h4>
        <Col span={24} key={item.type}>
          <InfoBox label="考试名称" type="text" value={item.examName} status="exam" />
        </Col>
        <Col span={24} key={item.type}>
          <InfoBox
            label="考试时间"
            type="text"
            value={`${formatDate(item.examStartTime)}~${formatDate(item.examEndTime)}`}
            status="exam"
          />
        </Col>
      </>
    ));
    return <Row gutter={20}>{content}</Row>;
  };

  return (
    <Modal
      title="报名详情"
      width={500}
      className={`${styles.root} ${className}`}
      footer={null}
      {...otherProps}
    >
      <Spin spinning={loading}>
        <h4>考生信息</h4>
        {infoRender()}
        {detailDate?.configUsers?.length && examRender()}
      </Spin>
    </Modal>
  );
};

export default ExamSignDetail;
