import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import Empty from '@/components/Empty';
import React, { useEffect, useMemo, useState } from 'react';
import NO1Img from '@/assets/images/NO1.svg';
import NO2Img from '@/assets/images/NO2.svg';
import NO3Img from '@/assets/images/NO3.svg';
import defaultAvatar from '@/assets/images/person.png';
import useLoading from '@/hooks/useLoading';
import { prefixUUCImage } from '@/utils';
import { useUpdateEffect } from 'ahooks';
import LayoutTitle from '../components/LayoutTitle';
import { ClassProps, StudentCardProps } from './types';
import { getListApi } from './services';
import styles from './MyClassPage.less';

const MyClassPage = () => {
  const [showMore, setShowMore] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [classInfo, setClassInfo] = useState<ClassProps>({} as ClassProps);
  const [activeIndex, setActiveIndex] = useState(0);
  const [classList, setClassList] = useState<Array<ClassProps>>([]);
  const [loading, wrapRequest] = useLoading(getListApi);

  const StudentCard = (props: StudentCardProps) => {
    const { isMine = false, data = {}, rank } = props;
    const { duration, avatar, userName } = data;
    const computedMin = useMemo(() => {
      const val: unknown = Number(duration) / 1000 / 60 || 0;
      if (val > parseInt(val)) {
        return val.toFixed(2);
      }
      return val;
    }, [duration]);
    return (
      <div className={`${styles.studentCardRoot} ${isMine && styles.studentCardMine}`}>
        <div className={styles.left}>
          <div className={`${styles.rank} ${isMine && styles.rankMine}`}>
            {rank > 3 ? rank : <img src={rank === 1 ? NO1Img : rank === 2 ? NO2Img : NO3Img} />}
          </div>
          <div className={styles.avatar}>
            <img src={prefixUUCImage(avatar, defaultAvatar)} alt="" />
          </div>
          <div className={styles.studentName}>{isMine ? '我' : userName}</div>
        </div>
        <div className={styles.right}>
          {computedMin}
          分钟
        </div>
      </div>
    );
  };

  const mineRank = useMemo(() => {
    const list = classInfo.recordList || [];
    const mine = list[0] || {};
    return list.slice(1).findIndex((item) => item.userGuid === mine.userGuid) + 1;
  }, [classInfo]);

  useEffect(() => {
    wrapRequest().then((res: unknown) => {
      if (res.success) {
        const classList = res.data || [];
        setShowIcon(classList.length > 5);
        setClassList(classList);
        setClassInfo(classList[0] || {});
      }
    });
  }, []);

  useUpdateEffect(() => {
    setClassInfo(classList[activeIndex]);
  }, [activeIndex]);

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <LayoutTitle />
        <div className={`${styles.classesBox} ${!showMore && styles.hideMore}`}>
          <div className={styles.classList}>
            {classList.length ? (
              classList.map((item, index) => (
                <Tooltip title={item.className}>
                  <div
                    className={`${styles.classItem} ${activeIndex === index && styles.active}`}
                    onClick={() => {
                      setActiveIndex(index);
                    }}
                    key={index}
                  >
                    {item.className}
                  </div>
                </Tooltip>
              ))
            ) : (
              <div style={{ margin: '0 auto', fontSize: 16 }}>暂无班级</div>
            )}
          </div>
          {showIcon && (
            <div className={styles.allowIcon}>
              {showMore ? (
                <UpOutlined
                  onClick={() => {
                    setShowMore(false);
                  }}
                />
              ) : (
                <DownOutlined
                  onClick={() => {
                    setShowMore(true);
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Spin spinning={loading}>
        <div className={styles.listBox}>
          {classList.length ? (
            <>
              <div className={styles.myInfo}>
                {!loading && (
                  <StudentCard
                    isMine
                    rank={mineRank}
                    data={classInfo.recordList ? classInfo.recordList[0] : {}}
                  />
                )}
              </div>
              <div className={styles.studentsList}>
                {classInfo.recordList?.slice(1).map((item, index) => (
                  <StudentCard rank={index + 1} data={item} key={index} />
                ))}
              </div>
            </>
          ) : (
            <Empty />
          )}
        </div>
      </Spin>
    </div>
  );
};
export default MyClassPage;
