/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useRef, useState } from 'react';
import { Spin, message, Button } from 'antd';
import { useControllableValue } from 'ahooks';
import { history } from 'umi';
import { ChannelConst, FeeModeConst } from '../../../utils';
import ConfirmSuccess from '../ConfirmSucess';
import ConfirmQRCode from '../ConfirmQRCode';
import OrderBreadcrumb from '../../../components/OrderBreadcrumb';
import OrderTitle from '../../../components/OrderTitle';
import OrderChannel from '../../../components/OrderChannel';
import OrderBanner from '../../../components/OrderBanner';
import FooterBar from '../../../components/FooterBar';
import CountDown from '../../../components/CountDown';
import useSteper from './useSteper';
import styles from './SuperOrder.less';

const SuperOrder = (props) => {
  const { hook, orderDetail, channelDisabled, title, renderContorls, customBreadCrumb } = props;
  const handle = useSteper();
  const [channel, setChannel] = useControllableValue(props, {
    defaultValue: ChannelConst.ON_LINE,
    valuePropName: 'channel',
    trigger: 'onChannelChange',
  });
  const [loading, setLoading] = useState(false);

  const lastReqRef = useRef();

  const h = useRef(null);

  const cleanUp = () => {
    if (h.current) {
      clearInterval(h.current);
    }
  };

  useEffect(() => {
    setLoading(true);
    hook.mounted(handle).finally(() => setLoading(false));
    return () => cleanUp();
  }, []);

  const goBack = () => {
    const { goBack: goBackProp } = customBreadCrumb || {};
    if (goBackProp instanceof Function) {
      goBackProp(handle);
    } else {
      history.replace('/person/my-order');
    }
  };

  const handlePaySuccess = () => {
    message.success('你已完成付款！3秒后自动跳转到结果页面', 3);
    setTimeout(() => {
      handle.toSucess();
    }, 3000);
  };

  const stopPolling = () => cleanUp();

  const startPolling = () => {
    stopPolling();

    const fn = () => {
      if (!lastReqRef.current) {
        lastReqRef.current = true;
        hook?.queryIsPay().then((resp) => {
          lastReqRef.current = false;
          if (resp?.success && resp?.data) {
            stopPolling();
            handlePaySuccess();
          }
        });
      }
    };

    h.current = setInterval(fn, 3000);
  };

  const handleOverTime = () => {
    message.error('订单超时，请重新下单，3s后返回。', 3);
    stopPolling();
    hook.closeOrder();
    setTimeout(() => {
      goBack(handle);
    }, 3000);
  };

  const isOnline = channel === ChannelConst.ON_LINE;
  const isOffLine = channel === ChannelConst.OFF_LINE;

  return (
    <>
      <OrderBreadcrumb onClick={() => goBack(handle)}>{customBreadCrumb?.text ?? '我的订单'}</OrderBreadcrumb>
      <Spin spinning={loading}>
        <div className={styles.root}>
          <div className={styles.body} style={{ flex: 1 }}>
            <OrderTitle
              extra={
                orderDetail.gmtPayDeadline && !handle.isSucess ? (
                  <CountDown targetDate={orderDetail.gmtPayDeadline} onEnd={handleOverTime} />
                ) : null
              }
            >
              {title || '订单确认'}
            </OrderTitle>
            <OrderBanner {...orderDetail} isOrderConfirm />
            {handle.isPay && (
              <OrderChannel
                value={channel}
                onChange={setChannel}
                channels={{
                  [ChannelConst.ON_LINE]: true,
                  [ChannelConst.OFF_LINE]: orderDetail?.feeMode === FeeModeConst.YES,
                }}
                disabled={channelDisabled}
              />
            )}
            {handle.isScan && (
              <ConfirmQRCode
                channel={channel}
                payUrl={orderDetail?.payPcUrl}
                afterPayMount={startPolling}
              />
            )}
            {handle.isSucess && isOnline ? (
              <ConfirmSuccess
                type="扫码支付"
                title="支付成功"
                extra={
                  <div>
                    <p className={`${styles.tips} ${styles.amount}`}>
                      {`¥${orderDetail?.amountOrder}`}
                    </p>
                    <p className={styles.tips}>线上支付</p>
                    <Button type="primary" shape="round" onClick={() => goBack(handle)}>
                      返回
                    </Button>
                  </div>
                }
              />
            ) : null}
            {handle.isSucess && isOffLine ? (
              <ConfirmSuccess
                type="线下支付"
                title="提交成功"
                extra={
                  <div>
                    <p className={styles.tips}>线下支付</p>
                    <Button type="primary" shape="round" onClick={goBack}>
                      返回
                    </Button>
                  </div>
                }
              />
            ) : null}
          </div>
          {!handle.isSucess && (
            <FooterBar>
              {orderDetail.amountOrder ? (
                <div className={styles.price}>
                  <span className={styles.label}>需付款</span>
                  <span className={styles.total}>{`¥${orderDetail.amountOrder}`}</span>
                </div>
              ) : null}
              {renderContorls(handle)}
            </FooterBar>
          )}
        </div>
      </Spin>
    </>
  );
};

export default SuperOrder;
