// eslint-disable-next-line import/no-cycle
import { get, post, upload } from '@/utils/fetch';

/**
 * 登录退出
 */
export const logout = () => get('/sso/loginOut');

/**
 * 用户信息
 */
export const userInfo = () => get('/sso/portal/getCurrentUser');

/**
 * 钉钉端免登
 */
export const dingtalkLogin = ({ code, ...rest }) =>
  get('/sso/dingtalk/login', { code, type: 'internal', ...rest });

/**
 * 获取用户班级排名、学习时长等信息
 */
export const getClassRank = () => get('/train/class/getRank');

/**
 * 获取积分和头衔
 */
export const getUserCredit = () => get('/information/creditDetail/userCredit');

export const uploadSmallFile = (data) => upload('/train/file/upload', data);

/**
 * 数据字典
 */

export const getDictList = (params) =>
  post('/uuc/dict/dictListByParentType', { dictStatus: 'ENABLE', language: 'zh_CN', ...params });
