import React from 'react';
import { Row, Col } from 'antd';
import { ColProps } from 'antd/lib/col';
import { RowProps } from 'antd/lib/row';
import styles from './Descriptions.less';

export interface ItemProps extends ColProps {
  valueType?: 'default' | 'money';
  label: string;
  children: React.ReactNode;
}

const Descriptions = ({ children, ...props }: RowProps) => {
  return <Row {...props}>{children}</Row>;
};

const Item = ({ label, valueType, children, span = 12, ...colProps }: ItemProps) => {
  let _children = children || '-';
  if (valueType === 'money' && children) {
    _children = <>¥{children}</>;
  }
  return (
    <Col span={span} {...colProps}>
      <div className={styles.row}>
        <div className={styles.label}>{label}:</div>
        <div className={styles.value}>{_children}</div>
      </div>
    </Col>
  );
};

Descriptions.Item = Item;

export default Descriptions;
