import React from 'react';
import { history } from 'umi';
import dayjs from 'dayjs';
import Card from '@/components/Card';
import classDefault from '@/assets/images/classDefault.png';
import MyIcon from '@/components/MyIcon';
import cn from 'classnames';
import { Button } from 'antd';
import styles from './GoodsCard.less';

/** 培训中心 图片访问地址 */
const previewUrl = (key) => `/train/file/show/${key}`;

export const formatDate = (date, format?) => dayjs(date).format(format || 'MM.DD HH:mm');

// 培训卡片分为 培训班级、公开课两种类型
const typeMap = {
  train: 1,
  class: 2,
};

export interface GoodsCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const GoodsCard = (props) => {
  const {
    goodsName,
    relatedCourseId,
    sale,
    stock,
    gmtStart,
    gmtEnd,
    goodsImage,
    id,
    classId,
    goodsType,
    size = 'default',
    price,
    ...rest
  } = props;

  /* const {
    // className = '',
    title,
    desc,
    url,
    time,
    tag,
    descExtra,
    imgExtra,
    size = 'default',
    ...otherProps
  } = props; */

  const handleDetail = () => {
    history.push(`/zyzx/goods-detail?id=${id}`);
  };

  const handlePurchase = () => {
    history.push(`/zyzx/goods-detail?id=${id}`);
  };
  const CountDesc = () => (
    <div>
      <span style={{ marginRight: 12 }}>
        库存:
        {stock ? 1 : 0}
      </span>
      <span style={{ marginRight: 12 }}>
        已售:
        {sale ?? 0}
      </span>
    </div>
  );

  const imgExtra = (
    <div className={styles.isOpenClass}>{goodsType === 0 ? '课程资源' : '会员'}</div>
  );

  /* return (
    <Card
      onClick={handleDetail}
      title={className}
      desc={<CountDesc />}
      url={fileId ? previewUrl(fileId) : classDefault}
      time={formatDate(gmtStart) + '~' + formatDate(gmtEnd)}
      // imgExtra={typeMap[type] === 2 ? imgExtra : null}
      {...rest}
    />

  ); */

  return (
    <div className={`${styles.item}`} {...rest} onClick={handleDetail}>
      <div className={styles.imageBox}>
        <img
          className={cn(styles.image, { [styles.large]: size === 'large' })}
          src={goodsImage ? previewUrl(goodsImage) : classDefault}
        />
        {imgExtra}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{goodsName}</div>
        <div className={styles.desc}>
          <div>
            <span style={{ marginRight: 12 }}>
              库存:
              {stock ?? 0}
            </span>
            <span style={{ marginRight: 12 }}>
              已售:
              {sale ?? 0}
            </span>
          </div>
        </div>
        {/* <div className={styles.desc}>{descExtra}</div> */}
        {/* {tag && <div className={styles.tag}>{tag}</div>} */}
        <div className={styles.option}>
          <span style={{ color: '#ff5653' }}>{price && price !== 0 ? `¥${price}` : '免费'}</span>
          <span>
            <Button type="primary" shape="round" size="small" onClick={handlePurchase}>
              立即购买
            </Button>
          </span>
        </div>
        {/* {time && (
          <div className={styles.time}>
            <MyIcon type="icon-shijian" />
            <span className={styles.timeText}>{time}</span>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default GoodsCard;
