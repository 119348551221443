import React from 'react';
import { Row, Col, Spin } from 'antd';
import Empty from '@/components/Empty';
import Container from '@/components/Container';
import GoodsCompCard from '@/components/GoodsCard';
import { history } from 'umi';
import Theme from '@/themes/theme';
import { useRequest } from 'ahooks';
import styles from './GoodsCard.less';
import { getGoodsList } from '../../services';

export interface GoodsCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const GoodsCard = (props: GoodsCardProps) => {
  const { data: requestData, loading } = useRequest(getGoodsList);
  const { data } = requestData || {};
  const { records: list } = data || {};
  return (
    <Container
      className={styles.root}
      title="商品中心"
      extra={
        <span
          onClick={() => {
            history.push('/zyzx/goods-center');
          }}
        >
          查看更多
        </span>
      }
      style={{ backgroundImage: Theme.background.Gradient ?? 'unset' }}
    >
      <Spin spinning={loading}>
        <Row gutter={16}>
          {list?.length > 0 ? (
            list.map((item, key) => (
              <Col span={12} key={key}>
                <GoodsCompCard type="class" {...item} />
              </Col>
            ))
          ) : (
            <Empty style={{ margin: '136px auto' }} />
          )}
        </Row>
      </Spin>
    </Container>
  );
};

export default GoodsCard;
