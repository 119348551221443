export const now = new Date();
export interface DateType {
  week: string;
  timeStamp: number;
  date: string;
}

const weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
const week = now.getDay();
const oneDay = 24 * 60 * 60 * 1000;
export const initWeeks = (setWeeksMap, cb) => {
  const resultMap: Array<DateType> = [];
  for (let i = 1; i < 8; i++) {
    let timeStamp, tempWeek, date;
    if (i === 1) {
      timeStamp = now.getTime();
      tempWeek = week;
      date = now.getDate();
    } else {
      timeStamp = now.getTime() + (i - 1) * oneDay;
      const d = new Date(timeStamp);
      tempWeek = d.getDay();
      date = d.getDate();
    }
    resultMap.push({
      week: weeks[tempWeek],
      timeStamp,
      date,
    });
  }
  setWeeksMap(resultMap);
  cb(resultMap);
};
