import { message } from 'antd';
import FileSaver from 'file-saver';

function exportUtils(resp, fileName, callback?) {
  resp
    .then(
      (t) => {
        if (t.type === 'application/json') {
          t.text().then((res) => {
            console.log(res);
            message.info(JSON.parse(res).message);
          });
        } else {
          const blob = new Blob([t]);
          FileSaver.saveAs(blob, fileName);
          callback?.();
        }
      },
      () => {
        throw new Error('下载失败');
      },
    )
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err, '下载失败'));
}

export default exportUtils;
