import React from 'react';
import { Tooltip } from 'antd';

const renderColunms = ({ showOrderIndex, columns, current, pageSize }) => {
  const orderColumns = {
    title: '序号',
    dataIndex: 'orderIndex',
    align: 'center',
    width: 80,
    fixed: 'left' as 'left',
    render: (text, record, index) => (current - 1) * pageSize + (index + 1),
  };

  let tableColumns = columns?.map((col) => {
    if (col.render) {
      return col;
    }
    return {
      ...col,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text || '—'}
        </Tooltip>
      ),
    };
  });

  if (showOrderIndex) {
    tableColumns = [orderColumns, ...tableColumns];
  }

  return tableColumns;
};

export default renderColunms;
