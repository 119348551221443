import { get, post, put } from '@/utils/fetch';
// 获取报名列表
export const getRegisterListApi = (data) => post('/train/mobile/registration/list', data);
// 获取报名信息
export const getRegisterInfoApi = ({ id }) => get(`/train/mobile/registration/${id}`);
// 提交/拒绝报名
export const submitRegister = (data) => post('/train/mobile/registration/confirm', data);
// 获取报名表单的配置
export const getRegisterConfig = ({ classId }) =>
  get(`/train/mobile/registration/getTcClassDictInstTree/${classId}`);
// 获取注册用户信息
export const getRegisterUserInfo = () => get('/train/mobile/registration/user');

// 获取考试的报名列表
export const getExamListApi = ({ pageNum, pageSize }) =>
  get(`/ks-api/user/examinee?pageNum=${pageNum}&pageSize=${pageSize}`);

// 查询我的报名详情
export const getExamDetailApi = (planId) => get(`/ks-api/user/examinee/${planId}`);
// 获取我的报名练习数据
export const getRegisterPractice = ({ pageNum, pageSize }) =>
  post('/ks-api/practice/enroll/page', { pageNum, pageSize });

// 取消报名
export const cancelExamSignApi = (planId) => put(`/ks-api/user/examinee/${planId}/cancel`);
