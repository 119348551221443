import { useCountDown } from 'ahooks';
import styles from './CountDown.less';

const CountDown = ({ targetDate, onEnd }) => {
  const [, formattedRes] = useCountDown({
    targetDate,
    onEnd,
  });
  const { hours, minutes, seconds } = formattedRes;

  return (
    <span className={styles.error}>
      交易将在{hours ? `${hours}小时` : ''}
      {minutes ? `${minutes}分` : ''}
      {seconds}秒后关闭，请及时付款！
    </span>
  );
};

export default CountDown;
