import React from 'react';
import { Modal, Tabs } from 'antd';
import RegisterInfo from '../RegisterInfo';
import styles from './RegisterDetail.less';

export interface RegisterDetailProps extends React.HTMLAttributes<HTMLDivElement> {
  classInfo: {
    className: string;
    gmtStart: string;
    gmtEnd: string;
    tenantName: string;
  };
  classId: string;
}

const { TabPane } = Tabs;

const RegisterDetail = (props: RegisterDetailProps) => {
  const { className = '', id, classId, classInfo, ...otherProps } = props;
  return (
    <Modal
      title="报名信息详情"
      width={920}
      className={`${styles.root} ${className}`}
      {...otherProps}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="报名信息" key="1">
          <RegisterInfo id={id} classId={classId} classInfo={classInfo} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default RegisterDetail;
