import dayjs from 'dayjs';
import { post } from '@/utils/fetch';

export const getQAListApi = ({ range, ...rest }) => {
  const d = { ...rest };
  if (Array.isArray(range) && range.length === 2) {
    const [s, e] = range;
    d.dateList = [
      `${dayjs(s).format('YYYY-MM-DD')} 00:00:00`,
      `${dayjs(e).format('YYYY-MM-DD')} 23:59:59`,
    ];
  }

  return post('/train/mobile/qa/self/page', d);
};
